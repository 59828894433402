import {
  Box,
  Button, Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  TypographyProps
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import Icon from '../../../../core/components/Icon';
import FilePreviewIcon from './FilePreviewIcon';
import { FileProp } from './FileProp';

const Image = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(10)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 250
  }
}));

const Header = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}));

interface UploaderProps {
  onUploadAction: (files: File[]) => void;
}

export const Uploader: FC<UploaderProps> = ({ onUploadAction }) => {
  // ** state
  const [files, setFiles] = useState<File[]>([]);

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file)));
    }
  });

  const onRemoveFileAction = (file: FileProp) => {
    const filtered = files.filter((i: FileProp) => i.name !== file.name);
    setFiles([...filtered]);
  };

  const fileList = files.map((file: FileProp) => (
    <ListItem key={file.name}>
      <ListItemIcon>
        <div className='file-preview'>
          <FilePreviewIcon file={file} />
        </div>
      </ListItemIcon>
      <div>
        <ListItemText className='file-name'>{file.name}</ListItemText>
        <Typography className='file-size' variant='body2'>
          {Math.round(file.size / 100) / 10 > 1000
            ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
            : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
        </Typography>
      </div>
      <ListItemSecondaryAction>
        <IconButton onClick={() => onRemoveFileAction(file)}>
          <Icon icon='mdi:close' fontSize={20} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ));

  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column'],
            alignItems: 'center'
          }}
        >
          <Image width={300} alt='Upload image' src='/media/misc/upload.png' />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: ['center', 'center', 'inherit']
            }}
          >
            <Header variant='h5'>Drop files here or click to upload.</Header>
            <Typography color='textSecondary' sx={{ '& a': { color: 'primary.main', textDecoration: 'none' } }}>
              Drop files here or click{' '}
              <Link to='/' onClick={e => e.preventDefault()}>
                browse
              </Link>{' '}
              thorough your machine
            </Typography>
          </Box>
        </Box>
      </div>

      {files.length ? (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <List>{fileList}</List>
          </Grid>

          <Grid item xs={6}>
            <Button
              color='error'
              fullWidth
              variant='outlined'
              onClick={() => setFiles([])}
            >
              Remove All
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={() => onUploadAction(files)}
              variant='contained'
            >
              Upload Files
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default Uploader;
