import { AxiosInstance, HttpStatusCode } from 'axios';

import { privateAxios } from '../../../api/axios';
import { refresh } from '../api';
import { AuthModel } from './_models';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';
export const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

export const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

export const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

export const setupAxios = (axios: AxiosInstance) => {
  axios.defaults.baseURL = API_BASE_URL;
  axios.defaults.headers.Accept = 'application/json';

  axios.interceptors.response.use(
    (response) => response,
    async (error: any) => {
      const prevRequest = error?.config;

      if (error?.response?.status === HttpStatusCode.Unauthorized && !prevRequest.sent) {
        prevRequest.sent = true;
        await refresh().catch((error: any) => {
          return Promise.reject(error);
        });

        return privateAxios(prevRequest);
      }

      return Promise.reject(error);
    },
  );
};
