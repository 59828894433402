import { Route, Routes } from 'react-router-dom';
import {
  EmailVerification,
  ForgotPassword,
  Login,
  Registration,
  ResendConfirmationLink,
} from './components';
import { AuthLayout } from './AuthLayout';

export const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="password/recovery" element={<ForgotPassword />} />
      <Route path="email/resend/confirmation" element={<ResendConfirmationLink />} />
      <Route path="email/verify" element={<EmailVerification />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

