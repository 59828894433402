import { Box, Button, TextField } from '@mui/material';
import { FC, ReactNode } from 'react';
import Icon from '../Icon';

interface DataGridHeaderProps {
  actions?: ReactNode[];
  onAddAction?: () => void;
  onDownloadAction?: ReactNode;
  onShareAction?: () => void;
  handleFilter: (value: string) => void;
  placeholder?: string;
  value?: string;
}

export const DataGridHeader: FC<DataGridHeaderProps> = (
  props: DataGridHeaderProps
) => {
  const {
    onAddAction,
    onDownloadAction,
    onShareAction,
    handleFilter,
    placeholder = 'Search...',
    value,
  } = props;

  return (
    <Box
      sx={{
        p: 5,
        pb: 3,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <TextField
        size="small"
        value={value}
        sx={{ mr: 6, mb: 2 }}
        placeholder={placeholder}
        onChange={(e) => handleFilter(e.target.value)}
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        {onAddAction && (
          <Button
            color="primary"
            sx={{ mr: 4, mb: 2 }}
            onClick={onAddAction}
            variant="outlined"
            startIcon={<Icon icon="mdi:plus-outline" fontSize={20} />}
          >
            Add
          </Button>
        )}

        {onDownloadAction && onDownloadAction}

        {onShareAction && (
          <Button
            sx={{ mr: 4, mb: 2 }}
            color="primary"
            onClick={() => onShareAction()}
            variant="outlined"
            startIcon={<Icon icon="mdi:share-outline" fontSize={20} />}
          >
            Share
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DataGridHeader;
