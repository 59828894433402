import { CustomerRequirement } from './CustomerRequirementModel';
import { ID } from './id';
import { Response } from './response';

export type Customer = {
  id?: ID,
  uuid?: ID,
  name?: string,
  division?: string,
  address1?: string,
  address2?: string,
  email?: string,
  phone?: string,
  fax?: string,
  city?: string,
  state?: string,
  country?: string,
  zip?: string,
  entryDate?: Date,
  active?: boolean,
  openOrders?: number,
  pickedOrders?: number,
  picks?: number,
  shippedOrders?: number,
  cancelOrders?: number,
  accuracy?: number,
  logo?: string,
  requirements?: CustomerRequirement[],
  routingRequired?: boolean,
  fineLineTicketsRequired?: boolean;
  fineLineTrackingNumberRequired?: boolean,
  printTicketsRequired?: boolean,
  palletLabelsRequired?: boolean,
  useUCCLabels?: boolean,
  useIndividualSKUCartons?: boolean,
  deadline?: boolean;
  orders?: number,
  avatar?: string;
}

export default Customer;

export type CustomerResponse = Response<Array<Customer>>;

export const initialCustomer: Customer = {
  name: '',
  email: '',
  phone: '',
  logo: '',
  division: '',
};
