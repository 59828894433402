import { SnackbarProvider } from "notistack";
import { FC } from 'react';
import { DataTableInitializer } from "./DataTableInitializer";
import { DataTableProvider } from "./DataTableProvider";

import { DataTableOptions } from './Prototypes';

import './datatable.css';


type Props = {
  options: DataTableOptions;
  header?: Element,
  body?: Element,
  footer?: Element,
};

export const DataTable: FC<Props> = (
  {
    options,
    header,
    body,
    footer,
  }) => {
  return (
    <DataTableProvider>
      <SnackbarProvider>
        <DataTableInitializer options={options} />
      </SnackbarProvider>
    </DataTableProvider>
  );
};
