import { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { I18nProvider } from '../theme/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../theme/layout/core';
import { MasterInit } from '../theme/layout/MasterInit';
import { AuthInit } from './modules/auth';

export const App = () => {
  const [googleSheetsApiReady, setGoogleSheetsApiReady] = useState(false);

  const loadSheetsGoogleApi = async () => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/client.js';

    script.onload = async () => {
      await (window as any).gapi.load('client', async () => {
        await (window as any).gapi.client.setApiKey(process.env.REACT_APP_GOOGLE_SHEET_API_KEY);
        await (window as any).gapi.load('sheets', 'v1', () => {
          setGoogleSheetsApiReady(true);
        });
      });
    };
  }

  useEffect(() => {
    void loadSheetsGoogleApi();
    setTimeout(() => {
      if (googleSheetsApiReady) {
        return;
      }
    }, 2000);
  }, [googleSheetsApiReady]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export default App;
