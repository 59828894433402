import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, FormControl, Grid } from '@mui/material';
import DatePicker from 'react-datepicker';
import { FC, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';

import CardSnippet from '../../../../../components/Cards/snippet';
import PickerCustomInput from '../../../../../components/Pickers/PickerCustomInput';
import { useResponse } from '../../../../../providers';
import { purchaseOrderGetReady } from '../../../api';
import { PurchaseOrder } from '../../../models';

interface PurchaseOrderFormGetReadyProps {
  onCloseAction?: () => void;
  purchaseOrder?: PurchaseOrder;
}

export const PurchaseOrderFormGetReady: FC<PurchaseOrderFormGetReadyProps> = ({
  onCloseAction,
  purchaseOrder,
}) => {
  const { reload } = useResponse();

  // ** State
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const schema = yup.object().shape({
    estimatedShipDate: yup.date().nullable().default(undefined),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      estimatedShipDate: purchaseOrder?.estimatedShipDate
        ? new Date(String(purchaseOrder?.estimatedShipDate))
        : null,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmitAction = async (values: FieldValues) => {
    setIsSubmitting(true);

    try {
      await purchaseOrderGetReady(purchaseOrder?.reference, values);

      await reload();
      reset();
      onCloseAction?.();
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    reset();
    onCloseAction?.();
  };
  return (
    <CardSnippet title="Get Ready to Ship">
      <form onSubmit={handleSubmit(onSubmitAction)}>
        <Grid item xs={12}>
          {error && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}
          <FormControl fullWidth sx={{ mb: 6, zIndex: 5 }}>
            <Controller
              control={control}
              name="estimatedShipDate"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  selected={value}
                  popperPlacement="auto-start"
                  onChange={(date) => {
                    let estimatedShipDate = new Date(String(date)).getTime();
                    let cancelDate = new Date(
                      String(purchaseOrder?.cancelDate)
                    ).getTime();
                    if (estimatedShipDate > cancelDate) {
                      setError('Chosen date is later than the cancel date!');
                    }

                    onChange(date);
                  }}
                  placeholderText="Select a ship date"
                  popperModifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [5, 10],
                      },
                    },
                    {
                      name: 'preventOverflow',
                      options: {
                        rootBoundary: 'viewport',
                        tether: false,
                        altAxis: true,
                      },
                    },
                  ]}
                  customInput={
                    <PickerCustomInput label="Estimated ship date" />
                  }
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Button
              disabled={isSubmitting}
              type="submit"
              fullWidth
              variant="contained"
              size="large"
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={isSubmitting}
              color="error"
              fullWidth
              onClick={handleClose}
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardSnippet>
  );
};

export default PurchaseOrderFormGetReady;
