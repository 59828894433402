import clsx from 'clsx';
import { FC } from 'react';

type Props = {
  title?: string;
  status?: string
};

export const StatusBadge: FC<Props> = ({ title, status= '' }) => {
  let type: string;

  switch (status.toString().toLowerCase()) {
    case 'y':
    case 'yes':
    case 'ship':
    case 'shipped':
    case 'success':
    case 'enabled':
    case 'true':
      type = 'success';
      break;
    case 'n':
    case 'no':
    case 'disabled':
    case 'danger':
      type = 'danger';
      break;
    case 'primary':
    case 'open':
    case 'active':
      type = 'primary';
      break;
    case 'unk':
    case 'unknown':
    case 'secondary':
    case 'inactive':
      type = 'secondary';
      break;
    case 'black':
      type = 'black';
      break;
    case 'white':
    default:
      type = 'white';
      break;
  }
  title = title?.toString().length ? title : status;

  return (
    <span className={clsx(`badge badge-${type}`, type === 'black' && 'bg-black')}>{title}</span>
  );
};
