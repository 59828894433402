import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Icon from '../../../../../../core/components/Icon';
import CardSnippet from '../../../../../components/Cards/snippet';
import FileList from '../../../../../components/lists/FileList';
import { ID } from '../../../../../models';
import { useResponse } from '../../../../../providers';
import { approvePickedOrder } from '../../../api';

const schema = yup.object().shape({
  attachments: yup.array(),
  notes: yup.string(),
});

interface PurchaseOrderFormApprovalProps {
  purchaseOrderID: ID;
  onCloseAction?: () => void;
}

export const PurchaseOrderFormApproval: FC<PurchaseOrderFormApprovalProps> = ({
  purchaseOrderID,
  onCloseAction,
}) => {
  const { reload } = useResponse();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { attachments: [], notes: '' },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setFiles([]);
    setValue('notes', '');
    reset();
    onCloseAction?.();
  };

  return (
    <CardSnippet title="Warehouse Approval">
      <form
        onSubmit={handleSubmit(async (values) => {
          setIsSubmitting(true);

          try {
            if (files.length) {
              setValue('attachments', files);
            }

            await approvePickedOrder(purchaseOrderID, values);
            await reload();

            handleClose();
          } catch (error: any) {
            console.log(error);
          } finally {
            setIsSubmitting(false);
          }
        })}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mt: 6, mb: 6 }}>
              <Controller
                control={control}
                name="notes"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    error={Boolean(errors.notes)}
                    fullWidth
                    label="Additional notes"
                    minRows={3}
                    multiline
                    onChange={onChange}
                    placeholder="Notes to WH team"
                    sx={{
                      '& .MuiOutlinedInput-root': { alignItems: 'baseline' },
                    }}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon="mdi:message-outline" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {errors.notes && (
                <FormHelperText sx={{ color: 'error.main' }}>
                  {String(errors.notes.message)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FileList
              enableAdd
              enableRemove
              enableClear
              files={files}
              onChange={(files) => setFiles(files)}
              title="Attachments"
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              disabled={isSubmitting}
              type="submit"
              fullWidth
              variant="contained"
              size="large"
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={isSubmitting}
              color="error"
              fullWidth
              onClick={handleClose}
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardSnippet>
  );
};

export default PurchaseOrderFormApproval;
