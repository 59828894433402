import { isUndefined } from "@ialopezg/commonjs";
import { FC } from "react";

import { OptionListCell } from "../../../components";

type Props = {
  objectValue?: any,
  activeColor?: string,
};

export const OrderOptionsCell: FC<Props> = ({ objectValue, activeColor }) => {
  const allowedKeys: { [key: string]: string } = {
    routingRequired: 'RT',
    fineLineTicketsRequired: 'FLT',
    fineLineTrackingNumberRequired: 'FTN',
    printTicketsRequired: 'PRT',
    palletLabelsRequired: 'PLL',
    uCCLabelsRequired: 'UCC',
    useIndividualSKUCartons: 'SKU',
    daysBeforeStartDate: 'BS',
    daysBeforeCancelDate: 'BC',
  };

  const options: any[] = Object
    .keys(allowedKeys)
    .map((key) => {
      const value = (objectValue as any)[key];

      if (value || value > 0) {
        return { value: activeColor ? activeColor : 'black', label: allowedKeys[key] };
      }

      return undefined;
    })
    .filter((option: any) => !isUndefined(option));

  return (
    <OptionListCell id={String(objectValue?.id)} options={options} />
  );
};
