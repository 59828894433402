import { FC, useEffect, useState } from 'react';

import { isUndefined } from '@ialopezg/commonjs';

import { SVGImage } from '../image';
import { useDebounce } from '../../common';

type Props = {
  onChange?: (value?: string) => void,
  placeholder?: string,
  value?: string,
};

export const SearchWidget: FC<Props> = (
  {
    onChange,
    placeholder = 'Search...',
    value,
  },
) => {
  const [searchTerm, setSearchTerm] = useState<string>(value || '');
  const debounceSearchTerm = useDebounce(searchTerm, 150);

  useEffect(() => {
    if (!isUndefined(debounceSearchTerm) && !isUndefined(searchTerm)) {
      if (onChange) {
        onChange(debounceSearchTerm);
      }
    }
    // eslint-disable-next-line
  }, [debounceSearchTerm, searchTerm]);

  return (<div className="card-title">
    <div className="d-flex align-items-center position-relative my-1">
      <SVGImage
        path="/media/icons/magnifying-glass.svg"
        className="svg-icon-1 svg-icon-lg-1 position-absolute ms-3"
      />

      <input
        type="text"
        className="form-control form-control-solid w-250px ps-12"
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        placeholder={placeholder}
      />
    </div>
  </div>);
};
