import DataGrid from '@ialopezg/datagrid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Avatar,
  Box,
  CircularProgress,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MouseEvent, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';

import { SVGImage } from '../../../../components';
import { createDownloadLink } from '../../../../helpers';
import { ID, Order } from '../../../../models';
import { download3PlReport } from '../../../PickTicket';
import { PurchaseOrder } from '../../models';

const LinkStyled = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1rem',
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const renderStatus = (order: Order) => {
  const color = ['B-CNCL', 'CNCL'].includes(order.status)
    ? 'error'
    : ['OPEN', 'PART'].includes(order.status)
    ? 'primary'
    : 'success';

  return (
    <Avatar
      color={color}
      sx={{ mr: 3, width: 34, height: 34, fontSize: '1rem' }}
    >
      <SVGImage
        path={`/media/icons/regular/${
          ['B-CNCL', 'CNCL'].includes(order.status)
            ? 'file-slash'
            : ['OPEN', 'PART'].includes(order.status)
            ? 'box-open'
            : 'ship'
        }.svg`}
        className={`svg-icon-1 text-${color === 'error' ? 'danger' : color}`}
      />
    </Avatar>
  );
};

export const PurchaseOrderExpandedRow = (row: Row<PurchaseOrder>) => {
  const purchaseOrder = row.original;
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'id',
          Header: 'CTRL',
          Cell: ({ ...props }) => {
            const order = props.data[props.row.index];

            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {renderStatus(order)}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <LinkStyled to={`/orders/${order?.id}`}>
                    {order?.id}
                  </LinkStyled>
                  <Typography noWrap variant="caption">
                    {''}
                  </Typography>
                </Box>
              </Box>
            );
          },
        },
        {
          id: 'pickTicket',
          Header: 'PICKTKT',
          Cell: ({ ...props }) => {
            const order = props.data[props.row.index];
            const pickTicket = order?.pickTicket;

            return <>{pickTicket?.id}</>;
          },
        },
        {
          id: 'customer',
          Header: 'CUST',
          Cell: ({ ...props }) => (
            <Typography
              variant="subtitle1"
              noWrap
              sx={{ textTransform: 'capitalize' }}
            >
              {props.data[props.row.index]?.customer?.id}
            </Typography>
          ),
        },
        {
          id: 'division',
          Header: 'DIV',
          Cell: ({ ...props }) => (
            <Typography
              variant="subtitle1"
              noWrap
              sx={{ textTransform: 'capitalize' }}
            >
              {props.data[props.row.index]?.division?.id}
            </Typography>
          ),
        },
        {
          id: 'orderDate',
          Header: 'O. DATE',
          Cell: ({ ...props }) => {
            const date = props.data[props.row.index].orderDate;

            return (
              <>{date ? new Date(date).toLocaleDateString('default') : ''}</>
            );
          },
        },
        {
          id: 'startDate',
          Header: 'S. DATE',
          Cell: ({ ...props }) => {
            const date = props.data[props.row.index].startDate;

            return (
              <>{date ? new Date(date).toLocaleDateString('default') : ''}</>
            );
          },
        },
        {
          id: 'cancelDate',
          Header: 'C. DATE',
          Cell: ({ ...props }) => {
            const date = props.data[props.row.index].cancelDate;

            return <>{new Date(date).toLocaleDateString('default')}</>;
          },
        },
        {
          id: 'shipDate',
          Header: 'SH. DATE',
          Cell: ({ ...props }) => {
            const date = props.data[props.row.index].shipDate;

            return (
              <>{date ? new Date(date).toLocaleDateString('default') : ''}</>
            );
          },
        },
        {
          id: 'pickupDate',
          Header: 'SH. DATE',
          Cell: ({ ...props }) => {
            const date = props.data[props.row.index].pickupDate;

            return (
              <>{date ? new Date(date).toLocaleDateString('default') : ''}</>
            );
          },
        },
        {
          id: 'totalOrder',
          Header: 'T. QTY',
          Cell: ({ ...props }) => {
            const value = props.data[props.row.index].orderQuantity;

            return <>{value ? Number(value).toLocaleString('default') : ''}</>;
          },
        },
        {
          id: 'pickedQuantity',
          Header: 'P. QTY',
          Cell: ({ ...props }) => {
            const value = props.data[props.row.index].pickedQuantity;

            return <>{value ? Number(value).toLocaleString('default') : ''}</>;
          },
        },
        {
          id: 'openQuantity',
          Header: 'O. QTY',
          Cell: ({ ...props }) => {
            const value = props.data[props.row.index].openQuantity;

            return <>{value ? Number(value).toLocaleString('default') : ''}</>;
          },
        },
        {
          id: 'shippedQuantity',
          Header: 'SH. QTY',
          Cell: ({ ...props }) => {
            const value = props.data[props.row.index].shippedQuantity;

            return <>{value ? Number(value).toLocaleString('default') : ''}</>;
          },
        },
      ] as Column<Order>[],
    []
  );
  const data = useMemo(
    () => purchaseOrder.productionOrders ?? [],
    [purchaseOrder.productionOrders]
  );

  // ** state
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const onDownload = async (e: MouseEvent<HTMLElement>, id: ID) => {
    e.stopPropagation();
    setIsExporting(true);

    try {
      const { filename, content } = await download3PlReport(id);

      createDownloadLink(filename, content);
    } catch (e) {
      console.error(e);
    } finally {
      setIsExporting(false);
    }
  };

  if (!purchaseOrder) {
    return <>Not Found!</>;
  }

  if (isExporting) {
    return <CircularProgress />;
  }

  return (
    <DataGrid
      // @ts-ignore
      columns={columns}
      data={data}
      disableGlobalFilter={true}
      enableRowActions
      enableSelection={false}
      hideFooter={true}
      isFetching={isExporting}
      rowActionMenuItems={(row, _, onCloseMenu) => [
        <MenuItem
          key="row-action-download"
          onClick={async (e: MouseEvent<HTMLElement>) => {
            onCloseMenu();
            await onDownload(e, row.original?.pickTicket?.id);
          }}
        >
          <CloudDownloadIcon color="info" /> Download 3PL Report
        </MenuItem>,
      ]}
    />
  );
};

export default PurchaseOrderExpandedRow;
