import { FC } from 'react';

import { ID } from '../../models';

type Props = {
  selected: Array<ID>;
  deleteSelectedItems?: any;
};

export const ListHeaderSelectionActions: FC<Props> = (
  {
    selected = [],
    deleteSelectedItems,
  }) => (
  <div className="d-flex justify-content-end align-items-center">
    <div className="fw-bolder me-5">
      <span className="me-2">{selected.length}</span> Selected
    </div>

    <button
      type="button"
      className="btn btn-danger"
      onClick={async () => await deleteSelectedItems.mutateAsync()}
    >
      Delete Selected
    </button>
  </div>
);
