import { initialState, State } from './state';

export type RequestContextProps = {
  state: State;
  updateState: (updates: Partial<State>) => void;
};

export const initialRequest: RequestContextProps = {
  state: initialState,
  updateState: (_updates: Partial<State>) => {},
};
