export const SidebarFooter = () => {
  const appName = process.env.REACT_APP_NAME || '';
  const docsUrl = process.env.REACT_APP_DOCS_URL || '#';
  const version = process.env.REACT_APP_VERSION;

  return (
    <div
      className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
      id="kt_app_sidebar_footer"
    >
      <a
        href={docsUrl}
        target="_blank"
        rel="noreferrer"
        className="btn btn-flex flex-center btn-secondary overflow-hidden text-nowrap px-0 h-40px w-100"
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-dismiss-="click"
        title="AXNY Group App Version"
      >
        <span className="btn-label">{appName} v.{version}</span>
      </a>
    </div>
  );
};
