import { Box, Typography } from '@mui/material';
import { FC } from 'react';

interface NumberCellProps {
  locale?: string;
  value: number | string;
  decimals?: number;
}

export const NumberCell: FC<NumberCellProps> = ({
  value,
  locale = 'default',
  decimals = 0,
}) => (
  <Box
    sx={{
      width: '95%',
      display: 'flex',
      justifyContent: 'end',
      paddingRight: 5,
    }}
  >
    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
      {(!isNaN(+value) ? value : 0).toLocaleString(locale ?? 'default', {
        maximumFractionDigits: decimals,
      })}
    </Typography>
  </Box>
);
