import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

import { SVGImage } from '../../../../../../components';
import { Customer } from '../../../../../../models';

type Props = {
  customer?: Customer;
};

export const CustomerOptionOverview: FC<Props> = ({ customer }) => {
  const { id } = useParams();

  return (
    <>
      <div className="card mb-5 mb-xl-10" id="kt_customer_overview">
        <div className="card-header cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Customer Details</h3>
          </div>

          <Link to={`/third-parties/customers/${customer?.id}/options`} className="btn btn-primary align-self-center">
            Edit Options
          </Link>
        </div>

        <div className="card-body p-9">
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Customer ID</label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark">{customer?.id}</span>
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Customer Name</label>

            <div className="col-lg-8 fv-row">
              <span className="fw-bold fs-6">{customer?.name}</span>
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">
              Contact Phone
              <i
                className="fas fa-exclamation-circle ms-1 fs-7"
                data-bs-toggle="tooltip"
                title="Phone number must be active"
              ></i>
            </label>

            <div className="col-lg-8 d-flex align-items-center">
              <span className="fw-bolder fs-6 me-2">{customer?.phone}</span>

              <span className="badge badge-success">{customer?.state}</span>
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Address</label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 me-2">{customer?.address1}</span><br/>
              {customer?.address2 && (
                <>
                  <span className="fw-bolder fs-6 me-2">{customer?.address1}</span>
                  <br/>
                </>
              )}
              <span className="fw-bolder fs-6 me-2">{customer?.state}, {customer?.city}</span><br/>
              <span className="fw-bolder fs-6 me-2">{customer?.zip}</span>
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">
              Location
              <i
                className="fas fa-exclamation-circle ms-1 fs-7"
                data-bs-toggle="tooltip"
                title="Country of origination"
              ></i>
            </label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark">{customer?.country}, {customer?.state}, {customer?.city}</span>
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Communication</label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark">Email, Phone</span>
            </div>
          </div>

          <div className="row mb-10">
            <label className="col-lg-4 fw-bold text-muted">Allow Changes</label>

            <div className="col-lg-8">
              <span className="fw-bold fs-6">Only options</span>
            </div>
          </div>

          {!customer?.requirements?.length && (
            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
              <SVGImage
                path="/media/icons/duotune/general/gen044.svg"
                className="svg-icon-2tx svg-icon-warning me-4"
              />
              <div className="d-flex flex-stack flex-grow-1">
                <div className="fw-bold">
                  <h4 className="text-gray-800 fw-bolder">We need your attention!</h4>
                  <div className="fs-6 text-gray-600">
                    Customer has no defined options
                    <Link className="fw-bolder" to={`/third-parties/customers/${id}/options`}>
                      {' '}
                      Please add customer options
                    </Link>
                    .
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/*<div className="row gy-10 gx-xl-10">*/}
      {/*  <div className="col-xl-6">*/}
      {/*    <BarChart className="card-xxl-stretch mb-5 mb-xl-10"/>*/}
      {/*  </div>*/}

      {/*  <div className="col-xl-6">*/}
      {/*    <TablesWidget1 className="card-xxl-stretch mb-5 mb-xl-10"/>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="row gy-10 gx-xl-10">*/}
      {/*  <div className="col-xl-6">*/}
      {/*    <ListsWidget5 className="card-xxl-stretch mb-5 mb-xl-10"/>*/}
      {/*  </div>*/}

      {/*  <div className="col-xl-6">*/}
      {/*    <TablesWidget5 className="card-xxl-stretch mb-5 mb-xl-10"/>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};
