import { AxiosResponse } from 'axios';

import { ORDERS_URL } from '../../../api';
import { privateAxios } from '../../../api/axios';

export const getSummary = (query: string) => {
  return privateAxios
    .get(`${ORDERS_URL}/summary${query ? `?${query}` : ''}`)
    .then((response: AxiosResponse) => response?.data);
};
