import { Close as CloseIcon } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { Transition } from "../Transitions";

type Props = {
  onClose: (value?: string) => void,
  open: boolean,
  title: string,
  message: string,
};

export const ConfirmDialog: FC<Props> = (props: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { onClose, open, message, title, ...rest } = props;

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      fullScreen={fullScreen}
      open={open}
      TransitionComponent={Transition}
      {...rest}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h5">{title}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">
          {message}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={() => onClose('OK')} variant="contained">OK</Button>
        <Button onClick={() => onClose()} variant="outlined" color="error">Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
