import Skin from '../../layouts/types/Skin';
import { OwnerStateThemeType } from './';

const Snackbar = (skin: Skin) => {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          borderRadius: 8,
          padding: theme.spacing(1.75, 4),
          ...(skin === 'bordered' && { boxShadow: 'none' }),
          backgroundColor: `rgb(${(theme.palette as any).customColors.main})`,
          color: theme.palette.common[theme.palette.mode === 'light' ? 'white' : 'black'],
          '& .MuiSnackbarContent-message': {
            lineHeight: 1.429,
          },
        }),
      },
    },
  };
};

export default Snackbar;
