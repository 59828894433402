import { AxiosResponse } from 'axios';
import { stringifyRequestQuery } from "../helpers";

import { ID, Order } from '../models';
import { privateAxios } from './axios';

export const ORDERS_URL = 'orders';
const OPERATIONS_URL = 'operations';

export const approvePurchaseOrder = async (purchaseOrderID: ID, body: any) => {
  return privateAxios
    .post(`${OPERATIONS_URL}/set-stage/${purchaseOrderID}`, body, { withCredentials: true })
    .then((response) => response.data);
};

export const getOrderById = async ({ queryKey }: any): Promise<Order | undefined> => {
  const { id } = queryKey[1];

  return privateAxios
    .get(`${ORDERS_URL}/${id}`, { withCredentials: true })
    .then((response: AxiosResponse<Order>) => response.data);
};

export const exportSingleOrder = (id: string, options: any): any => {
  const query = stringifyRequestQuery(options);
  console.log(id);

  return privateAxios
    .get(
      `${ORDERS_URL}/${id}/export${query ? `?${query}` : ""}`,
      { withCredentials: true }
    )
    .then((response) => response.data);
};
