import { DataGridCell } from '@ialopezg/datagrid';
import FaceIcon from '@mui/icons-material/Face';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';

import IconWithTooltip from '../../../../../core/components/Icon/IconWithTooltip';
import {
  DateTimeCell,
  NumberCell,
  SVGImage,
  TileCell,
} from '../../../../components';
import { CustomerCell } from '../../../third-parties/customers/components/CustomerCell';
import { OrderOptionsCell } from '../../components';
import { PurchaseOrder } from '../../models';
import { ModuleType } from './PurchaseOrderList';

interface CreateColumnsFactoryProps {
  module: ModuleType;
  CustomerFilter?: ReactNode;
  DivisionFilter?: ReactNode;
  WarehouseFilter?: ReactNode;
}

export const createColumns = ({
  module = 'sales',
  CustomerFilter,
  DivisionFilter,
  WarehouseFilter,
}: CreateColumnsFactoryProps): Column<PurchaseOrder>[] => {
  return [
    {
      Header: 'PURCHASE ORDER',
      columns: [
        {
          accessor: 'reference' as const,
          minWidth: 220,
          Header: 'PO',
          Cell: ({ cell: { row } }: { cell: DataGridCell<PurchaseOrder> }) => {
            const order = row?.original;
            const { reference, status } = order;
            const isOpen = status === 'OPEN';
            const canPickup = !!order?.pickupDate;
            const isReady = !!order?.estimatedShipDate;

            const icon = order?.isCancelled
              ? 'file-slash'
              : order?.isShipped
              ? 'ship'
              : order?.isRouted
              ? 'route'
              : order?.hasAppointment
              ? 'timer'
              : order?.isReady
              ? 'check'
              : order?.isManufacturing
              ? 'warehouse'
              : order?.isPicked
              ? 'hand-holding-box'
              : 'box-open';
            const color =
              order?.isShipped || order?.hasAppointment
                ? 'success'
                : order?.isCancelled || order?.isRouted
                ? 'error'
                : isReady || canPickup
                ? 'success'
                : isOpen &&
                  order?.isPicked &&
                  order?.isManufacturing &&
                  order?.isRouted &&
                  !isReady
                ? 'error'
                : isOpen &&
                  order?.isPicked &&
                  order?.isManufacturing &&
                  !order?.isRouted &&
                  !isReady
                ? 'warning'
                : isOpen &&
                  order?.isPicked &&
                  !order?.isManufacturing &&
                  !order?.isRouted &&
                  !isReady
                ? 'info'
                : isOpen &&
                  !order?.isPicked &&
                  !order?.isManufacturing &&
                  !order?.isRouted &&
                  !isReady
                ? 'primary'
                : 'success';

            return (
              <TileCell
                title={reference}
                subtitle={<OrderOptionsCell objectValue={order} />}
                icon={
                  <SVGImage
                    path={`/media/icons/regular/${icon}.svg`}
                    className={`svg-icon-1 text-${
                      color === 'error' ? 'danger' : color
                    }`}
                  />
                }
                color={color}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  {order?.hasAttachments && (
                    <IconWithTooltip
                      enabled
                      icon="mdi:paperclip"
                      title="Purchase Order has files attached"
                    />
                  )}
                  {order?.hasNotes && (
                    <IconWithTooltip
                      enabled
                      icon="mdi:forum-outline"
                      title="Purchase Order has notes & comments"
                    />
                  )}
                  {order?.isRouted && (
                    <IconWithTooltip
                      enabled
                      icon="mdi:phone-forward"
                      title="Customer on Purchase Order has been contacted"
                    />
                  )}
                  {canPickup && (
                    <IconWithTooltip
                      enabled
                      icon="mdi:truck-cargo-container"
                      title="Purchase Order has pickup date"
                    />
                  )}
                </Box>
              </TileCell>
            );
          },
        },
        {
          accessor: 'customer' as const,
          disableClickToCopy: true,
          minWidth: 175,
          Header: 'CUST',
          Filter: () => CustomerFilter,
          Cell: ({
            cell: {
              row: { original },
            },
          }: {
            cell: DataGridCell<PurchaseOrder>;
          }) => {
            const customer = original?.customer;
            const active = !!customer?.active;

            return (
              <CustomerCell
                active={active}
                avatar={<FaceIcon color="primary" fontSize="large" />}
                id={customer?.id}
                onlyEnabled
                {...customer}
              />
            );
          },
        },
        {
          accessor: 'division' as const,
          minWidth: 110,
          Header: 'DIV',
          Filter: () => DivisionFilter,
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            !value ? (
              ''
            ) : value === 'MANY' ? (
              value
            ) : (
              <Link to={`/third-parties/divisions/${value}`}>{value}</Link>
            ),
        },
        {
          accessor: 'warehouse' as const,
          Header: 'WH',
          Filter: () => WarehouseFilter,
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            !value ? (
              ''
            ) : value === 'MANY' ? (
              value
            ) : (
              <Link to={`/third-parties/warehouses/${value}`}>{value}</Link>
            ),
        },
      ],
    },
    {
      Header: 'Dates',
      columns: [
        {
          accessor: 'warehouseApprovalDate' as const,
          Header: 'WH APPR',
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            value ? <DateTimeCell value={value} /> : '',
        },
        {
          accessor: 'startDate' as const,
          Header: 'START',
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            value ? <DateTimeCell value={value} /> : '',
        },
        {
          accessor: 'cancelDate' as const,
          Header: 'CANCEL',
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            value ? <DateTimeCell value={value} /> : '',
        },
        {
          accessor: 'pickupDate' as const,
          Header: 'PICKUP',
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            value ? <DateTimeCell value={value} /> : '',
        },
        {
          accessor: 'estimatedShipDate' as const,
          Header: 'WH. SHIP',
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            value ? <DateTimeCell value={value} /> : '',
        },
      ],
    },
    {
      Header: 'ORDER DETAILS',
      columns: [
        {
          accessor: 'totalOrders' as const,
          Header: 'CONTROLS',
          Cell: ({
            cell: {
              row: { original },
            },
          }: {
            cell: DataGridCell<PurchaseOrder>;
          }) => {
            const purchaseOrder = original;
            const value =
              module === 'sales'
                ? purchaseOrder.totalOrders
                : purchaseOrder?.pickedOrders;

            return value ? <NumberCell value={value} /> : '';
          },
        },
        {
          accessor: 'orderQuantity' as const,
          Header: 'T. QTY',
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            value ? <NumberCell value={value} /> : '',
        },
        {
          accessor: 'pickedQuantity' as const,
          Header: `${module !== 'sales' ? 'T' : 'P'}. QTY`,
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            value ? <NumberCell value={value} /> : '',
        },
        {
          accessor: 'openQuantity' as const,
          Header: 'O. QTY',
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            value ? <NumberCell value={value} /> : '',
        },
        {
          accessor: 'shippedQuantity' as const,
          Header: 'SH. QTY',
          Cell: ({ cell: { value } }: { cell: DataGridCell<PurchaseOrder> }) =>
            value ? <NumberCell value={value} /> : '',
        },
      ],
    },
  ] as unknown as Column<PurchaseOrder>[];
};
