import { IconButton, Tooltip, Zoom } from '@mui/material';
import { FC, MouseEventHandler, ReactElement } from 'react';

type Props = {
  className?: string,
  disabled?: boolean,
  icon?: ReactElement,
  onlyIcon?: boolean,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  title?: string,
};

export const BaseButton: FC<Props> = (
  {
    className,
    disabled,
    icon,
    onClick,
    onlyIcon,
    title,
  },
) =>
  (
    <Tooltip
      title={disabled ? '' : title}
      TransitionComponent={Zoom}
      arrow
    >
      <span>
        <IconButton
          className={className ?? `btn btn-color-primary me-3${disabled ? ' disabled-icon' : ''}`}
          disabled={disabled}
          onClick={onClick}
        >
          {icon && icon}
          {!onlyIcon && title}
        </IconButton>
      </span>
    </Tooltip>
  );
