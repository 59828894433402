import { createContext, FC, useContext, useState } from 'react';

import { WithChildren } from "../../core";
import { initialRequest, initialState, RequestContextProps, State } from "../models";

const RequestContext = createContext<RequestContextProps>(initialRequest);

export const RequestProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<State>(initialRequest.state);

  const updateState = (updates: Partial<State>) => {
    // applies new updates to current state
    const updatedState = { ...state, ...updates } as State;

    // update current state
    setState(updatedState);
  };

  return (
    <RequestContext.Provider value={{ state, updateState }}>
      {children}
    </RequestContext.Provider>
  );
};

export const useRequest = () => useContext(RequestContext);

export const useRequestState = () => {
  const { state } = useRequest();

  return state ? state : initialState;
};
export const useRequestFilters = () => {
  const { filter } = useRequestState();

  return filter ? filter : {};
};
