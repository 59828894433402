import { Column } from 'react-table';
import {
  ActionsCell,
  DefaultHeader,
  Switch,
  TileCell,
} from '../../../../../../components';
import { CustomerRequirement } from '../../../../../../models';
import { useListView } from '../../../../../../providers';
import { deleteCustomerOptionById, updateCustomerRequirement } from '../../../api';

export const createCustomerChecklistColumns = () => {
  return [
    {
      Header: (props) => (<DefaultHeader column={props.column} title="Division" />),
      accessor: 'divisionId',
      Cell: ({ ...props }) => (<TileCell title={props.data[props.row.index].divisionId!} />),
    },
    {
      Header: (props) => (<DefaultHeader column={props.column} title="RT" />),
      id: 'routing',
      Cell: ({ ...props }) => (<Switch
        checked={props.data[props.row.index].routing}
        size="small"
        type="outline"
        onChange={async (event) => {
          const option = { ...props.data[props.row.index], routing: event.target.checked };

          await updateCustomerRequirement(option?.id, option);
        }}
      />),
    },
    {
      Header: (props) => (<DefaultHeader column={props.column} title="FTN" />),
      id: 'fineLineTickets',
      Cell: ({ ...props }) => {
        return (<Switch
          checked={props.data[props.row.index].fineLineTickets}
          size="small"
          type="outline"
          onChange={async (event) => {
            const option = { ...props.data[props.row.index], fineLineTickets: event.target.checked };

            await updateCustomerRequirement(option?.id, option);
          }}
        />);
      },
    },
    {
      Header: (props) => (<DefaultHeader column={props.column} title="FLT" />),
      id: 'fineLineTrackingNo',
      Cell: ({ ...props }) => (<Switch
        checked={props.data[props.row.index].fineLineTrackingNo}
        size="small"
        type="outline"
        onChange={async (event) => {
          const option = { ...props.data[props.row.index], fineLineTrackingNo: event.target.checked };

          await updateCustomerRequirement(option?.id, option);
        }}
      />),
    },
    {
      Header: (props) => (<DefaultHeader column={props.column} title="PRT" />),
      id: 'printTickets',
      Cell: ({ ...props }) => (<Switch
        checked={props.data[props.row.index].printTickets}
        size="small"
        type="outline"
        onChange={async (event) => {
          const option = { ...props.data[props.row.index], printTickets: event.target.checked };

          await updateCustomerRequirement(option?.id, option);
        }}
      />),
    },
    {
      Header: (props) => (<DefaultHeader column={props.column} title="PLL" />),
      id: 'palletLabels',
      Cell: ({ ...props }) => (<Switch
        checked={props.data[props.row.index].palletLabels}
        size="small"
        type="outline"
        onChange={async (event) => {
          const option = { ...props.data[props.row.index], palletLabels: event.target.checked };

          await updateCustomerRequirement(option?.id, option);
        }}
      />),
    },
    {
      Header: (props) => (<DefaultHeader column={props.column} title="UCC" />),
      id: 'uccLabels',
      Cell: ({ ...props }) => {
        console.log('database option UCC', props.data[props.row.index].uccLabels);
        return (<Switch
          checked={props.data[props.row.index].uccLabels}
          size="small"
          type="outline"
          onChange={async (event) => {
            const option = { ...props.data[props.row.index], uccLabels: event.target.checked };

            await updateCustomerRequirement(option?.id, option);
          }}
        />);
      },
    },
    {
      Header: (props) => (<DefaultHeader column={props.column} title="SKU" />),
      id: 'individualSKUCartons',
      Cell: ({ ...props }) => (<Switch
        checked={props.data[props.row.index].individualSKUCartons}
        size="small"
        type="outline"
        onChange={async (event) => {
          const option = { ...props.data[props.row.index], individualSKUCartons: event.target.checked };

          await updateCustomerRequirement(option?.id, option);
        }}
      />),
    },
    {
      Header: (props) => (<DefaultHeader column={props.column} title="Days Before" />),
      id: 'beforeDates',
      Cell: ({ ...props }) => (<TileCell
        title={`S.DATE: ${props.data[props.row.index].daysBeforeStartDate || 0}`}
        subtitle={`C.DATE: ${props.data[props.row.index].daysBeforeCancelDate || 0}`}
      />),
    },
    {
      Header: (props) => (<DefaultHeader column={props.column} title="Days Before" className="text-end min-w-100px" />),
      id: 'actions',
      Cell: ({ ...props }) => {
        const { setItemForUpdate } = useListView();

        return (<ActionsCell
          type="horizontal"
          id={props.data[props.row.index].uuid}
          enableEdit={true}
          editAction={(id) => setItemForUpdate(id)}
          enableDelete={true}
          deleteAction={async () => deleteCustomerOptionById(props.data[props.row.index].uuid)}
        />);
      },
    },
  ] as Array<Column<CustomerRequirement>>;
}
