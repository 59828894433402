import { createContext, FC, useContext, useState } from 'react';

import { PageContextModel, PageLink } from '../interfaces';
import { WithChildren } from '../../WithChildren';

const PageContext = createContext<PageContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
});

export const PageProvider: FC<WithChildren> = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageDescription, setPageDescription] = useState<string>('');
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
  const value: PageContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  };

  return (<PageContext.Provider value={value}>{children}</PageContext.Provider>);
};

export const usePage = () => useContext(PageContext);
