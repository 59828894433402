import { AxiosResponse } from 'axios';
import { FieldValues } from 'react-hook-form';

import { ID, ProductionOrder } from '../../../models';
import { privateAxios } from '../../../api/axios';
import { ORDERS_URL } from '../../../api';
import { PurchaseOrder } from '../models';

const PRODUCTION_ORDERS_URL = `${ORDERS_URL}/production`;

export const getProductionOrdersList = (query: string) => {
  return privateAxios
    .get(`${PRODUCTION_ORDERS_URL}?${query}`, { withCredentials: true })
    .then((response) => response.data);
};

export const purchaseOrderGetReady = (
  id: ID,
  body: FieldValues
): Promise<PurchaseOrder> => {
  return privateAxios
    .patch(`${PRODUCTION_ORDERS_URL}/${id}/get/ready`, body)
    .then((response) => response.data);
};

export const getProductionOrderByID = async ({
  queryKey,
}: any): Promise<any> => {
  const id = encodeURIComponent(queryKey[1].id);

  return privateAxios
    .get(`${PRODUCTION_ORDERS_URL}/${id}`, { withCredentials: true })
    .then((response: AxiosResponse<ProductionOrder>) => response.data);
};

export const updateProductionOrder = (
  id: ID,
  body: { [key: string]: any }
): Promise<PurchaseOrder> => {
  id = encodeURIComponent(String(id));

  return privateAxios
    .patch(`${PRODUCTION_ORDERS_URL}/${id}`, body, { withCredentials: true })
    .then((response: AxiosResponse<PurchaseOrder>) => response.data)
    .then((response: PurchaseOrder) => response);
};

export const exportOrders = (query: string): any => {
  return privateAxios
    .get(`${PRODUCTION_ORDERS_URL}/export?${query}`, { withCredentials: true })
    .then((response) => response.data);
};
