import { Chip, Grid } from '@mui/material';
import { FC } from 'react';

import { Option } from '../../Prototypes';

type Props = {
  id: string,
  options: Option[];
};

export const OptionListCell: FC<Props> = ({ id, options }) => {
  return(
    <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
      {options.map((option, index) => (
        // <Chip key={`status-${index}`}>{option.label}</Chip>
        <></>
      ))}
    </Grid>
  );
}

export default OptionListCell;
