import { AxiosResponse } from 'axios';
import { ID } from '../../../models';
import { PurchaseOrder } from '../models';

import { ORDERS_URL } from '../../../api';
import { privateAxios } from '../../../api/axios';

export const PURCHASE_ORDERS_URL = `${ORDERS_URL}/purchases`;

export const getPurchaseOrderByID = (id: ID): Promise<PurchaseOrder> => {
  return privateAxios
    .get(`${PURCHASE_ORDERS_URL}/${encodeURIComponent(String(id))}`, {
      withCredentials: true,
    })
    .then((response: AxiosResponse) => response.data);
};

export const exportPurchaseOrders = async (queryString: string) => {
  queryString = queryString.length ? `?${queryString}` : '';

  const response = await privateAxios.get(
    `${PURCHASE_ORDERS_URL}/export${queryString}`
  );
  return response.data;
};

export const exportPurchaseOrder3PLReport = async (id: ID) => {
  const response = await privateAxios.get(
    `${PURCHASE_ORDERS_URL}/${encodeURIComponent(String(id))}/export/3pl`
  );

  return response.data;
};
