import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import DatePickerInput from "./DatePickerInput";
import { DateType } from "./DateType";

type Props = {
  popperPlacement: ReactDatePickerProps["popperPlacement"],
  label?: string,
  onChange?: (start: DateType, end: DateType) => void;
  selected?: DateType[];
};

export const DateRangePicker: FC<Props> = (
  {
    popperPlacement,
    label,
    onChange,
    selected
  }
) => {
  const [startDate, setStartDate] = useState<DateType>(selected?.[0]);
  const [endDate, setEndDate] = useState<DateType>(selected?.[1]);

  const onChangeDates = (start?: DateType, end?: DateType) => {
    start && setStartDate(start);
    end && setEndDate(start);

    if (onChange) {
      onChange(startDate, endDate);
    }
  };

  useEffect(() => {
    (() => {
      if (onChange) {
        onChange(startDate, endDate);
      }
    })();
  }, [startDate, endDate]);

  return (<Box
    sx={{ display: "flex", flexWrap: "wrap", mb: 2, mr: 5 }}
  >
    <div>
      <DatePicker
        selected={startDate}
        onChange={(date) => onChangeDates(date)}
        customInput={<DatePickerInput label={`${label ? label : "Start range"}`} />}
        popperPlacement={popperPlacement}
      />
    </div>
    <div>
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        customInput={<DatePickerInput label={`${label ? label : "End range"}`} />}
        popperPlacement={popperPlacement}
      />
    </div>
  </Box>);
};

export default DateRangePicker;
