/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';

import { usePage } from './providers';
import { WithChildren } from '../WithChildren';

export const PageDescription: FC<WithChildren> = ({ children }) => {
  const { setPageDescription } = usePage();

  useEffect(() => {
    if (children) {
      setPageDescription(children.toString());
    }

    return () => {
      setPageDescription('');
    };
  }, [children]);

  return (<></>);
};