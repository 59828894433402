import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { SVGImage } from '../../../../../components';
import { QUERIES } from '../../../../../constants';
import { Division, initialState } from '../../../../../models';
import { useRequest, useResponse } from '../../../../../providers';

import { getDivisionsWithOpenOrders } from '../../../../third-parties';

export const ShippingOrderListFilterAction = () => {
  const { updateState } = useRequest();
  const { isLoading } = useResponse();
  const [status, setStatus] = useState<string | undefined>('ALL');
  const [division, setDivision] = useState<string | undefined>('ALL');
  const { data: divisions } = useQuery(QUERIES.DIVISIONS, getDivisionsWithOpenOrders);

  useEffect(() => {
  }, []);

  const resetData = () => {
    setStatus('OPEN');
    setDivision('ALL');
    updateState({
      filter: { status, division },
      ...initialState,
    });
  };

  const filterData = () => {
    updateState({
      filter: {
        status: status ? status : 'ALL',
        division: division ? division : 'ALL',
      },
      ...initialState,
    });
  };

  return (
    <>
      {/* begin::Filter Fab */}
      <button
        disabled={isLoading}
        type="button"
        className="btn btn-sm btn-light-primary me-3"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        <SVGImage path="/media/icons/duotune/general/gen031.svg" className="svg-icon-2" />
        Filters
      </button>
      {/* end::Filter Fab */}
      {/* begin::SubMenu */}
      <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
        {/* begin::Header */}
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className="separator border-gray-200"></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className="px-7 py-5" data-kt-user-table-filter="form">
          {/* begin::Input group */}
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">Division:</label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-hide-search="true"
              onChange={(e) => setDivision(e.target.value)}
              value={division}
            >
              <option value="ALL">ALL DIVISIONS</option>
              {(divisions as Division[])?.map(({ id, name }) => (
                <option key={`option-${id}`} value={id?.toString()}>{id} - {name}</option>
              ))}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">Warehouse:</label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-hide-search="true"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value="All">ALL</option>
              <option value="ACTIVE">ACTIVE</option>
              <option value="INACTIVE">INACTIVE</option>
              <option value="DEACTIVATE">DEACTIVATE</option>
            </select>
          </div>
          {/* end::Input group */}


          {/* begin::Actions */}
          <div className="d-flex justify-content-end">
            <button
              type="button"
              disabled={isLoading}
              onClick={resetData}
              className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="reset"
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type="button"
              onClick={filterData}
              className="btn btn-primary fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter"
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  );
};
