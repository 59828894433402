import { isNotEmpty } from '@ialopezg/commonjs';
import qs from 'qs';

import { State } from '../models';

/**
 * Stringify the state object.
 *
 * @example page=1&take=10&fieldName=id&direction=desc&search=a&filterName=a&filterOnline=false
 *
 * @param state Object to be stringify.
 */
export const stringifyRequestQuery = (state: State): string => {
  const pagination = qs.stringify(state, {
    filter: ['page', 'take', 'maxPages'],
    skipNulls: true,
  });
  const sort = qs.stringify(state, {
    filter: ['fieldName', 'direction'],
    skipNulls: true,
  });
  const search = isNotEmpty(state?.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : '';
  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter(
          ([_, value]) =>
            value !== undefined && String(value).toLowerCase() !== 'undefined'
        )
        .map(([key, value]) => {
          key = `filterBy${key[0].toUpperCase()}${key.slice(1)}`;
          return `${key}=${value}`;
        })
        .join('&')
    : '';

  return [pagination, sort, search, filter]
    .filter((filter) => filter)
    .join('&');
};
