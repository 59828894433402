import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { MasterLayout } from '../../theme/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { getCSSVariableValue } from '../../theme/assets/ts/_utils';
import { WithChildren } from '../../core';

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/account/AccountPage'))
  const ThirdPartiesPage = lazy(() => import('../modules/third-parties/ThirdPartiesPage'));
  const OrdersPage = lazy(() => import('../modules/orders/OrdersPage'));
  const ToolsPage = lazy(() => import('../modules/tools/ToolsPage'));
  const UsersPage = lazy(() => import('../modules/users/UsersPage'));
  const ProductionPage = lazy(() => import('../modules/production/ProductionPage'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route
          path="account/*"
          element={
            <SuspenseView>
              <AccountPage />
            </SuspenseView>
          }
        />
        <Route
          path="orders/*"
          element={
            <SuspenseView>
              <OrdersPage />
            </SuspenseView>
          }
        />
        <Route
          path="operations/picktkt/*"
          element={
            <SuspenseView>
              <OrdersPage />
            </SuspenseView>
          }
        />
        <Route
          path="production/*"
          element={
            <SuspenseView>
              <ProductionPage />
            </SuspenseView>
          }
        />
        <Route
          path="third-parties/*"
          element={
            <SuspenseView>
              <ThirdPartiesPage />
            </SuspenseView>
          }
        /> s
        <Route
          path="tools/*"
          element={
            <SuspenseView>
              <ToolsPage />
            </SuspenseView>
          }
        />
        <Route
          path="security/users/*"
          element={
            <SuspenseView>
              <UsersPage />
            </SuspenseView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export const SuspenseView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
