import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';

import { LayoutSplashScreen } from '../../../../theme/layout/core';
import { AuthModel, User } from './_models';
import * as authHelper from './AuthHelpers';
import { getUserByToken } from "../api";
import { WithChildren } from "../../../../core";

type AuthContextProps = {
  auth: AuthModel | undefined
  setAuth: (auth: AuthModel | undefined) => void
  currentUser: User | undefined
  setCurrentUser: Dispatch<SetStateAction<User | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  setAuth: () => {
  },
  currentUser: undefined,
  setCurrentUser: () => {
  },
  logout: () => {
  },
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<User | undefined>();

  useEffect(() => {});

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = async () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  return (
    <AuthContext.Provider value={{
      auth,
      setAuth: saveAuth,
      currentUser,
      setCurrentUser,
      logout,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = await getUserByToken();
          if (user) {
            setCurrentUser(user);
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (auth) {
      void requestUser();
    } else {
      logout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};
