import axios, { AxiosResponse } from 'axios';
import { privateAxios } from '../../../../api/axios';

import { Customer, CustomerResponse, ID, Response } from '../../../../models';
import { API_BASE_URL } from '../../../auth';

const THIRD_PARTIES_URL = `${API_BASE_URL}/third-parties`;
export const CUSTOMER_URL = `${THIRD_PARTIES_URL}/customers`;

export const getCustomers = (query: string): Promise<CustomerResponse> => {
  return axios
    .get(`${CUSTOMER_URL}?${query}`, { withCredentials: true })
    .then((response: AxiosResponse<CustomerResponse>) => response.data);
};

export const getCustomerById = (id: ID): Promise<Customer> => {
  return axios
    .get(`${CUSTOMER_URL}/${id}`, { withCredentials: true })
    .then((response: AxiosResponse<Customer>) => response.data)
    .catch((error) => error.response.data);
};

export const createCustomer = (
  customer: Customer
): Promise<Customer | undefined> => {
  return axios
    .put(CUSTOMER_URL, customer, { withCredentials: true })
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data);
};

export const updateCustomer = (
  customer: Customer
): Promise<Customer | undefined> => {
  return axios
    .post(`${CUSTOMER_URL}/${customer.id}`, customer, { withCredentials: true })
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data);
};
