import { getPickedOrders } from '../../api';
import { ModalDialogProvider } from '../../../../components';
import { QUERIES } from '../../../../constants';
import {
  ListViewProvider,
  RequestProvider,
  ResponseProvider,
} from '../../../../providers';
import { PurchaseOrderList } from '../../purchases';

const queryKey = QUERIES.SHIPPING_ORDERS;

const ShippingOrdersList = () => <PurchaseOrderList module="picked" />;

export const ShippingOrdersListWrapper = () => (
  <RequestProvider>
    <ResponseProvider callback={getPickedOrders} queryKey={queryKey}>
      <ListViewProvider>
        <ModalDialogProvider>
          <ShippingOrdersList />
        </ModalDialogProvider>
      </ListViewProvider>
    </ResponseProvider>
  </RequestProvider>
);
