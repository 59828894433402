import { isUndefined } from '@ialopezg/commonjs';
import { Component, ReactElement } from 'react';

import { ListOption } from './ListOption';

import './ListBox.scss';

export interface ListBoxProps {
  disabled?: boolean;
  displayTexts?: {
    item: string,
    items: string,
    noItemSelectedTex: string,
    search: string,
  }
  onTransfer?: Function;
  multiple?: boolean;
  options: Array<ListOption>,
  transferActionButton?: ReactElement | string;
}

export interface ListBoxState {
  filterKeyword: string;
  selectedValues: Array<string>;
}

export class ListBox extends Component<ListBoxProps, ListBoxState> {
  constructor(props: ListBoxProps) {
    super(props);

    this.state = {
      filterKeyword: '',
      selectedValues: [],
    };
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onTransferButtonClick = this.onTransferButtonClick.bind(this);
  }

  onSearchChange(event: any): void {
    const filterKeyword = event.target.value;

    this.setState({ filterKeyword });
  }

  onSelectionChange(event: any): void {
    const { options } = event.target;

    const selectedValues = [...options]
      .filter((option: any)  => option.selected)
      .map((option: any) => String(option.value));

    this.setState({ selectedValues });
  }

  onTransferButtonClick(event: any): void {
    if (!isUndefined(this.props.onTransfer)) {
      (this.props.onTransfer as CallableFunction)(this.state.selectedValues);
    }

    this.setState({ selectedValues: [] });
  }

  render() {
    const {
      disabled,
      displayTexts,
      multiple,
      options,
      transferActionButton,
    } = this.props;
    const { filterKeyword, selectedValues } = this.state;
    const filteredOptions = options
      .filter((option) => option.label.toLowerCase().includes(filterKeyword));

    let itemCount = '';
    switch (filteredOptions.length) {
      case 0:
        itemCount = displayTexts?.noItemSelectedTex || 'No items';
        break
      case 1:
        itemCount = `${filteredOptions.length} ${displayTexts?.item || 'item'}`;
        break;
      default:
        itemCount = `${filteredOptions.length} ${displayTexts?.items || 'items'}`;
        break;
    }

    return (
      <div className="list-box">
        <input
          value={filterKeyword}
          disabled={this.props.disabled}
          onChange={this.onSearchChange}
          placeholder={displayTexts?.search || 'Search...'}
        />
        <button
          type="button"
          onClick={this.onTransferButtonClick}
          disabled={options.length === 0 || disabled}
        >
          {
            !isUndefined(transferActionButton)
              ? typeof transferActionButton === 'string' ? String(transferActionButton) : transferActionButton
              : 'Transfer'
          }
        </button>

        <select
          disabled={disabled}
          multiple={isUndefined(multiple) ? true : multiple}
          onChange={this.onSelectionChange}
          value={selectedValues}
        >
          {this.props.options.map((option, index) => (
            <option
              value={String(option.value)}
              key={`key-${index}-${option.label}`}
            >
              {option.label}
            </option>
          ))}
        </select>

        <span className="item-count">{itemCount}</span>
      </div>
    );
  }
}
