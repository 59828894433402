import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import { FC, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';

import CardSnippet from '../../../../../components/Cards/snippet';
import PickerCustomInput from '../../../../../components/Pickers/PickerCustomInput';
import { useResponse } from '../../../../../providers';
import { updatePurchaseOrder } from '../../../api';
import { PurchaseOrder } from '../../../models';

interface PurchaseOrderFormRoutingProps {
  onCloseAction?: () => void;
  purchaseOrder?: PurchaseOrder;
}

export const PurchaseOrderFormRouting: FC<PurchaseOrderFormRoutingProps> = ({
  onCloseAction,
  purchaseOrder,
}) => {
  const { reload } = useResponse();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const schema = yup.object().shape({
    customerContacted: yup.boolean(),
    carrierName: yup.string().nullable(),
    confirmationNumber: yup.string().nullable(),
    billOfLandingNumber: yup.string().nullable(),
    pickupDate: yup.date().nullable().default(undefined),
    startTimeframe: yup.date().nullable().default(undefined),
    endTimeframe: yup.date().nullable().default(undefined),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customerContacted: purchaseOrder?.customerContacted,
      carrierName: purchaseOrder?.carrierName,
      confirmationNumber: purchaseOrder?.confirmationNumber,
      billOfLandingNumber: purchaseOrder?.billOfLandingNumber,
      pickupDate: purchaseOrder?.pickupDate
        ? new Date(String(purchaseOrder?.pickupDate))
        : null,
      startTimeframe: purchaseOrder?.startTimeframe
        ? new Date(String(purchaseOrder?.startTimeframe))
        : null,
      endTimeframe: purchaseOrder?.endTimeframe
        ? new Date(String(purchaseOrder?.endTimeframe))
        : null,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmitAction = async (values: FieldValues) => {
    setIsSubmitting(true);

    try {
      await updatePurchaseOrder(purchaseOrder?.id, values);

      await reload();
      reset();
      onCloseAction?.();
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    reset();
    onCloseAction?.();
  };

  return (
    <CardSnippet title="Routing">
      <form onSubmit={handleSubmit(onSubmitAction)}>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 6 }}>
            <Controller
              control={control}
              name="customerContacted"
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={<Switch />}
                  label="Customer contacted?"
                  checked={!!value}
                  onChange={(e, value) => onChange(value)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 6 }}>
            <Controller
              control={control}
              name="carrierName"
              render={({ field: { value, onChange } }) => (
                <TextField
                  label="Carrier name"
                  onChange={onChange}
                  placeholder="Carrier name"
                  value={value}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 6 }}>
            <Controller
              control={control}
              name="confirmationNumber"
              render={({ field: { value, onChange } }) => (
                <TextField
                  label="Confirmation #"
                  onChange={onChange}
                  placeholder="Confirmation number"
                  value={value}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 6 }}>
            <Controller
              control={control}
              name="billOfLandingNumber"
              render={({ field: { value, onChange } }) => (
                <TextField
                  label="BOL #"
                  onChange={onChange}
                  placeholder="Bill Of Landing number"
                  value={value}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 6 }}>
            <Controller
              control={control}
              name="pickupDate"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  selected={value}
                  popperPlacement="bottom-start"
                  onChange={onChange}
                  placeholderText="Select a pickup date"
                  customInput={<PickerCustomInput label="Pickup date" />}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ mb: 6 }}>
              <Controller
                control={control}
                name="startTimeframe"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    selected={value}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="h:mm aa"
                    popperPlacement="bottom-start"
                    onChange={onChange}
                    placeholderText="Select a pickup time"
                    customInput={
                      <PickerCustomInput label="Start pickup timeframe" />
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ mb: 6 }}>
              <Controller
                control={control}
                name="endTimeframe"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    selected={value}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="h:mm aa"
                    popperPlacement="bottom-start"
                    onChange={onChange}
                    placeholderText="Select an end pickup time"
                    customInput={
                      <PickerCustomInput label="End pickup timeframe" />
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Button
              disabled={isSubmitting}
              type="submit"
              fullWidth
              variant="contained"
              size="large"
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={isSubmitting}
              color="error"
              fullWidth
              onClick={handleClose}
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardSnippet>
  );
};

export default PurchaseOrderFormRouting;
