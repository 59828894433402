import axios, { AxiosResponse } from 'axios';

import { PickTicketResponse } from '../models';
import { API_BASE_URL } from '../modules/auth';

const PICKTKT_URL = `${API_BASE_URL}/pick-ticket`;


export const getPickTicketOrders = (query: string): Promise<PickTicketResponse> => {
  return axios
    .get(`${PICKTKT_URL}?${query}`, { withCredentials: true })
    .then((response: AxiosResponse<PickTicketResponse>) => response.data);
};
