import {
  Card,
  ListHeader,
  ModalDialogProvider,
} from '../../../../components';
import { QUERIES } from '../../../../constants';
import {
  ListViewProvider,
  RequestProvider,
  ResponseProvider,
  useListView,
} from '../../../../providers';
import { getWarehouses } from '../api';
import { WarehouseTable } from './table';

const queryKey = QUERIES.DIVISIONS;

const WarehouseList = () => {
  const { itemForUpdate } = useListView();

  return (
    <>
      <Card>
        <ListHeader queryKey={queryKey} />
        <WarehouseTable />
      </Card>
      {itemForUpdate !== undefined && (<>Warehouse Form</>)}
    </>
  );
};

export const WarehouseListWrapper = () => (
  <RequestProvider>
    <ResponseProvider callback={getWarehouses} queryKey={queryKey}>
      <ListViewProvider>
        <ModalDialogProvider>
          <WarehouseList />
        </ModalDialogProvider>
      </ListViewProvider>
    </ResponseProvider>
  </RequestProvider>
);