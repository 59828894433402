import { Dispatch, SetStateAction } from 'react';
import { ID } from '../models';

export const checkIfGroupingIsDisabled = <T>(isLoading: boolean, data: Array<T> | undefined): boolean => {
  if (isLoading) {
    return true;
  }

  return !data || !data.length;
}

export const checkIfAllDataSelected = <T>(data: Array<T> | undefined, selected: Array<ID>): boolean => {
  if (!data) {
    return false;
  }

  return data.length > 0 && data.length === selected.length;
}

export const groupingOnSelect = (id: ID, selected: Array<ID>, setSelected: Dispatch<SetStateAction<Array<ID>>>) => {
  if (!id) {
    return;
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id));
  } else {
    const updatedSelected = [...selected];
    updatedSelected.push(id);
    setSelected(updatedSelected);
  }
}

export const groupingOnSelectAll = <T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID }>,
) => {
  if (isAllSelected) {
    setSelected([]);
    return;
  }

  if (!data || !data.length) {
    return;
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id));
}
