import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import { AutocompleteWidget } from '../../../../../components';
import { QUERIES } from '../../../../../constants';
import { stringifyRequestQuery } from '../../../../../helpers';
import { State } from '../../../../../models';
import { getDivisions } from '../../api';

type Props = {
  disabled?: boolean;
  onChange?: (division: string) => void;
  selected?: string;
  module?: string;
};

export const DivisionSelectWidget: FC<Props> = ({
  disabled,
  onChange = (division) => division,
  module,
  selected,
}) => {
  const { data } = useQuery(QUERIES.DIVISIONS, async () => {
    const options = {
      page: -1,
      filter: {
        [String(module)]: module ? true : undefined,
        customer: undefined,
        warehouse: undefined,
      },
    } as State;
    const query = stringifyRequestQuery(options);

    return getDivisions(query);
  });

  const options = useMemo(
    () =>
      (data?.data || [])
        .map((d) => ({
          label: String(d?.name),
          value: String(d?.id),
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <AutocompleteWidget
      disabled={disabled}
      options={options}
      onChange={onChange}
      selected={selected}
      title="Division"
    />
  );
};
