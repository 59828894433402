import {
  CardBody,
  CircularSpinner,
  DataTable,
} from '../../../../../components';
import { Pagination } from '../../../../../components/pagination';
import { useResponseData, useResponseLoading } from '../../../../../providers';
import { useMemo } from 'react';

export const DivisionsTable = () => {
  const data = useResponseData();
  const isLoading = useResponseLoading();
  const options = useMemo(() => {
    return {
      data: {
        columns: [
          { id: 'id', label: 'ID' },
          { id: 'name', label: 'NAME' },
          { id: 'entryDate', label: 'E. DATE', type: 'date' },
          { id: 'active', label: 'ACTIVE', type: 'boolean' },
        ],
        rows: data,
      },
      features: {
        configuration: {
          columnsOrder: ['id', 'name', 'entryDate', 'active'],
        },
      },
      keyColumn: 'id',
    };
  }, [data]);

  return (
    <CardBody className="py-4">
      <div className="table-responsive">
        <DataTable options={options} />
      </div>

      <Pagination />
      {isLoading && <CircularSpinner />}
    </CardBody>
  );
};
