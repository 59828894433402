export const createDownloadLink = (filename: string, content: string) => {
  // Create blob link to download
  const url = window.URL.createObjectURL(
    new Blob([content]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    filename,
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  document.body.removeChild(link);
}