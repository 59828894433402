import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  ToggleButtonGroupPropsColorOverrides,
} from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import { OverridableStringUnion } from "@mui/types";
import { FC } from "react";
import { SVGImage } from "../image";

type Props = {
  color?: OverridableStringUnion<"primary" | "standard" | "secondary" | "error" | "info" | "success" | "warning", ToggleButtonGroupPropsColorOverrides> | undefined,
  disabled?: boolean,
  onlyIcons?: boolean,
  onlyText?: boolean,
  onStatusChange?: (status: string) => void,
  selected?: string,
  values?: string[];
};

export const ToggleButtons: FC<Props> = (
  {
    color = 'primary',
    disabled = false,
    onStatusChange = (status) => status,
    selected,
    values = []
  }
) => {
  const icons: any = [
    { name: "ALL", icon: "ballot", className: "gray", title: "all" },
    { name: "OPEN", icon: "box-open", className: "primary", title: "open" },
    { name: "CNCL", icon: "ban", className: "danger", title: "cancelled" },
    { name: "SHIP", icon: "ship", className: "success", title: "shipped" },
    { name: "SHIPPING", icon: "ship", className: "success", title: "shipped" },
    { name: "WAREHOUSE", icon: "warehouse", className: "warning", title: "warehouse" },
    { name: "ROUTING", icon: "route", className: "primary", title: "routing" },
    { name: "READY", icon: "check", className: "success", title: "ready" }
  ];

  const getIcon = (iconName: string) => icons
    .find((icon: any) => icon.name === iconName);

  return (
    <ToggleButtonGroup
      color={color}
      value={selected}
      exclusive
      onChange={(_, value) => onStatusChange(value)}
      aria-label="..."
    >
      {values.map((value, index) => {
        const icon = getIcon(value);

        return (
          <ToggleButton
            key={`tg-${index}`}
            value={value}
            aria-label={`Display ${value.toLowerCase()}`}
            disabled={disabled || selected === value}
          >
            <SVGImage
              path={`/media/icons/${icon.icon}.svg`}
              className={`text-${icon.className}`}
            />
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};