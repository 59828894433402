import { Grid } from "@mui/material";
import { useField } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import { SingleFileUploadProgress } from "./SingleFileUploadProgress";
import { UploadError } from "./UploadError";

interface StreamableFile {
  file: File,
  errors: FileError[],
}

interface Props {
  name: string
}

export const UploadBox: FC<Props> = ({ name }) => {
  const [_, __, helpers] = useField(name);
  const [files, setFiles] = useState<StreamableFile[]>([]);
  const onDrop = useCallback((accepted: File[], rejected: FileRejection[]) => {
    const files = accepted.map((file) => ({ file, errors: [] }));
    const errors = rejected.map((file) => ({ ...file }));

    setFiles((current) => [...current, ...files, ...errors]);
  },[]);
  const { getInputProps, getRootProps } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
  });

  useEffect(() => {
    helpers.setValue(files);
  }, [files]);

  return (
    <>
      <Grid item>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />

          <p>Drag and drop some files here, or click to select files</p>
        </div>
      </Grid>

      {files.map((file, index) => (
        <Grid item key={`file-${index}`}>
          {file.errors.length ? (
            <UploadError
              file={file.file}
              onDelete={(file) => {}}
              errors={file.errors}
            />
          ) : (
            <SingleFileUploadProgress
              file={file.file}
              onDelete={(file) => {}}
              onUpload={(file) => {}}
            />
          )}
        </Grid>
      ))}
    </>
  );
};
