import { DataOptions } from './DataOptions';
import { DimensionsOptions } from './DimensionsOptions';
import { FeatureOptions } from './FeatureOptions';

export interface DataTableOptions {
  data: DataOptions;

  dimensions?: DimensionsOptions;

  features?: FeatureOptions;

  font?: string;

  keyColumn?: string,

  /**
   * DataTable title.
   */
  title?: string;
}

export const initialDataTableOptions: DataTableOptions = {
  data: {
    columns: [],
    rows: [],
  },
  dimensions: {
    table: {
      width: '100vw',
    },
    header: {
      height: '60px',
    },
    body: {
      height: '300px',
    },
    row: {
      height: '60px',
    },
  },
  features: {
    additionalIcons: [],
    allowSaveConfiguration: false,
    configuration: {
      columnsOrder: [],
      enableCopyToClipboard: false,
    },
    enableDownload: false,
    enableEdit: false,
    enableFiltering: false,
    enablePrint: false,
    enableRefresh: false,
    enableSearch: false,
    pagination: {
      pageSizes: [10, 25, 50, 100],
      take: 10,
    },
    selection: {
      allowAllRowsSelection: false,
      allowPageRowsSelection: false,
      allowRowsSelection: false,
    },
    selectionIcons: [],
  },
  font: 'Arial',
  keyColumn: undefined,
  title: undefined,
};