import { TextField } from "@mui/material";
import { forwardRef } from "react";

interface PickerProps {
  label?: string;
  readonly?: boolean;
}

export const DatePickerInput = forwardRef(({ ...props }: PickerProps, ref) => {
  const { label, readonly } = props;

  return (
    <TextField
      inputRef={ref}
      {...props} label={label || ''}
      {...(readonly && { inputProps: { readOnly: true } })}
    />
  );
});

export default DatePickerInput;
