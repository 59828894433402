import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC, useEffect } from 'react';

import {
  Card,
  ListHeader,
  ListTable,
  ListToolbar,
  FormDialog,
  ModalDialogProvider,
  SVGImage,
} from '../../../../../../components';
import { useModalDialog } from '../../../../../../components';
import { QUERIES } from '../../../../../../constants';
import {
  ListViewProvider,
  RequestProvider,
  ResponseProvider,
  useListView,
} from '../../../../../../providers';
import { Customer } from '../../../../../../models';
import { getCustomerRequirements } from '../../../api';
import { CustomerOptionsFormWrapper } from './CustomerOptionsFormWrapper';
import { createCustomerChecklistColumns } from './CustomerOptionsTableColumns';

export const customerOptionsTableId = 'table_customer_options';

type Props = {
  customer?: Customer;
};

const CustomerOptionsList: FC<Props> = ({ customer }) => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const { setTitle } = useModalDialog();

  useEffect(() => {
    const setModalDialogTitle = (title: string) => setTitle(title);

    setModalDialogTitle('Customer Requirements Form');

    return () => setTitle('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <ListHeader
        queryKey={QUERIES.CUSTOMER_OPTIONS}
        toolbar={
          <div className="card-toolbar">
            {/* begin::Group actions */}
            <ListToolbar>
              <IconButton
                color="inherit"
                onClick={() => setItemForUpdate(null)}
              >
                <Add color="inherit" />
              </IconButton>
            </ListToolbar>
            {/* end::Group actions */}
          </div>
        }
      />
      <ListTable
        columns={createCustomerChecklistColumns() as any[]}
        relatedTableId={customerOptionsTableId}
      />
      {itemForUpdate !== undefined && (
        <FormDialog
          icon={
            <SVGImage
              path="/media/icons/users.svg"
              className="svg-icon-1 text-black"
            />
          }
          onClose={() => setItemForUpdate(undefined)}
          title="Production Order"
        >
          <CustomerOptionsFormWrapper customer={customer?.id} />
        </FormDialog>
      )}
    </Card>
  );
};

export const CustomerOptionsListWrapper: FC<Props> = ({ customer }) => {
  return (
    <RequestProvider>
      <ResponseProvider
        callback={getCustomerRequirements}
        params={{ customer: customer?.id }}
        queryKey={QUERIES.CUSTOMER_OPTIONS}
      >
        <ListViewProvider>
          <ModalDialogProvider>
            <CustomerOptionsList customer={customer} />
          </ModalDialogProvider>
        </ListViewProvider>
      </ResponseProvider>
    </RequestProvider>
  );
};
