import { Delete } from "@mui/icons-material";
import { IconButton, Grid } from "@mui/material";
import { FC } from "react";
import { UploadFileProps } from "./UploadError";

type Props = Omit<UploadFileProps, "errors">;

export const FileHeader: FC<Props> = ({ file, onDelete }) => {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={11} md={10}>{file.name}</Grid>
      <Grid item xs={1} md={2}>
        <IconButton
          aria-label="delete"
          color="error"
          onClick={() => onDelete(file)}
          size="small"
        >
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  );
};