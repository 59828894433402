import { isUndefined } from '@ialopezg/commonjs';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';

import Icon from '../../../../../../core/components/Icon';
import CardSnippet from '../../../../../components/Cards/snippet';
import Uploader from '../../../../../components/inputs/uploader';
import FilePreviewIcon from '../../../../../components/inputs/uploader/FilePreviewIcon';
import List from '../../../../../components/list/List';
import { ID } from '../../../../../models';
import { useResponse } from '../../../../../providers';
import { getPurchaseOrderAttachments, uploadFiles } from '../../../api';

interface PurchaseOrderFormAttachmentsProps {
  purchaseOrderID: ID;
  onCloseAction?: () => void;
  onSubmitAction?: () => void;
}

export const PurchaseOrderFormAttachments: FC<
  PurchaseOrderFormAttachmentsProps
> = ({ purchaseOrderID }) => {
  console.log(purchaseOrderID)
  const { reload } = useResponse();
  const enabled = !isUndefined(purchaseOrderID);
  const { data: attachments, refetch } = useQuery(
    `purchase-order-${purchaseOrderID}-attachments`,
    async () => getPurchaseOrderAttachments(purchaseOrderID),
    { enabled }
  );

  const [checked, setChecked] = useState<number[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [action, setAction] = useState<'add' | 'delete' | undefined>(undefined);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const onUploadFilesAction = async (files: File[]) => {
    setIsSubmitting(true);

    try {
      await uploadFiles(purchaseOrderID, files);
      setAction(undefined);

      await refetch();
      await reload();
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CardSnippet title="Attachments">
      {!action && (
        <Box
          sx={{
            mb: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ToggleButtonGroup
            exclusive
            size="small"
            color="primary"
            value={action}
            onChange={(e, newValue) => setAction(newValue)}
          >
            <ToggleButton disabled={isSubmitting} value="add">
              <Icon color="blue" icon="mdi:plus" />
            </ToggleButton>
            <ToggleButton disabled={isSubmitting} value="delete">
              <Icon color="red" icon="mdi:trash-can" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      <List disablePadding>
        {action !== undefined ? (
          action === 'add' ? (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Uploader onUploadAction={onUploadFilesAction} />
              </Grid>

              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  fullWidth
                  color="error"
                  onClick={() => setAction(undefined)}
                  size="large"
                  startIcon={<Icon icon="mdi:close-circle" />}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>Delete Action</>
          )
        ) : (
          <>
            {!attachments?.length ? (
              <ListItem>
                <ListItemIcon>
                  <Icon icon="mdi:file-question-outline" />
                </ListItemIcon>
                <ListItemText primary="No files" />
              </ListItem>
            ) : (
              attachments.map((attachment, index) => {
                return (
                  <ListItem key={`file-${attachment?.id}`}>
                    <ListItemButton href={attachment?.url} target="_blank">
                      <ListItemIcon>
                        <FilePreviewIcon file={attachment} />
                      </ListItemIcon>
                      <ListItemText
                        id="checkbox-list-label-0"
                        primary={attachment.name}
                      />
                    </ListItemButton>

                    <ListItemSecondaryAction onClick={handleToggle(index)}>
                      <Checkbox
                        edge="end"
                        tabIndex={-1}
                        disableRipple
                        onChange={handleToggle(index)}
                        checked={checked.indexOf(index) !== -1}
                        inputProps={{
                          'aria-labelledby': 'checkbox-list-label-0',
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
            )}
          </>
        )}
      </List>
    </CardSnippet>
  );
};

export default PurchaseOrderFormAttachments;
