import { FC } from "react";
import { WithChildren } from "../../../core/components";

export const ListToolbar: FC<WithChildren> = ({ children }) => {
  return (
    <div className="btn-toolbar" role="toolbar" aria-label="...">
      {children}
    </div>
  );
};
