import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAuth } from "../../../modules/auth";

const TrophyImage = styled("img")(({ theme }) => ({
  right: 22,
  bottom: 0,
  width: 106,
  position: "absolute",
  [theme.breakpoints.down("sm")]: {
    width: 95
  }
}));

export const AwardWidget = () => {
  const { currentUser } = useAuth();
  const pendingTasks = 0;
  const rating = Number(1)
    .toLocaleString("default", { style: "percent" });

  return (
    <Card sx={{ position: "relative" }}>
      <CardContent>
        <Typography variant="h6">
          {"Welcome back, "}
          <Box component="span" sx={{ fontWeight: "bold" }}>
            {currentUser?.firstName}
          </Box>
          ! 🎉
        </Typography>
        <Typography variant="body2" sx={{ mb: 3.25 }}>
          A new day has been started
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 600, color: "primary.main" }}>
          {pendingTasks} pending tasks
        </Typography>
        <Typography variant="body2" sx={{ mb: 3.25 }}>
          {rating} of target 🤟🏻
        </Typography>

        <Button size="small" variant="contained" href="/">
          View Profile
        </Button>

        <TrophyImage src="/media/images/cards/trophy.png" alt="trophy" />
      </CardContent>
    </Card>
  );
};

export default AwardWidget;
