import { createContext, Dispatch, SetStateAction } from 'react';

export type ModalDialogContextModel = {
  title: string,
  setTitle: Dispatch<SetStateAction<string>>,
}

export const initialModalContextProps: ModalDialogContextModel = {
  title: '',
  setTitle: () => {},
}

export const ModalDialogContext = createContext<ModalDialogContextModel>(initialModalContextProps);