import DataGrid, { DataGridInstance, DataGridRow } from '@ialopezg/datagrid';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import UploadIcon from '@mui/icons-material/CloudUpload';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  Tooltip
} from '@mui/material';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { FieldValues } from 'react-hook-form';
import { Row } from 'react-table';

import Icon from '../../../../../core/components/Icon';
import { QUERIES } from '../../../../constants';
import { createDownloadLink, stringifyRequestQuery } from '../../../../helpers';
import {
  exportCustomersRequirements,
  getCustomers,
  importCustomersRequirements
} from '../api';
import {
  ListViewProvider,
  RequestProvider,
  ResponseProvider,
  useRequest,
  useResponse,
  useResponseData,
  useResponseLoading,
  useResponsePagination,
  useResponseReloading
} from '../../../../providers';
import { createCustomerColumns } from './list';
import CustomerFormWrapper, { ActionType } from './list/CustomerFormWrapper';
import { Customer } from '../../../../models';
import Pagination from '../../../../components/pagination';

const CustomerList = () => {
  const { state, updateState } = useRequest();
  const { reload } = useResponse();
  const data = useResponseData();
  const isLoading = useResponseLoading();
  const isReloading = useResponseReloading();
  const pagination = useResponsePagination();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  // ** State
  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [action, setAction] = useState<ActionType | undefined>(undefined);
  const [currentRow, setCurrentRow] = useState<
    DataGridRow<Customer> | undefined
  >(undefined);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onMenuItemAction = (
    e: MouseEvent,
    row: DataGridRow<Customer>,
    callback?: () => void,
    action: ActionType = 'preview'
  ) => {
    e.stopPropagation();
    e.preventDefault();

    setCurrentRow(row);
    setAction(action);
    // setIsExporting(action === 'download');

    callback && callback();
  };

  const renderRowActionMenuItems = (
    row: Row<Customer>,
    _table: DataGridInstance<Customer>,
    onCloseMenu: () => void
  ) => {
    const items: ReactNode[] = [
      <MenuItem
        key='row-action-view'
        onClick={(e: MouseEvent<HTMLLIElement>) =>
          onMenuItemAction(e, row as DataGridRow<Customer>, onCloseMenu)
        }
      >
        <Icon icon='mdi:eye-outline' /> Preview
      </MenuItem>
    ];

    return items;
  };

  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return;
    }

    updateState({ page, pageSize: pagination.take || 10 });
  };

  useEffect(() => {
    updatePage(pagination.page + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page]);

  useEffect(() => {
    if (filterActive) {
      updateState({ ...state, filter: { active: filterActive } });
    } else {
      updateState({ ...state, filter: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterActive]);

  const onDownload = async (_table: DataGridInstance<Customer>) => {
    setIsExporting(true);

    try {
      const query = stringifyRequestQuery(state);
      const { filename, content } = await exportCustomersRequirements(query);

      createDownloadLink(filename, content);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsExporting(false);
    }
  };

  const onSubmit = async (values?: FieldValues): Promise<void> => {
    if (action === 'import') {
      await uploadData(values as FieldValues);
    }

    reload();
  };

  const uploadData = async (values: FieldValues): Promise<void> => {
    setIsUploading(true);

    try {
      await importCustomersRequirements(values);

      await reload();
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}></Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title='Search Filters'
            sx={{ pb: 4, '& .MuiCardHeader-title': { letterSpacing: '.15px' } }}
          />

          <CardContent>
            <Grid container spacing={6}>
              <Grid item sm={4} xs={12}></Grid>
              <Grid item sm={4} xs={12}></Grid>
              <Grid item sm={4} xs={12}></Grid>
            </Grid>
          </CardContent>

          <Divider />

          <DataGrid
            // @ts-ignore
            columns={createCustomerColumns()}
            data={data}
            enableCellCopy
            enableRowActions
            // enableSelection
            isLoading={isLoading || isExporting || isUploading}
            isFetching={isReloading}
            onGlobalFilterChange={(e) => {
              updateState({
                ...state,
                search: e.target.value ? e.target.value : undefined
              });
            }}
            rowActionMenuItems={renderRowActionMenuItems}
            toolbarCustomActions={(table: DataGridInstance<Customer>) => (
              <Box display='flex' flexDirection='row' gap='5'>
                <Tooltip arrow title='Upload'>
                  <IconButton
                    aria-label='Import'
                    color='primary'
                    onClick={() => setAction('import')}
                  >
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title='Download'>
                  <IconButton
                    aria-label='Doanload'
                    color='primary'
                    onClick={() => onDownload(table)}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title='Filter only active customers'>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={filterActive}
                          onChange={() => setFilterActive(!filterActive)}
                        />
                      }
                      label={'Filter only active'}
                    />
                  </FormGroup>
                </Tooltip>
              </Box>
            )}
          />

          <Grid item xs={12}>
            <Pagination />
          </Grid>
        </Card>
      </Grid>

      <CustomerFormWrapper
        action={action}
        onCloseAction={() => setAction(undefined)}
        onSubmitAction={onSubmit}
        open={!!action}
        row={currentRow as DataGridRow<Customer>}
      />
    </Grid>
  );
};

export const CustomersListWrapper = () => (
  <RequestProvider>
    <ResponseProvider callback={getCustomers} queryKey={QUERIES.CUSTOMERS}>
      <ListViewProvider>
        <CustomerList />
      </ListViewProvider>
    </ResponseProvider>
  </RequestProvider>
);

export default CustomersListWrapper;
