import { FC } from 'react';

import { ID } from '../../models';
import { SearchWidget } from '../list';
import { ListHeaderDefaultActions } from '../list/ListHeaderDefaultActions';
import { ListHeaderSelectionActions } from '../list/ListHeaderSelectionActions';

type Props = {
  selected: Array<ID>;
};

export const CardTitle: FC<Props> = ({ selected }) => {
  return (
    <div className="card-header border-0 pt-6">
      <div className="card-title">
        <SearchWidget />
      </div>
      <div className="card-toolbar">
        {selected.length > 0 ? <ListHeaderSelectionActions selected={selected} /> : <ListHeaderDefaultActions addAction={null} />}
      </div>
    </div>
  );
};
