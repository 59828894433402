/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';

import { PageLink } from './interfaces';
import { usePage } from './providers';
import { WithChildren } from '../WithChildren';

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
}

export const PageTitle: FC<Props & WithChildren> = ({children, description, breadcrumbs}) => {
  const { setPageTitle, setPageDescription, setPageBreadcrumbs } = usePage()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }

    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }

    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }

    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return <></>
}