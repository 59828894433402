import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { ColumnInstance } from 'react-table';

import { initialState } from '../../../models';
import { useRequest } from '../../../providers';

type Props = {
  className?: string;
  title?: string;
  column: ColumnInstance<any>;
}

export const DefaultHeader: FC<Props> = ({ className, title, column }) => {
  const id = column.id;
  const { state, updateState } = useRequest();
  const isSelectedForSorting = useMemo(() => {
    return state.sortFieldName && state.sortDirection === id;
  }, [state, id]);
  const direction: 'ASC' | 'DESC' | undefined = useMemo(() => state.sortDirection, [state]);

  const sortColumn = () => {
    if (id === 'actions' || id === 'selection') {
      return;
    }

    if (!isSelectedForSorting) {
      updateState({ sortFieldName: id, sortDirection: 'ASC', ...initialState });

      return;
    }

    if (isSelectedForSorting && direction !== undefined) {
      if (direction === 'ASC') {
        updateState({ sortFieldName: id, sortDirection: 'DESC', ...initialState });

        return;
      }

      updateState({ sortFieldName: undefined, sortDirection: undefined, ...initialState });
    }
  };

  return (
    <th
      {...column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && direction !== undefined && `table-sort-${direction}`,
      )}
      style={{ cursor: 'pointer' }}
      onClick={sortColumn}
    >
      {title}
    </th>
  );
};
