import { Direction } from "@mui/material";

import AppBar from "../core/layouts/types/AppBar";
import Footer from "../core/layouts/types/Footer";
import HorizontalMenuToggle from "../core/layouts/types/HorizontalMenuToggle";
import Mode from "../core/layouts/types/Mode";
import Skin from "../core/layouts/types/Skin";
import ContentWidth from "../core/layouts/types/ContentWidth";
import VerticalNavToggle from "../core/layouts/types/VerticalNavToggle";

interface ThemeConfig {
  afterVerticalNavMenuContentPosition: "fixed" | "static";
  appBar: AppBar;
  appBarBlur: boolean;
  beforeVerticalNavMenuContentPosition: "fixed" | "static";
  collapsedNavigationSize: number;
  contentWidth: ContentWidth;
  disableCustomizer: boolean;
  disableRipple: boolean;
  direction: Direction;
  footer: Footer,
  horizontalMenuAnimation: boolean;
  horizontalMenuToggle: HorizontalMenuToggle;
  layout: "vertical" | "horizontal";
  menuTextTruncate: boolean;
  mode: Mode;
  navCollapsed: boolean;
  navHidden: boolean;
  navigationSize: number;
  navSubItemIcon: string;
  responsiveFontSizes: boolean;
  skin: Skin;
  routingLoader: boolean;
  templateName: string;
  toastPosition: "top-left" | "top-center" | "top-right" | "bottom-left" | "bottom-center" | "bottom-right";
  verticalNavToggleType: VerticalNavToggle;
}

export const theme: ThemeConfig = {
  templateName: "AXNY Group",
  layout: "vertical",
  mode: "light" as Mode,
  direction: "ltr",
  skin: "default",
  contentWidth: "boxed",
  footer: "static",

  routingLoader: true,

  navHidden: false,
  menuTextTruncate: true,
  navSubItemIcon: "mdi:circle",
  verticalNavToggleType: "accordion",
  navCollapsed: false,
  navigationSize: 260,
  collapsedNavigationSize: 68,
  afterVerticalNavMenuContentPosition: "fixed",
  beforeVerticalNavMenuContentPosition: "fixed",
  horizontalMenuToggle: "hover",
  horizontalMenuAnimation: true,

  appBar: "fixed",
  appBarBlur: true,

  responsiveFontSizes: true,
  disableRipple: false,
  disableCustomizer: false,
  toastPosition: "top-right"
};

export default theme;