import { TableBody, TableCell, TableRow } from '@mui/material';
import { useDataTableRows } from '../../DataTableProvider';
import { DataRow } from '../../Prototypes';

export const Body = () => {
  const rows = useDataTableRows();

  const buildRow = (row: DataRow) => {
    return (
      <TableRow key={`row-${row.id}`}>
        {Object.keys(row).map((key, index) => (
          <TableCell
            key={`cell-${key}-${index}`}
            align={index === 0 ? 'right' : 'left'}
            component={index === 0 ? 'th' : undefined}
            scope={index === 0 ? 'row' : undefined}
          >
            <div>{row[key]}</div>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <TableBody>
      {rows.map((row) => buildRow(row))}
    </TableBody>
  );
};