import axios from "./axios";

const ACCOUNT_URL = 'account';

export const resendConfirmationLink = async (email: string) => {
  const response = await axios
    .post(
      `${ACCOUNT_URL}/email/resend/link`,
      { email },
      { withCredentials: true },
    );

  if (!response || !response?.data) {
    return undefined;
  }

  return response?.data;
};

export const emailVerification = async (token: string) => {
  const response = await axios.post(`${ACCOUNT_URL}/email/verify`, { token });

  console.log(response);
};
