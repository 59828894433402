export const QUERIES = {
  ASSET: 'asset',
  CUSTOMER_OPTIONS: 'customer-options',
  CUSTOMERS: 'customers',
  DIVISIONS: 'divisions',
  DIVISIONS_ON_SHIPPING_ORDERS: 'divisions_on_shipping_orders',
  ORDER_LINE: 'order-line',

  ORDERS: 'orders',
  SINGLE_ORDER: 'single-order',

  PICK_TICKETS: 'pick-tickets',
  PURCHASE_ORDERS: 'purchase-orders',
  SALES_ORDERS: 'sales-orders',
  SHIPPING_ORDERS: 'shipping-orders',
  PRODUCTION_ORDERS: 'production-orders',
  ROUTING_ORDERS: 'shipping-orders',
  USERS: 'users',
  WAREHOUSE: 'warehouses',
  WAREHOUSE_ON_ORDERS: 'warehouse_on_orders',
  WAREHOUSE_ON_SHIPPING_ORDERS: 'warehouses_on_shipping_orders',
  WAREHOUSE_ON_PRODUCTION: 'warehouses_on_production',
};
