import { isUndefined } from '@ialopezg/commonjs';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';

import { WithChildren } from '../../core';
import { stringifyRequestQuery } from '../helpers';
import { initialResponse, initialState } from '../models';
import { useRequest } from './RequestProvider';

const ResponseContext = createContext<any>(initialResponse);

type Props = {
  callback: (...args: any) => void;
  params?: { [key: string]: any };
  queryKey: string;
};

export const ResponseProvider: FC<Props & WithChildren> = ({
  children,
  callback,
  params,
  queryKey,
}) => {
  const { state } = useRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    const updateQuery = (query: string) => {
      if (query !== updatedQuery) {
        setQuery(updatedQuery);
      }
    };

    updateQuery(query);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedQuery]);

  const {
    isFetching: isLoading,
    isRefetching: isReloading,
    refetch: reload,
    data: response,
  } = useQuery(
    `${queryKey}-${query}`,
    () => {
      if (!isUndefined(params)) {
        return callback(query, params);
      }

      return callback(query);
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <ResponseContext.Provider
      value={{ isLoading, isReloading, query, reload, response }}
    >
      {children}
    </ResponseContext.Provider>
  );
};

export const useResponse = () => useContext(ResponseContext);

export const useResponseData = () => {
  const { response } = useResponse();

  return response && response?.data ? response?.data : [];
};

export const useResponsePagination = () => {
  const defaultPagination = {
    pages: [],
    ...initialState,
  };
  const { response } = useResponse();
  if (!response || !response?.payload || !response.payload?.pagination) {
    return defaultPagination;
  }

  return response.payload.pagination;
};

export const useResponseLoading = () => {
  const { isLoading } = useResponse();

  return isLoading;
};

export const useResponseReloading = () => {
  const { isReloading } = useResponse();

  return isReloading;
};
