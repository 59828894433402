/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { SVGImage } from '../image';

type Props = {
  className?: string
  color?: string
  icon?: string
  iconColor?: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string;
  url?: string;
}

export const StatisticsWidget: React.FC<Props> = ({
                                                    className,
                                                    color = 'primary',
                                                    icon,
                                                    iconColor = 'white',
                                                    title,
                                                    titleColor = 'white',
                                                    description,
                                                    descriptionColor = 'white',
                                                    url = '#',
                                                  }) => {
  return (
    <a href={url} className={`card bg-${color} hoverable ${className}`}>
      <div className="card-body">
        <div className="d-flex flex-row">
          {icon && <SVGImage path={icon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`}/>}
          <div className={`text-${titleColor} fw-bold fs-2 p-2`}>&nbsp;{title}</div>
        </div>

        <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
      </div>
    </a>
  );
};

