import { isNotEmpty } from '@ialopezg/commonjs';
import { FC } from 'react';
import { useQuery } from 'react-query';

import { QUERIES } from '../../../../../constants';
import { useListView } from '../../../../../providers';
import { getProductionOrderByID } from '../../../api';
import { RoutingDetailsForm } from '../../../routing';
import { ProductionOrderForm } from './ProductionOrderForm';

type Props = {
  form?: string,
};

export const ProductionOrderFormWrapper: FC<Props> = ({ form }) => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const enabledQuery = isNotEmpty(itemForUpdate);

  const { isLoading, data: order, error } = useQuery(
    [`${QUERIES.PRODUCTION_ORDERS}-id-${itemForUpdate}`, { id: itemForUpdate }],
    getProductionOrderByID,
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (_: any) => {
        setItemForUpdate(undefined);
      },
    },
  );

  if (!isLoading && !error && order) {
    if (form === 'RoutingDetailsForm') {
      return (<RoutingDetailsForm
        purchaseOrder={order}
        isLoading={isLoading}
      />);
    }

    return (<ProductionOrderForm
      order={order}
      showShipDate={form === 'ShipDateForm'}
      showNotes={form === 'NotesForm'}
      showMarkOrderAsReady={form === 'OrderReadyForm'}
      isLoading={isLoading}
    />);
  }

  return null;
};