import React from 'react'

const OtherOrderList = () => {
  return (
    <>Other Order List</>
  );
};

export function OtherOrderListWrapper() {
  return (
    <OtherOrderList />
  )
}
