import { isNotEmpty } from '@ialopezg/commonjs';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { CircularSpinner, InputSpinner } from '../../../../../../components';
import { QUERIES } from '../../../../../../constants';
import { CustomerRequirement, ID, initialCustomerOption } from '../../../../../../models';
import { useListView, useResponse } from '../../../../../../providers';
import { getDivisions } from '../../../../divisions';
import { createCustomerRequirement, updateCustomerRequirement } from '../../../api';

type Props = {
  isLoading?: boolean
  customer?: ID;
  option?: CustomerRequirement
}

const editUserSchema = Yup.object().shape({
  divisionId: Yup.string()
    .required('Division is required'),
});

export const CustomerOptionForm: FC<Props> = ({ customer, option, isLoading }) => {
  const { setItemForUpdate } = useListView();
  const { reload } = useResponse();
  const { data } = useQuery(QUERIES.DIVISIONS, () => getDivisions('page=-1'));
  const { data: divisions } = data || {};

  const [optionForEdit] = useState<CustomerRequirement>({
    ...option,
    customerId: customer || option?.customerId || initialCustomerOption.customerId,
    divisionId: option?.divisionId || initialCustomerOption.divisionId,
    routing: option?.routing || initialCustomerOption.routing,
    fineLineTickets: option?.fineLineTickets || initialCustomerOption.fineLineTickets,
    fineLineTrackingNo: option?.fineLineTrackingNo || initialCustomerOption.fineLineTrackingNo,
    printTickets: option?.printTickets || initialCustomerOption.printTickets,
    palletLabels: option?.palletLabels || initialCustomerOption.palletLabels,
    uccLabels: option?.uccLabels || initialCustomerOption.uccLabels,
    individualSKUCartons: option?.individualSKUCartons || initialCustomerOption.individualSKUCartons,
    daysBeforeStartDate: option?.daysBeforeStartDate || initialCustomerOption.daysBeforeStartDate,
    daysBeforeCancelDate: option?.daysBeforeCancelDate || initialCustomerOption.daysBeforeCancelDate,
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      reload();
    }
    setItemForUpdate(undefined);
  };

  const formik = useFormik({
    initialValues: optionForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (isNotEmpty(values.uuid)) {
          await updateCustomerRequirement(option?.id, values);
        } else {
          await createCustomerRequirement(customer, values);
        }
        reload();
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        cancel(true);
      }
    },
  });

  return (
    <>
      <form id="customer_options_form" className="form" onSubmit={formik.handleSubmit} noValidate>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="customer_options_form"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#customer_options_form_header"
          data-kt-scroll-wrappers="#customer_options_form"
          data-kt-scroll-offset="300px"
        >
          <div className="row mb-2">
            <label className="col-lg-7 col-form-label fw-bold fs-6 required">Division</label>

            <div className="col-lg-5 d-flex align-items-center">
              <select
                {...formik.getFieldProps('divisionId')}
                className="form-select form-select-lg form-select-solid"
                name="divisionId"
              >
                <option value="">Select a division...</option>
                <option value="ALL">ALL - ALL DIVISIONS</option>
                {(divisions || []).map(({ id, name }) => (
                  <option key={`option-${id}`} value={id?.toString()}>{id} - {name}</option>
                ))}
              </select>
              {formik.touched.divisionId && formik.errors.divisionId && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.divisionId}</div>
                </div>
              )}
            </div>
          </div>

          <div className="row mb-2">
            <label className="col-lg-7 col-form-label fw-bold fs-6">Allow routing</label>

            <div className="col-lg-5 d-flex align-items-center">
              <div className="form-check form-switch form-check-custom form-check-outline">
                <input
                  {...formik.getFieldProps('routing')}
                  type="checkbox"
                  className="form-check-input w-45px h-30px"
                  name="routing"
                  checked={formik.values.routing}
                />
                <label className="form-check-label"></label>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <label className="col-lg-7 col-form-label fw-bold fs-6">Allow FineLine tickets</label>

            <div className="col-lg-5 d-flex align-items-center">
              <div className="form-check form-switch form-check-custom form-check-outline">
                <input
                  {...formik.getFieldProps('fineLineTickets')}
                  type="checkbox"
                  className="form-check-input w-45px h-30px"
                  name="fineLineTickets"
                  checked={formik.values.fineLineTickets}
                />
                <label className="form-check-label"></label>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <label className="col-lg-7 col-form-label fw-bold fs-6">Allow FineLine tracking number</label>

            <div className="col-lg-5 d-flex align-items-center">
              <div className="form-check form-switch form-check-custom form-check-outline">
                <input
                  {...formik.getFieldProps('fineLineTrackingNo')}
                  type="checkbox"
                  className="form-check-input w-45px h-30px"
                  name="fineLineTrackingNo"
                  checked={formik.values.fineLineTrackingNo}
                />
                <label className="form-check-label"></label>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <label className="col-lg-7 col-form-label fw-bold fs-6">Allow print tickets</label>

            <div className="col-lg-5 d-flex align-items-center">
              <div className="form-check form-switch form-check-custom form-check-outline">
                <input
                  {...formik.getFieldProps('printTickets')}
                  type="checkbox"
                  className="form-check-input w-45px h-30px"
                  name="printTickets"
                  checked={formik.values.printTickets}
                />
                <label className="form-check-label"></label>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <label className="col-lg-7 col-form-label fw-bold fs-6">Use UCC labels</label>

            <div className="col-lg-5 d-flex align-items-center">
              <div className="form-check form-switch form-check-custom form-check-outline">
                <input
                  {...formik.getFieldProps('uccLabels')}
                  type="checkbox"
                  className="form-check-input w-45px h-30px"
                  name="uccLabels"
                  checked={formik.values.uccLabels}
                />
                <label className="form-check-label"></label>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <label className="col-lg-7 col-form-label fw-bold fs-6">Pallet labels</label>

            <div className="col-lg-5 d-flex align-items-center">
              <div className="form-check form-switch form-check-custom form-check-outline">
                <input
                  {...formik.getFieldProps('palletLabels')}
                  type="checkbox"
                  className="form-check-input w-45px h-30px"
                  name="palletLabels"
                  checked={formik.values.palletLabels}
                />
                <label className="form-check-label"></label>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <label className="col-lg-7 col-form-label fw-bold fs-6">Use individual SKU cartons</label>

            <div className="col-lg-5 d-flex align-items-center">
              <div className="form-check form-switch form-check-custom form-check-outline">
                <input
                  {...formik.getFieldProps('individualSKUCartons')}
                  type="checkbox"
                  className="form-check-input w-45px h-30px check"
                  name="individualSKUCartons"
                  checked={formik.values.individualSKUCartons}
                />
                <label className="form-check-label"></label>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <label className="col-lg-7 col-form-label fw-bold fs-6">Days before start shipping</label>

            <div className="col-lg-5 d-flex align-items-center">
              <InputSpinner
                {...formik.getFieldProps('daysBeforeStartDate')}
                type="decimal"
                onChange={(value: number) => formik.values.daysBeforeStartDate = value}
                step={1}
                value={formik.values.daysBeforeStartDate!}
              />
            </div>
          </div>

          <div className="row mb-2">
            <label className="col-lg-7 col-form-label fw-bold fs-6">Days before cancel shipping</label>

            <div className="col-lg-5 d-flex align-items-center">
              <InputSpinner
                {...formik.getFieldProps('daysBeforeCancelDate')}
                type="decimal"
                onChange={(value: number) => formik.values.daysBeforeCancelDate = value}
                step={1}
                value={formik.values.daysBeforeCancelDate!}
              />
            </div>
          </div>
        </div>

        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            disabled={formik.isSubmitting || isLoading}
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className="indicator-label">Submit</span>
            {(formik.isSubmitting || isLoading) && (
              <span className="indicator-progress">
                Please wait...{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isLoading) && <CircularSpinner />}
    </>
  );
};
