import { FC } from 'react';
import { SVGImage } from '../../../components';

type Props = {
  status?: string,
};

export const OrderStatus: FC<Props> = ({ status }) => {
  let imageName;
  let className;
  switch (status) {
    case 'READY':
      imageName = 'check';
      className = 'success';
      break;
    case 'WAREHOUSE':
    case 'ON HAND':
      imageName = 'hand-holding-box';
      className = 'warning'
      break;
    case 'B-CNCL':
    case 'CNCL':
      imageName = 'ban';
      className = 'danger';
      break;
    case 'PART':
      imageName = 'puzzle';
      className = 'warning';
      break;
    case 'SHIP':
      imageName = 'ship';
      className = 'success';
      break;
    default:
      imageName = 'box-open';
      className = 'primary';
      break
  }

  return (
    <SVGImage path={`/media/icons/${imageName}.svg`} className={`svg-icon-2 svg-icon-${className}`} />
  );
};
