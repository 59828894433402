import { Grid, Typography } from "@mui/material";
import { ReactNode, FC } from "react";

interface ContentWrapperProps {
  children: ReactNode;
  title?: string;
}

export const ContentWrapper: FC<ContentWrapperProps> = ({ children, title }) => {
  return (
    <Grid item xs={12}>
      {title && <Typography variant="h6">{title}</Typography>}

      <Grid container spacing={5} sx={{ mt: 5 }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ContentWrapper;
