import { Box, Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";

import Icon from "../../../../core/components/Icon";
import Avatar from "../../../../core/components/Avatar/Avatar";
import Chip from "../../../../core/components/Chip/Chip";
import { VerticalStatsCardProps } from "./VerticalStatsCardProps";

export const VerticalStatsCard: FC<VerticalStatsCardProps> = (
  {
    chipText,
    color,
    icon,
    stats,
    title,
    trend = "positive",
    trendNumber
  }
) => {
  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{
          mb: 6,
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between"
        }}>
          <Avatar skin="light" variant="rounded" color={color}>
            {icon}
          </Avatar>

          <Box sx={{
            display: "flex",
            alignItems: "center",
            color: trend === "positive" ? "success.main" : "error.main"
          }}>
            <Typography
              sx={{
                color: trend === "positive" ? "success.main" : "error.main"
              }}
              variant="subtitle2"
            >
              {trendNumber}
            </Typography>
            <Icon
              icon={trend === "positive" ? "mdi:chevron-up" : "mdi:chevron-down"}
              fontSize="1.25rem"
            />
          </Box>
        </Box>

        <Typography variant="h6" sx={{ mb: 1 }}>
          {stats}
        </Typography>

        <Typography variant='body2' sx={{ mb: 5 }}>
          {title}
        </Typography>

        <Chip
          skin='light'
          size='small'
          label={chipText}
          color='secondary'
          sx={{ height: 20, fontWeight: 500, fontSize: '0.75rem', alignSelf: 'flex-start', color: 'text.secondary' }}
        />
      </CardContent>
    </Card>
  );
};

export default VerticalStatsCard;
