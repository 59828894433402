import { FC } from 'react';

import { SVGImage } from '../image';
import { ListFilterAction } from './ListFilterAction';

type Props = {
  addAction: any;
};

export const ListHeaderDefaultActions: FC<Props> = ({ addAction }) => (
  <div className="d-flex justify-content-end">
    <ListFilterAction />

    {/* begin::Export */}
    <button type="button" className="btn btn-light-primary me-3">
      <SVGImage path="/media/icons/duotune/arrows/arr078.svg" className="svg-icon-2"/>
      Export
    </button>
    {/* end::Export */}

    {/* begin::Add user */}
    <button type="button" className="btn btn-primary" onClick={addAction}>
      <SVGImage path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2"/>
      Add
    </button>
    {/* end::Add user */}
  </div>
);
