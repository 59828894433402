import { Dispatch, SetStateAction } from 'react';

import { ID } from './id';

export type ListViewContextProps = {
  selected: Array<ID>;
  onSelect: (id: ID) => void;
  onSelectAll: () => void;
  clearSelection: () => void;
  // null => Creation mode
  // number | string => Edit mode
  // undefined => Close creation or edit modal
  itemForUpdate?: ID;
  setItemForUpdate: Dispatch<SetStateAction<ID>>;
  isAllSelected: boolean;
  disabled: boolean;
};

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: (_id: ID) => {},
  onSelectAll: () => {},
  clearSelection: () => {},
  setItemForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
};
