/* eslint-disable jsx-a11y/anchor-is-valid */
import { isString } from '@ialopezg/commonjs';
import { FC, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { MenuComponent } from '../../../../theme/assets/ts/components';
import { QUERIES } from '../../../constants';
import { useListView, useResponse } from '../../../providers';
import { SVGImage } from '../../image';

type Props = {
  id: string,
  type?: 'horizontal' | 'vertical',
  enableApprove?: boolean,
  enableDelete?: boolean,
  enableEdit?: boolean,
  enablePrint?: boolean,
  enableView?: boolean,
  enableExport?: boolean,
  approveAction?: ((id?: string) => void | string) | string;
  deleteAction?: (id: string | number) => Promise<number>,
  editAction?: ((id: string) => void | string) | string;
  exportAction?: (id: string) => void;
  printAction?: (id: string) => void;
  viewAction?: ((id: string) => void | string) | string;
}

export const ActionsCell: FC<Props> = (
  {
    id,
    type = 'vertical',
    enableApprove = false,
    approveAction = (id) => id,
    enableDelete = false,
    deleteAction = (id) => id,
    enableEdit = false,
    editAction = (id) => id,
    enableExport = false,
    exportAction = (id) => id,
    enablePrint = false,
    printAction = (id) => id,
    enableView = false,
    viewAction = (id) => id
  }
) => {
  const { query, reload } = useResponse();
  const queryClient = useQueryClient();
  const { setItemForUpdate } = useListView();
  let error: string = '';

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const approvalActionHandler = async () => {
    setItemForUpdate(id);

    if (approveAction) {
      (approveAction as Function)(id);
    }
  };

  const deleteItem = useMutation(async () => deleteAction(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([`${QUERIES.USERS}-${query}`]);
      reload();
    }
  });

  return (type === 'vertical' ?
    (<>
      {error.length && <>{error}</>}
      {!error.length && <></>}
      <a
        href="#"
        className="btn btn-light btn-active-light-primary btn-sm"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        Actions
        <SVGImage path="/media/icons/arrows/chevron-down.svg" className="svg-icon-5 m-0" />
      </a>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
        data-kt-menu="true"
      >
        {enableEdit && (
          <div className="menu-item px-3">
            {isString(editAction)
              ? (
                <Link
                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  to={String(editAction)}
                >Edit</Link>
              )
              : (
                <button
                  onClick={(_event) => (editAction as CallableFunction)(id)}
                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                ><SVGImage path="/media/icons/pen.svg" className="svg-icon-3" /></button>
              )
            }
          </div>
        )}

        <div className="menu-item px-3">
          <a
            className="menu-link px-3"
            data-kt-users-table-filter="delete_row"
            onClick={async () => await deleteItem.mutateAsync()}
          >Delete</a>
        </div>
      </div>
    </>) : (
      <div className="d-flex justify-content-end flex-shrink-0">
        {enableApprove && (
          isString(approveAction)
            ? (
              <Link
                to={String(approveAction)}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              >
                <SVGImage path="/media/icons/check.svg" className="svg-icon-3" />
              </Link>
            )
            : (
              <button
                onClick={approvalActionHandler}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              >
                <SVGImage path="/media/icons/check.svg" className="svg-icon-3" />
              </button>
            )
        )}
        {enableEdit && (
          <button
            onClick={(_event) => setItemForUpdate(id)}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          >
            <SVGImage path="/media/icons/pen.svg" className="svg-icon-3" />
          </button>
        )}
        {enableView && (
          isString(viewAction)
            ? (
              <Link
                to={String(viewAction)}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              >
                <SVGImage path="/media/icons/eye.svg" className="svg-icon-3" />
              </Link>
            ) : (
              <button
                onClick={(_event) => setItemForUpdate(id)}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              >
                <SVGImage path="/media/icons/eye.svg" className="svg-icon-3" />
              </button>
            )
        )}
        {enableExport && (
          <button
            onClick={(_event) => {
              return exportAction(id);
            }}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          >
            <SVGImage path="/media/icons/file-export.svg" className="svg-icon-3" />
          </button>
        )}
        {enablePrint && (
          <button
            onClick={(_event) => {
              return printAction(id);
            }}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          >
            <SVGImage path="/media/icons/print.svg" className="svg-icon-3" />
          </button>
        )}
        {enableDelete && (
          <button
            onClick={async () => await deleteItem.mutateAsync()}
            className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
          >
            <SVGImage path="/media/icons/trash.svg" className="svg-icon-3" />
          </button>
        )}
      </div>
    )
  );
};
