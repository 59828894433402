import { isNumber } from '@ialopezg/commonjs';
import clsx from 'clsx';
import { FC, useMemo, useState } from 'react';
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from 'react-data-table-component';
import { Link } from 'react-router-dom';

import {
  ActionsCell,
  CardBody,
  CircularSpinner,
  DateTimeCell,
  DownloadButton,
  FormDialog,
  NumberCell,
  SVGImage,
  TileCell,
  useModalDialog,
} from '../../../../components';
import { Pagination } from '../../../../components/pagination';
import { createDownloadLink } from '../../../../helpers';
import { ID, Order, Orders } from '../../../../models';
import { useListView, useResponseData } from '../../../../providers';
import { download3PlReport } from '../../../PickTicket';
import { OrderOptionsCell } from '../../components';
import { PurchaseOrder } from '../../models';
import { OrderStatus } from '../../order/OrderStatus';
import { ProductionOrderFormWrapper } from './Forms';

type ExpandableRowProps = {
  onShowNotesActionClick?: Function;
};

const ExpandableRow: FC<
  ExpandableRowProps & ExpanderComponentProps<PurchaseOrder>
> = ({ data }) => {
  const [isExporting, setIsExporting] = useState(false);
  const orders = data.productionOrders as Orders;

  const onDownload = async (id: ID, downloadFileName?: string) => {
    setIsExporting(true);

    try {
      const { filename, content } = await download3PlReport(id);

      createDownloadLink(downloadFileName || filename, content);
    } catch (e) {
      console.error(e);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <>
      <DataTable
        columns={[
          ...ProductionOrderColumns,
          {
            name: 'ACTIONS',
            selector: (row) => String(row.id),
            cell: (row) => {
              const pickTicket = row?.pickTicket;

              return (
                <div className="d-flex justify-content-end flex-shrink-0">
                  <ActionsCell
                    id={String(row.id)}
                    type="horizontal"
                    enableView={true}
                    viewAction={`/orders/${row.id}`}
                  />

                  {pickTicket && (
                    <DownloadButton
                      fileName="single-order-report"
                      onDownloadAction={(fileName) =>
                        onDownload(pickTicket?.id, fileName)
                      }
                    />
                  )}
                </div>
              );
            },
          } as TableColumn<Order>,
        ]}
        data={orders}
        className="ms-15"
      />

      {isExporting && <CircularSpinner />}
    </>
  );
};

type Props = {
  listTitle?: string;
};

export const ProductionOrderTable: FC<Props> = ({ listTitle }) => {
  const data = useResponseData();
  const orders = useMemo(() => data, [data]);
  const { itemForUpdate, setItemForUpdate } = useListView();
  const { setTitle } = useModalDialog();
  const [formName, setFormName] = useState('');

  const showPurchaseOrderNotes = (id: ID) => {
    setFormName('NotesForm');
    setItemForUpdate(id);
    setTitle(`Purchase Order ${id}`);
  };

  const markOrderReady = (id: ID) => {
    setFormName('OrderReadyForm');
    setItemForUpdate(id);
    setTitle(`Purchase Order ${id}`);
  };

  const setRoutingDetails = (id: ID) => {
    setFormName('RoutingDetailsForm');
    setItemForUpdate(id);
    setTitle(`Purchase Order ${id}`);
  };

  const onUpdateShipDateClickAction = (order: PurchaseOrder) => {
    setFormName('ShipDateForm');
    setItemForUpdate(order.id);
    setTitle(`Purchase Order ${order.id}`);
  };

  const columns: TableColumn<PurchaseOrder>[] = useMemo(
    () => [
      {
        name: 'PO',
        sortable: true,
        grow: 2,
        selector: (row) => String(row?.id),
        cell: (row) => {
          return (
            <TileCell
              title={
                <>
                  <OrderStatus
                    status={
                      row.status === 'OPEN' &&
                      (row.stage === 'READY' || row?.stage === 'WAREHOUSE')
                        ? row.stage
                        : row.status
                    }
                  />{' '}
                  {row.id}
                </>
              }
              url={`/orders/purchases/${row.id}`}
            >
              <OrderOptionsCell objectValue={row} />
            </TileCell>
          );
        },
      },
      {
        name: 'CUSTOMER',
        sortable: true,
        grow: 1,
        compact: true,
        selector: ({ customer }) => String(customer),
        cell: ({ customer }) => {
          return (
            <Link
              to={`/third-parties/customers/${customer}`}
              className="text-center min-w-75px"
            >
              {String(customer)}
            </Link>
          );
        },
      },
      {
        name: 'DIV',
        sortable: true,
        compact: true,
        center: true,
        id: 'division',
        cell: ({ division }) => {
          if (isNumber(division)) {
            return <span className="badge badge-white">{division}</span>;
          }

          return <Link to={`/divisions/${division}`}>{division}</Link>;
        },
      },
      {
        name: 'WH',
        sortable: true,
        grow: 1,
        compact: true,
        selector: ({ warehouse }) => String(warehouse),
        cell: ({ warehouse }) => {
          return (
            <Link
              to={`/third-parties/warehouses/${warehouse}`}
              className="text-center min-w-75px"
            >
              {String(warehouse)}
            </Link>
          );
        },
      },
      {
        name: 'A. DATE',
        sortable: true,
        compact: true,
        right: true,
        selector: ({ warehouseApprovalDate }) => String(warehouseApprovalDate),
        cell: ({ warehouseApprovalDate }) =>
          warehouseApprovalDate ? (
            <DateTimeCell value={warehouseApprovalDate} />
          ) : (
            ''
          ),
      },
      {
        name: 'S. DATE',
        sortable: true,
        compact: true,
        right: true,
        selector: (row) => String(row.startDate),
        cell: ({ startDate }) =>
          startDate ? <DateTimeCell value={startDate} /> : '',
      },
      {
        name: 'C. DATE',
        sortable: true,
        compact: true,
        right: true,
        selector: (row) => String(row.cancelDate),
        cell: ({ cancelDate }) =>
          cancelDate ? <DateTimeCell value={cancelDate} /> : '',
      },
      {
        name: 'SH. DATE',
        button: true,
        ignoreRowClick: true,
        compact: true,
        right: true,
        cell: (row) => (
          <div className="d-flex align-items-center">
            <div className="mr-auto pr-2">
              {row.shipDate ? <DateTimeCell value={row.shipDate} /> : <></>}
            </div>
            <button
              className={clsx(
                'btn btn-icon btn-active-color-primary btn-sm me-1',
                {
                  'btn-light-danger': !row.shipDate,
                  'btn-light-success': row.shipDate,
                }
              )}
              title="Edit ship date"
              onClick={() => onUpdateShipDateClickAction(row)}
            >
              <SVGImage
                path="/media/icons/calendar-pen.svg"
                className="svg-icon"
              />
            </button>
          </div>
        ),
      },
      {
        name: 'CONTROLS',
        compact: true,
        right: true,
        cell: (row) => (
          <NumberCell value={Number(row?.productionOrders?.length)} />
        ),
      },
      {
        name: 'T. QTY',
        selector: (row) => String(row.orderQuantity),
        sortable: true,
        compact: true,
        right: true,
        cell: ({ orderQuantity }) => (
          <NumberCell value={Number(orderQuantity)} />
        ),
      },
      {
        name: 'O. QTY',
        selector: (row) => String(row.openQuantity),
        sortable: true,
        compact: true,
        right: true,
        cell: ({ openQuantity }) => <NumberCell value={Number(openQuantity)} />,
      },
      {
        name: 'P. QTY',
        selector: (row) => String(row.pickedQuantity),
        sortable: true,
        compact: true,
        right: true,
        cell: ({ pickedQuantity }) => (
          <NumberCell value={Number(pickedQuantity)} />
        ),
      },
      {
        name: 'SH. QTY',
        selector: (row) => String(row.shippedQuantity),
        sortable: true,
        right: true,
        cell: ({ shippedQuantity }) => (
          <NumberCell value={Number(shippedQuantity)} />
        ),
      },
      {
        name: 'ACTIONS',
        selector: (row) => String(row.id),
        compact: true,
        style: {
          marginRight: '5px',
        },
        cell: (row) => {
          const productionOrders = (row.productionOrders as Orders) || [];
          const productionInfo = productionOrders?.length
            ? productionOrders[0].productionInfo
            : undefined;
          const notes = productionInfo ? productionInfo?.notes : '';
          const editable = !['B-CNCL', 'CNCL', 'SHIP'].includes(
            String(row?.status)
          );

          return (
            <div className="d-flex justify-content-end flex-shrink-0">
              {editable && row.stage !== 'READY' && row.shipDate && (
                <button
                  onClick={() => markOrderReady(row.id)}
                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  title="Mark as ready"
                >
                  <SVGImage
                    path="/media/icons/check.svg"
                    className="svg-icon-3"
                  />
                </button>
              )}
              <button
                onClick={() => {}}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                title="View order"
              >
                <SVGImage path="/media/icons/eye.svg" className="svg-icon-3" />
              </button>
              {editable && row?.routingRequired && (
                <button
                  onClick={() => setRoutingDetails(row.id)}
                  className={clsx(
                    'btn btn-icon btn-active-color-primary btn-sm me-1',
                    {
                      'btn-light-danger': !productionInfo?.routing,
                      'btn-light-success': productionInfo?.routing?.pickupDate,
                      'btn-light-warning':
                        productionInfo &&
                        !productionOrders[0]?.productionInfo?.routing
                          ?.pickupDate,
                    }
                  )}
                  title="Edit routing details"
                >
                  <SVGImage
                    path="/media/icons/route.svg"
                    className="svg-icon-3"
                  />
                </button>
              )}
              {editable && notes && (
                <button
                  className={clsx(
                    'btn btn-icon btn-active-color-primary btn-sm me-1',
                    {
                      'btn-light-success': notes,
                      'btn-light-primary': !notes,
                    }
                  )}
                  onClick={() => showPurchaseOrderNotes(row.id)}
                  title="Edit order notes"
                >
                  <SVGImage
                    path="/media/icons/memo.svg"
                    className="svg-icon-3"
                  />
                </button>
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <CardBody className="py-4">
        <DataTable
          title={listTitle}
          columns={columns}
          data={orders}
          expandableRows={true}
          expandableRowsComponent={ExpandableRow}
        />
        <Pagination />
      </CardBody>
      {itemForUpdate !== undefined && (
        <FormDialog
          icon={
            <SVGImage
              path="/media/icons/warehouse.svg"
              className="svg-icon-1"
            />
          }
          onClose={() => setItemForUpdate(undefined)}
          title="Production Order"
        >
          <ProductionOrderFormWrapper form={formName} />
        </FormDialog>
      )}
    </>
  );
};

export const ProductionOrderColumns: TableColumn<Order>[] = [
  {
    id: 'order',
    name: 'CONTROL',
    sortable: true,
    cell: (row) => {
      return (
        <TileCell
          title={
            <>
              <OrderStatus
                status={
                  row.status === 'OPEN' && row.stage === 'WAREHOUSE'
                    ? row.stage
                    : row?.status
                }
              />{' '}
              {row.id}
            </>
          }
          url={`/orders/${row.id}`}
        >
          <OrderOptionsCell objectValue={row} />
        </TileCell>
      );
    },
  },
  {
    id: 'pick-ticket',
    name: 'PT #',
    compact: true,
    cell: ({ pickTicket }) =>
      pickTicket ? (
        <Link to={`/production/pick-ticket/${pickTicket?.id}`}>
          {pickTicket?.id}
        </Link>
      ) : (
        ''
      ),
  },
  {
    name: 'DIV',
    id: 'division',
    sortable: true,
    cell: ({ division }) => {
      return (
        <Link
          to={`/third-parties/divisions/${division?.id}`}
          className="min-w-75px"
        >
          {String(division?.id)}
        </Link>
      );
    },
  },
  {
    name: 'O. DATE',
    selector: ({ orderDate }) => String(orderDate),
    sortable: true,
    cell: ({ orderDate }) =>
      orderDate ? <DateTimeCell value={orderDate} /> : '',
  },
  {
    name: 'S. DATE',
    selector: ({ startDate }) => String(startDate),
    sortable: true,
    cell: ({ startDate }) =>
      startDate ? <DateTimeCell value={startDate} /> : '',
  },
  {
    name: 'C. DATE',
    selector: ({ cancelDate }) => String(cancelDate),
    sortable: true,
    cell: ({ cancelDate }) =>
      cancelDate ? <DateTimeCell value={cancelDate} /> : '',
  },
  {
    name: 'SH. DATE',
    selector: ({ shipDate }) => String(shipDate),
    sortable: true,
    cell: ({ shipDate }) => {
      return shipDate ? <DateTimeCell value={shipDate} /> : '';
    },
  },
  {
    name: 'T. QTY',
    selector: ({ openQuantity }) => Number(openQuantity).toLocaleString(),
    sortable: true,
    cell: ({ orderQuantity }) => <NumberCell value={Number(orderQuantity)} />,
    right: true,
  },
  {
    name: 'O. QTY',
    selector: ({ openQuantity }) => Number(openQuantity).toLocaleString(),
    sortable: true,
    cell: ({ openQuantity }) => <NumberCell value={Number(openQuantity)} />,
    right: true,
  },
  {
    name: 'P. QTY',
    sortable: true,
    cell: ({ pickedQuantity }) => <NumberCell value={Number(pickedQuantity)} />,
    right: true,
  },
  {
    name: 'SH. QTY',
    sortable: true,
    cell: ({ shippedQuantity }) => (
      <NumberCell value={Number(shippedQuantity)} />
    ),
    right: true,
  },
];
