import { Box, Grid } from "@mui/material";
import clsx from "clsx";
import { useLayout } from "../../core";
import { Footer } from "./Footer";

export const FooterWrapper = () => {
  const { config } = useLayout();
  if (!config.app?.footer?.display) {
    return null;
  }

  return (
    <Box
      sx={{
        p: { xs: 4, md: 10 },
        pt: 12,
        pb: 12,
        fontSize: { xs: "12px", md: "14px" }
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        {config.app.footer.containerClass ? (
          <Grid container className={clsx(config.app.footer.containerClass)}>
            <Footer />
          </Grid>
        ) : (
          <Footer />
        )}
      </Grid>
    </Box>
  );
};

export default FooterWrapper;
