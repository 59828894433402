import DataGrid, { DataGridInstance } from '@ialopezg/datagrid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TuneIcon from '@mui/icons-material/Tune';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { FC, MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { Row, TableInstance } from 'react-table';

import { StatisticCardProps, SVGImage } from '../../../../../core';
import StatisticCard from '../../../../../core/components/Card/StatisticCard';
import Icon from '../../../../../core/components/Icon';
import { Pagination } from '../../../../components';
import { ID, initialState } from '../../../../models';
import {
  useListView,
  useRequest,
  useRequestFilters,
  useResponse,
  useResponseData,
  useResponsePagination,
} from '../../../../providers';
import {
  CustomerSelectWidget,
  DivisionSelectWidget,
  WarehouseSelectWidget,
} from '../../../third-parties';
import {
  getOrderStatistics,
  exportPurchaseOrder3PLReport,
  exportPurchaseOrders,
} from '../../api';
import OrderStatusSelectWidget from '../../components/OrderStatusSelectWidget';
import { PurchaseOrder } from '../../models';
import { PurchaseOrderFormActionType } from './Forms/PurchaseOrderFormActionType';
import PurchaseOrderFormWrapper from './Forms/PurchaseOrderFormWrapper';
import PurchaseOrderExpandedRow from './PurchaseOrderExpandableRow';
import { createColumns } from './PurchaseOrderTableColumns';
import { createDownloadLink, stringifyRequestQuery } from '../../../../helpers';

interface StatisticsWrapperProp {
  stats: StatisticCardProps[];
}

const StatisticsWrapper: FC<StatisticsWrapperProp> = ({ stats }) => {
  return (
    <Grid item xs={12}>
      {stats && (
        <Grid container spacing={6}>
          {stats.map((statistic, index) => (
            <Grid item xs={12} md={3} sm={6} key={`statistic-widget-${index}`}>
              <StatisticCard
                {...statistic}
                icon={
                  <SVGImage
                    path={`/media/icons/regular/${
                      statistic.icon as string
                    }.svg`}
                    className={`svg-icon-1 text-${
                      statistic.color === 'error' ? 'danger' : statistic.color
                    }`}
                  />
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

interface FiltersWrapperProps {
  module: string;
}

const FiltersWrapper: FC<FiltersWrapperProps> = ({ module }) => {
  const { updateState } = useRequest();
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    (() => {
      updateState({
        filter: {
          status: status !== '' ? status.toUpperCase() : undefined,
        },
        ...initialState,
      });
    })();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      <Grid item sm={9} xs={12}></Grid>

      <Grid item sm={3} xs={12}>
        <OrderStatusSelectWidget
          module={module}
          selected={status}
          onChange={(status) => setStatus(status)}
        />
      </Grid>
    </>
  );
};

export type ModuleType = 'sales' | 'picked' | 'production' | 'routed';

type PurchaseOrderListProps = {
  module?: ModuleType;
  onOrderNotesAction?: (id: ID) => void;
  openDrawer?: boolean;
};

export const PurchaseOrderList: FC<PurchaseOrderListProps> = ({
  module = 'sales',
}) => {
  const { state, updateState } = useRequest();
  const filters = useRequestFilters();
  const { isLoading, isReloading } = useResponse();
  const orders = useResponseData();
  const { setItemForUpdate } = useListView();

  // ** State
  const [customer, setCustomer] = useState<string>('');
  const [division, setDivision] = useState<string>('');
  const [warehouse, setWarehouse] = useState<string>('');
  const [stats, setStats] = useState<StatisticCardProps[]>([]);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [action, setAction] = useState<PurchaseOrderFormActionType>(undefined);

  const columns = useMemo(
    () =>
      createColumns({
        module,
        CustomerFilter: (
          <CustomerSelectWidget
            module={module}
            onChange={(customer) => {
              setCustomer(customer);
            }}
            selected={customer}
          />
        ),
        DivisionFilter: (
          <DivisionSelectWidget
            module={module}
            onChange={(division) => {
              setDivision(division);
            }}
            selected={division}
          />
        ),
        WarehouseFilter: (
          <WarehouseSelectWidget
            module={module}
            onChange={(warehouse) => {
              setWarehouse(warehouse);
            }}
            selected={warehouse}
          />
        ),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const data = useMemo(() => orders, [orders]);

  const onMenuItemAction = (
    e: MouseEvent,
    row: Row<PurchaseOrder>,
    callback?: () => void,
    action: PurchaseOrderFormActionType = 'view'
  ) => {
    e.stopPropagation();
    e.preventDefault();

    const { reference: id } = row.original;
    setItemForUpdate(id);
    setAction(action);
    setIsExporting(action === 'download');

    callback && callback();
  };

  const onAddEditAttachmentAction = (
    e: MouseEvent,
    row: Row<PurchaseOrder>,
    callback?: () => void,
    action: PurchaseOrderFormActionType = 'view'
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setAction('attachments');

    const { reference: id } = row.original;

    setItemForUpdate(id);
    setAction(action);
    setIsExporting(action === 'download');

    callback && callback();
  };

  const onDownload = async () => {
    console.log(
      stringifyRequestQuery({
        filter: { module },
        ...state,
      })
    );
    setIsExporting(true);
    try {
      const { content } = await exportPurchaseOrders(
        stringifyRequestQuery({
          filter: { module },
          ...state,
        })
      );

      createDownloadLink(`${module}-orders-list.csv`, content);
    } catch (e) {
      console.error(e);
    } finally {
      setIsExporting(false);
    }
  };

  const download3PlReport = async (
    e: MouseEvent,
    row: Row<PurchaseOrder>,
    callback?: () => void
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setIsExporting(true);
    callback && callback();

    try {
      const { filename, content } = await exportPurchaseOrder3PLReport(
        row.original?.reference
      );

      createDownloadLink(filename, content);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsExporting(false);
    }
  };

  const renderRowActionMenuItems = (
    row: Row<PurchaseOrder>,
    _: TableInstance<PurchaseOrder>,
    onCloseMenu: () => void
  ) => {
    const { original: purchaseOrder } = row;
    const canApproveToWH =
      purchaseOrder.isPicked && purchaseOrder?.stage === 'NONE';
    const canViewAttachmentsAndNotes =
      ['picked', 'production', 'routed'].includes(module) && !canApproveToWH;
    const canRoute =
      ['production', 'routed'].includes(module) &&
      purchaseOrder?.customer?.routingRequired;

    const items: ReactNode[] = [
      <MenuItem
        key="row-action-view"
        onClick={(e) => onMenuItemAction(e, row, onCloseMenu)}
      >
        <Icon icon="mdi:eye-outline" /> Preview
      </MenuItem>,
    ];
    if (canApproveToWH) {
      items.push(
        <MenuItem
          key="row-action-approve"
          onClick={(e) => onMenuItemAction(e, row, onCloseMenu, 'approve')}
          sx={{ color: 'primary' }}
        >
          <Icon color="blue" icon="mdi:check" /> Approve to WH
        </MenuItem>
      );
    }
    if (canViewAttachmentsAndNotes) {
      items.push(
        <MenuItem
          color="success"
          key="row-action-notes"
          onClick={(e) => onMenuItemAction(e, row, onCloseMenu, 'notes')}
        >
          <Icon color="green" icon="mdi:note-text-outline" /> View notes
        </MenuItem>
      );
      items.push(
        <MenuItem
          key="row-action-attachments"
          onClick={(e) =>
            onAddEditAttachmentAction(e, row, onCloseMenu, 'attachments')
          }
        >
          <Icon color="blue" icon="mdi:paperclip" /> View attached files
        </MenuItem>
      );

      if (canRoute) {
        items.push(
          <MenuItem
            key="row-action-routing"
            onClick={(e) =>
              onAddEditAttachmentAction(e, row, onCloseMenu, 'routing')
            }
          >
            <AltRouteIcon color="error" /> View routing details
          </MenuItem>
        );
      }

      if (module === 'production') {
        items.push(
          <MenuItem
            key="row-action-ready"
            onClick={(e) =>
              onAddEditAttachmentAction(e, row, onCloseMenu, 'ready')
            }
          >
            <Icon icon="mdi:package-variant-closed-check" />`
            {`${
              !purchaseOrder?.estimatedShipDate ? 'Add' : 'Edit'
            } estimated ship date`}
          </MenuItem>
        );
      }
    }

    return [
      ...items,
      <MenuItem
        key="row-action-download"
        onClick={(e) => download3PlReport(e, row, onCloseMenu)}
      >
        <Icon color="purple" icon="mdi:cloud-download-outline" /> Download 3PL
        Report
      </MenuItem>,
    ];
  };

  let hiddenColumns: Array<string> = [];
  if (module !== 'sales') {
    hiddenColumns = ['orderQuantity', 'openQuantity', 'shippedQuantity'];
  }

  useEffect(() => {
    (async () => {
      const statistics = await getOrderStatistics('');

      setStats(statistics);
    })();

    return () => {};
  }, []);

  useEffect(() => {
    (() => {
      updateState({
        filter: {
          ...filters,
          customer: customer !== '' ? customer : undefined,
          division: division !== '' ? division : undefined,
          warehouse: warehouse !== '' ? warehouse : undefined,
        },
        ...initialState,
      });
    })();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, division, warehouse]);

  return (
    <Grid container spacing={6}>
      <StatisticsWrapper stats={stats} />

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Search Filters"
            sx={{ pb: 4, '& .MuiCardHeader-title': { letterSpacing: '.15px' } }}
          />

          <CardContent>
            <Grid container spacing={6}>
              <FiltersWrapper module={module} />
            </Grid>
          </CardContent>

          <Divider />

          <DataGrid
            // @ts-ignore
            columns={columns}
            data={data}
            detailPanel={(row: Row<PurchaseOrder>) =>
              PurchaseOrderExpandedRow(row)
            }
            enableClickToCopy
            enableColumnResizing
            enableRowActions
            enableSelection
            initialState={{ hiddenColumns }}
            isLoading={isLoading}
            isFetching={isReloading || isExporting}
            manualPagination
            onGlobalFilterChange={(e) => {
              if (e.target.value.length) {
                updateState({ ...state, search: e.target.value });
              } else {
                updateState({ ...state, search: undefined });
              }
            }}
            rowActionMenuItems={renderRowActionMenuItems}
            toolbarCustomActions={(_table: DataGridInstance<PurchaseOrder>) => (
              <Box>
                <Tooltip arrow title="Extra filtering options">
                  <IconButton
                    color="primary"
                    onClick={() => setAction('filter')}
                  >
                    <TuneIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Download">
                  <IconButton color="primary" onClick={onDownload}>
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />

          <Grid item xs={12}>
            <Pagination />
          </Grid>
        </Card>
      </Grid>

      <PurchaseOrderFormWrapper
        action={action}
        onCloseAction={() => setAction(undefined)}
        open={!!action}
      />

      {isExporting && <CircularProgress />}
    </Grid>
  );
};
