import { isNotEmpty } from '@ialopezg/commonjs';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment/moment';
import { FC } from 'react';
import { Button, FormGroup, InputGroup } from 'react-bootstrap';
import * as Yup from 'yup';

import { SVGImage } from '../../../../../components';
import { Order, Orders } from '../../../../../models';
import { useListView, useResponse } from '../../../../../providers';
// import { createRoutingOrder, updateRoutingOrder } from '../../../api';
import { PurchaseOrder } from '../../../models';

type Props = {
  isLoading?: boolean,
  purchaseOrder?: PurchaseOrder,
};

export const RoutingDetailsForm: FC<Props> = ({ isLoading, purchaseOrder }) => {
    const { reload } = useResponse();
    const { setItemForUpdate } = useListView();
    const order = (purchaseOrder?.productionOrders || [])[0] as Order;

    const onCancelActionHandler = (withRefresh?: boolean) => {
      if (withRefresh) {
        reload();
      }

      setItemForUpdate(undefined);
    };

    return (
      <Formik
        validationSchema={Yup.object().shape({
          customerContacted: Yup.boolean(),
          carrier: Yup.string(),
          confirmationNumber: Yup.string(),
          pickupDate: Yup.date().nullable(),
          startTime: Yup.string(),
          endTime: Yup.string(),
          billOfLandingNumber: Yup.string(),
          notes: Yup.string(),
          globalError: Yup.string(),
        })}
        initialValues={{
          customerContacted: order?.productionInfo?.routing?.customerContacted || false,
          carrier: order?.productionInfo?.routing?.carrier || undefined,
          confirmationNumber: order?.productionInfo?.routing?.confirmationNumber || undefined,
          pickupDate: order?.productionInfo?.routing?.pickupDate
            ? new Date(String(order?.productionInfo?.routing?.pickupDate)).toISOString().slice(0, 10)
            : undefined,
          startTime: order?.productionInfo?.routing?.startTime?.toString() || undefined,
          endTime: order?.productionInfo?.routing?.endTime?.toString() || undefined,
          billOfLandingNumber: order?.productionInfo?.routing?.billOfLandingNumber || undefined,
          notes: order?.productionInfo?.routing?.notes || undefined,
          globalError: undefined,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);

          try {
            if (isNotEmpty(order?.productionInfo?.routing?.id)) {
              values.pickupDate = moment(values.pickupDate).toISOString(true);
              // await updateRoutingOrder(purchaseOrder?.id, values);
            } else {
              // await createRoutingOrder(purchaseOrder?.id, values);
            }

            onCancelActionHandler(true);
          } catch (error: any) {
            if (error.response.status === 404) {
              setErrors({
                globalError: error.response.data.message,
              });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
            values,
            isSubmitting,
            isValid,
            touched,
            errors,
          }) => {
          const orders = purchaseOrder?.productionOrders as Orders;

          return (
            <Form className="form p-0">
              {errors && errors.globalError && (
                <div className="text-danger">{errors.globalError}</div>
              )}

              <div className="card-body p-1">
                <h3 className="font-size-lg text-dark font-weight-bold mb-6">1. Order Details:</h3>
                <div className="mb-5">
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label text-end">Purchase Order:</label>
                    <div className="col-lg-8 col-form-label">
                      <strong>{purchaseOrder?.id}</strong>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label text-end">Production Order(s):</label>
                    <div className="col-lg-8 col-form-label">
                      <strong>
                        {orders?.length > 1
                          ? `${orders.length} orders`
                          : orders[0].id}
                      </strong>
                    </div>
                  </div>
                </div>

                <h3 className="font-size-lg text-dark font-weight-bold mb-6">2. Routing Details:</h3>
                <div className="mb-3">
                  <div className="form-group row">
                    <div className="col-lg-4 text-end">
                      <div className="d-flex flex-stack">
                        <div className="me-0">
                          <label className="fs-6 fw-bold form-label">Customer contacted?</label>
                          <div className="fs-7 fw-bold text-gray-400">
                            Customer reached out.
                          </div>
                        </div>
                      </div>
                    </div>
                    <label className="col-lg-8 form-check form-switch form-check-custom form-check-solid">
                      <Field
                        className="form-check-input"
                        type="checkbox"
                        name="customerContacted"
                        value="1"
                        checked={values.customerContacted}
                      />
                    </label>
                  </div>
                  <FormGroup className="row">
                    <label className="col-lg-4 col-form-label text-end">Carrier name:</label>
                    <div className="col-lg-8">
                      <InputGroup className="mb-3">
                        <InputGroup.Text>
                          <SVGImage path="/media/icons/id-card.svg" />
                        </InputGroup.Text>
                        <Field
                          type="text"
                          name="carrier"
                          placeholder="Enter carrier name"
                          className="form-control"
                          value={values.carrier}
                        />
                      </InputGroup>
                      <ErrorMessage name="carrier" />
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <label className="col-lg-4 col-form-label text-end">Pickup confirmation #:</label>
                    <div className="col-lg-8">
                      <InputGroup className="mb-3">
                        <InputGroup.Text>
                          <SVGImage path="/media/icons/hashtag.svg" />
                        </InputGroup.Text>
                        <Field
                          type="text"
                          name="confirmationNumber"
                          className="form-control"
                          placeholder="Enter confirmation number"
                          value={values.confirmationNumber}
                        />
                      </InputGroup>
                      <ErrorMessage name="confirmationNumber" />
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <label className="col-lg-4 col-form-label text-end">Pickup Date:</label>
                    <div className="col-lg-8">
                      <InputGroup className="mb-3">
                        <InputGroup.Text>
                          <SVGImage path="/media/icons/calendar.svg" />
                        </InputGroup.Text>
                        <Field
                          type="date"
                          name="pickupDate"
                          className="form-control"
                          placeholder="Choose the pickup date"
                          value={values.pickupDate}
                          pattern="\d{4}-\d{2}-\d{2}"
                        />
                      </InputGroup>
                      <ErrorMessage name="pickupDate" />
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <label className="col-lg-4 col-form-label text-end">Pickup time:</label>
                    <div className="col-lg-8">
                      <div className="d-flex flex-row mb-3">
                        <div className="col-6 ml-2">
                          <Field
                            type="time"
                            name="startTime"
                            className="form-control"
                            placeholder="Enter timeframe start"
                            value={values.startTime}
                          />
                        </div>
                        <div className="col-6 mr-3">
                          <Field
                            type="time"
                            name="endTime"
                            className="form-control mr-2"
                            placeholder="Enter timeframe end"
                            value={values.endTime}
                          />
                        </div>
                      </div>
                    </div>
                    <ErrorMessage name="startTime" />
                    <ErrorMessage name="endTime" />
                  </FormGroup>
                  <FormGroup className="form-group row">
                    <label className="col-lg-4 col-form-label text-end">Bill Of Landing #:</label>
                    <div className="col-lg-8">
                      <InputGroup className="mb-3">
                        <InputGroup.Text>
                          <SVGImage path="/media/icons/hashtag.svg" />
                        </InputGroup.Text>
                        <Field
                          type="text"
                          name="billOfLandingNumber"
                          className="form-control"
                          placeholder="Bill Of Landing number"
                          value={values.billOfLandingNumber}
                        />
                      </InputGroup>
                      <ErrorMessage name="billOfLandingNumber" />
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <label className="col-lg-4 col-form-label text-end">Notes:</label>
                    <div className="col-lg-8">
                      <InputGroup className="mb-3">
                        <InputGroup.Text>
                          <SVGImage path="/media/icons/memo.svg" />
                        </InputGroup.Text>
                        <Field
                          as="textarea"
                          name="notes"
                          placeholder="Additional notes or instructions"
                          className="form-control"
                          value={values.notes}
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>
                </div>
              </div>

              <div className="card-footer pb-0">
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-6">
                    <Button
                      type="reset"
                      variant="secondary"
                      className="me-3"
                      disabled={isSubmitting || isLoading}
                      onClick={() => onCancelActionHandler()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      className="mr-2"
                      disabled={isSubmitting || isLoading || !isValid || !touched}
                    >
                      {!isSubmitting && (
                        <span className="indicator-label">{purchaseOrder?.routing?.id ? 'Update' : 'Save'}</span>
                      )}
                      {isSubmitting && isLoading && (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
;