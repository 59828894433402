import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { PageLink, PageTitle } from "../../../../core";
import { ShippingOrdersListWrapper } from './components';

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

export const ShippingOrdersPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Shipping Orders List</PageTitle>
              <ShippingOrdersListWrapper />
            </>
          }
      />
      <Route index element={<Navigate to="/orders/shipping/list"/>}/>
    </Route>
  </Routes>
);

export default ShippingOrdersPage;
