import { FC } from 'react';
import AsyncSelect from 'react-select/async';
import FormSelect from 'react-select';

type Props = {
  isClearable?: boolean,
  isDisabled?: boolean,
  remoteLoad?: boolean,
  remoteLoadCallback?: (query: string) => Promise<any[]>,
};

export const Select: FC<Props> = (
  {
    isClearable,
    isDisabled,
    remoteLoad,
    remoteLoadCallback,
  },
) => {
  if (remoteLoad && !remoteLoadCallback) {
    throw new Error('Remote Load Callback is required!')
  }

  return (
    <>
      {remoteLoad && <AsyncSelect
        cacheOptions
        defaultOptions
        loadOptions={remoteLoadCallback}
      />}
      {!remoteLoad && <FormSelect
        isClearable={isClearable}
        isDisabled={isDisabled}
      />}
    </>
  );
};