import ClearAllIcon from '@mui/icons-material/ClearAll';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { DateType } from '../../../../../components';

import CardSnippet from '../../../../../components/Cards/snippet';
import DatePickerInput from '../../../../../components/DatePicker/DatePickerInput';
import { initialState } from '../../../../../models';
import { useRequest, useRequestFilters } from '../../../../../providers';
import {
  CustomerSelectWidget,
  WarehouseSelectWidget,
} from '../../../../third-parties';
import { DivisionSelectWidget } from '../../../../third-parties/divisions/components/DivisionSelectWidget';

type DateTypeRange = {
  from?: DateType;
  to?: DateType;
};

interface DateRangePickersProps {
  range: DateTypeRange;
  setRange: (value: DateTypeRange) => void;
  title: string;
}

const DateRangePickers: FC<DateRangePickersProps> = ({
  range,
  setRange,
  title,
}) => {
  const theme = useTheme();
  const { direction } = theme;
  const popperPlacement: ReactDatePickerProps['popperPlacement'] =
    direction === 'ltr' ? 'bottom-start' : 'bottom-end';

  return (
    <Grid container spacing={6} sx={{ paddingTop: 5 }}>
      <Grid item xs={12} sx={{ paddingBottom: 0 }}>
        <Typography fontWeight="bold" variant="subtitle1">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="min-date"
          selected={range.from}
          popperPlacement={popperPlacement}
          onChange={(date: Date) => setRange({ ...range, from: date })}
          customInput={<DatePickerInput label="Min Date" />}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="max-date"
          selected={range.to}
          popperPlacement={popperPlacement}
          onChange={(date: Date) => setRange({ ...range, to: date })}
          customInput={<DatePickerInput label="Max Date" />}
        />
      </Grid>
    </Grid>
  );
};

interface PurchaseOrderFormFiltersProps {
  module: 'sales' | 'picked' | 'production' | 'routing';
  onCloseAction?: () => void;
}

export const PurchaseOrderFormFilters: FC<PurchaseOrderFormFiltersProps> = ({
  module,
  onCloseAction,
}) => {
  const { updateState } = useRequest();
  const filters = useRequestFilters();

  const [customer, setCustomer] = useState<string>(
    (filters as any).customer ?? ''
  );
  const [division, setDivision] = useState<string>(
    (filters as any).division ?? ''
  );
  const [warehouse, setWarehouse] = useState<string>(
    (filters as any).warehouse ?? ''
  );
  const sd = ((filters as any).startDate ?? '').split(',');
  const [startDate, setStartDate] = useState<DateTypeRange>({
    from: sd[0] && sd[0]?.length ? new Date(sd[0]) : undefined,
    to: sd[1] && sd[1]?.length ? new Date(sd[1]) : undefined,
  });
  const cd = ((filters as any).cancelDate ?? '').split(',');
  const [cancelDate, setCancelDate] = useState<DateTypeRange>({
    from: cd[0] && cd[0]?.length ? new Date(cd[0]) : undefined,
    to: cd[1] && cd[1]?.length ? new Date(cd[1]) : undefined,
  });
  const pd = ((filters as any).pickupDate ?? '').split(',');
  const [pickupDate, setPickupDate] = useState<DateTypeRange>({
    from: pd[0] && pd[0]?.length ? new Date(pd[0]) : undefined,
    to: pd[1] && pd[1]?.length ? new Date(pd[1]) : undefined,
  });
  const esd = ((filters as any).estimatedShipDate ?? '').split(',');
  const [estimatedShipDate, setEstimatedShipDate] = useState<DateTypeRange>({
    from: esd[0] && esd[0]?.length ? new Date(esd[0]) : undefined,
    to: esd[1] && esd[1]?.length ? new Date(esd[1]) : undefined,
  });

  useEffect(() => {
    (() => {
      const startDateRange = [
        startDate.from && new Date(startDate.from).toISOString().slice(0, 10),
        startDate.to && new Date(startDate.to).toISOString().slice(0, 10),
      ].toString();
      const cancelDateRange = [
        cancelDate.from && new Date(cancelDate.from).toISOString().slice(0, 10),
        cancelDate.to && new Date(cancelDate.to).toISOString().slice(0, 10),
      ].toString();
      const pickupDateRange = [
        pickupDate.from && new Date(pickupDate.from).toISOString().slice(0, 10),
        pickupDate.to && new Date(pickupDate.to).toISOString().slice(0, 10),
      ].toString();
      const estimatedShipDateRange = [
        estimatedShipDate.from &&
          new Date(estimatedShipDate.from).toISOString().slice(0, 10),
        estimatedShipDate.to &&
          new Date(estimatedShipDate.to).toISOString().slice(0, 10),
      ].toString();

      updateState({
        filter: {
          ...filters,
          customer: customer !== '' ? customer : undefined,
          division: division !== '' ? division : undefined,
          warehouse: warehouse !== '' ? warehouse : undefined,
          startDate: startDateRange.length > 1 ? startDateRange : undefined,
          cancelDate: cancelDateRange.length > 1 ? cancelDateRange : undefined,
          pickupDate: pickupDateRange.length > 1 ? pickupDateRange : undefined,
          estimatedShipDate:
            estimatedShipDateRange.length > 1
              ? estimatedShipDateRange
              : undefined,
        },
        ...initialState,
      });
    })();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customer,
    division,
    warehouse,
    startDate,
    cancelDate,
    pickupDate,
    estimatedShipDate,
  ]);

  const onSubmitAction = () => {
    onCloseAction?.();
  };

  const clearFiltersAction = () => {
    updateState({ ...initialState, filter: undefined });
    onCloseAction?.();
  };

  return (
    <CardSnippet title="Advanced Filter Options">
      <form onSubmit={onSubmitAction}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <CustomerSelectWidget
            module={module}
            onChange={setCustomer}
            selected={customer}
          />
        </Grid>

        <Grid item xs={12} sx={{ mb: 3 }}>
          <DivisionSelectWidget
            module={module}
            onChange={setDivision}
            selected={division}
          />
        </Grid>

        <Grid item xs={12} sx={{ mb: 3 }}>
          <WarehouseSelectWidget
            module={module}
            onChange={setWarehouse}
            selected={warehouse}
          />
        </Grid>

        <DateRangePickers
          range={startDate}
          setRange={setStartDate}
          title="Start Date"
        />
        <DateRangePickers
          range={cancelDate}
          setRange={setCancelDate}
          title="Cancel Date"
        />
        <DateRangePickers
          range={pickupDate}
          setRange={setPickupDate}
          title="Pickup Date"
        />
        <DateRangePickers
          range={estimatedShipDate}
          setRange={setEstimatedShipDate}
          title="Estimated Ship Date"
        />

        <Grid container spacing={6} sx={{ mt: 3 }}>
          <Grid item xs={6}>
            <span />
          </Grid>
          <Grid item xs={6}>
            <Button color="error" fullWidth onClick={clearFiltersAction} variant="contained">
              <ClearAllIcon />
              Clear filters
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardSnippet>
  );
};

export default PurchaseOrderFormFilters;
