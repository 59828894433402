import ErrorIcon from '@mui/icons-material/Error';
import {
  Alert,
  Box,
  CircularProgress,
  Fade,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

import Icon from '../../../../../../../core/components/Icon';
import List from '../../../../../../components/list/List';
import CardSnippet from '../../../../../../components/Cards/snippet';
import Customer from '../../../../../../models/CustomerModel';
import { CustomerRequirement } from '../../../../../../models';
import CustomerRequirementForm from './CustomerRequirementForm';
import { FieldValues } from 'react-hook-form';
import {
  createCustomerRequirement,
  getCustomerById,
  updateCustomerRequirement,
} from '../../../api';
import IconWithTooltip from '../../../../../../../core/components/Icon/IconWithTooltip';
import { useQuery } from 'react-query';
import { QUERIES } from '../../../../../../constants';

export interface CustomerRequirementsFormProps {
  customer: Customer;
  onSubmitAction: () => void;
}

export const CustomerRequirementsForm: FC<CustomerRequirementsFormProps> = ({
  customer,
  onSubmitAction,
}) => {
  const enabled = !!customer?.id;
  const {
    data: c,
    isLoading,
    refetch,
  } = useQuery(
    `${QUERIES.CUSTOMERS}-${customer?.id}`,
    async () => getCustomerById(customer?.id),
    { enabled }
  );

  // ** State
  const [requirements, setRequirements] = useState<Array<CustomerRequirement>>(
    c?.requirements ?? []
  );
  const [edit, setEdit] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [useAllDivisions, setUseAllDivisions] = useState<boolean>(true);
  const [requirement, setRequirement] = useState<
    CustomerRequirement | undefined
  >(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const onListItemClickAction = (requirement: CustomerRequirement) => {
    setEdit(true);
    setUseAllDivisions(false);
    setRequirement(requirement);
  };

  const onSaveAction = async (values: FieldValues) => {
    setIsSubmitting(true);

    const { divisionId, ...rest } = values;
    if (!divisionId || divisionId === 'undefined') {
      setError('Division is required!');

      return;
    }

    const hasValues =
      [...new Set(Object.keys(rest).filter((k) => rest[k] !== false))].length >
      0;
    if (divisionId && !hasValues) {
      setError('At least an option must be selected!');

      return;
    }

    try {
      if (!requirement) {
        await createCustomerRequirement(customer?.id, values);
      } else {
        await updateCustomerRequirement(requirement?.id, {
          ...values,
          customerId: customer?.id,
        });
      }
      customer = await getCustomerById(customer?.id);

      onSubmitAction();
      await refetch();
      setIsSubmitting(false);
      setEdit(false);
    } catch (error: any) {
      console.log(error);
      setError(error.toString());

      throw error;
    }
  };

  useEffect(() => {
    setRequirements(c?.requirements as Array<CustomerRequirement>);
  }, [c?.requirements]);

  return (
    <CardSnippet
      action={
        !edit || !isSubmitting ? (
          <IconButton
            onClick={() => {
              setError(undefined);
              setEdit(true);
            }}
          >
            <Icon icon="mdi:plus" fontSize={20} />
          </IconButton>
        ) : undefined
      }
      sx={{ minHeight: '60vh', overflow: 'auto' }}
      title="Customer Requirements"
    >
      {error && (
        <Fade
          in={!!error}
          timeout={{ enter: 1000, exit: 1000 }}
          addEndListener={() => {
            setTimeout(() => {
              setError(undefined);
            }, 3000);
          }}
        >
          <Alert severity="error" icon={<ErrorIcon />}>
            {error}
          </Alert>
        </Fade>
      )}
      {edit ? (
        <CustomerRequirementForm
          requirement={requirement}
          onCloseAction={() => {
            setEdit(false);
            setUseAllDivisions(true);
            setRequirement(undefined);
          }}
          onSubmitAction={onSaveAction}
          useAllDivisions={useAllDivisions}
          setUseAllDivisions={setUseAllDivisions}
        />
      ) : !requirements?.length && (isSubmitting || isLoading) ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>Loading data...</Typography>
          <CircularProgress />
        </Box>
      ) : (
        <List dense>
          {!requirements?.length ? (
            <ListItem dense disablePadding>
              <ListItemIcon>
                <Icon icon="mdi:file-question-outline" />
              </ListItemIcon>
              <ListItemText primary="No requirements" />
            </ListItem>
          ) : (
            requirements?.map((requirement) => {
              return (
                <ListItem key={`requirement-${requirement?.id}`}>
                  <ListItemButton
                    onClick={() => onListItemClickAction(requirement)}
                  >
                    <ListItemIcon>
                      <Icon icon="mdi:office-building" fontSize={24} />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={requirement?.divisionId}
                      secondary={
                        <Box display="flex" flexDirection="row">
                          <IconWithTooltip
                            enabled={requirement?.routing}
                            title="Routing required"
                            icon="mdi:routes"
                          />
                          <IconWithTooltip
                            enabled={requirement?.fineLineTickets}
                            title="Use FINELINE lables required"
                            icon="mdi:label-multiple"
                          />
                          <IconWithTooltip
                            enabled={requirement?.fineLineTrackingNo}
                            title="FINELINE tracking required"
                            icon="mdi:radar"
                          />
                          <IconWithTooltip
                            enabled={requirement?.printTickets}
                            title="Print tickets required"
                            icon="mdi:printer-pos"
                          />
                          <IconWithTooltip
                            enabled={requirement?.uccLabels}
                            title="Use UCC labels"
                            icon="mdi:label"
                          />
                          <IconWithTooltip
                            enabled={requirement?.palletLabels}
                            title="Pallet tickets required"
                            icon="mdi:shipping-pallet"
                          />
                          <IconWithTooltip
                            enabled={requirement?.individualSKUCartons}
                            title="Use individual SKU per carton"
                            icon="mdi:barcode"
                          />
                          <IconWithTooltip
                            enabled={requirement?.deadlines}
                            title="Has deadline dates"
                            icon="mdi:calendar"
                          />
                        </Box>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })
          )}
        </List>
      )}
    </CardSnippet>
  );
};

export default CustomerRequirementsForm;
