import { Add, Remove } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@mui/material';
import { FormGroup } from 'react-bootstrap';
import { FieldValues } from 'react-hook-form';
import { FC, useEffect, useState } from 'react';
import { useResponse } from '../../../../../../providers';

import { DivisionSelectWidget } from '../../../../divisions';
import List from '../../../../../../components/list/List';
import { CustomerRequirement } from '../../../../../../models';
import Icon from '../../../../../../../core/components/Icon';

interface DaysBeforeProps {
  id: string;
  daysBefore: number;
  setDaysBefore: (value: number) => void;
}

const DaysBefore: FC<DaysBeforeProps> = ({ daysBefore, id, setDaysBefore }) => {
  return (
    <ListItem key={`requirement-${id}-daysBeforeStartDate`}>
      <ListItemIcon>
        <IconButton
          color="error"
          onClick={() => setDaysBefore(daysBefore <= 1 ? 0 : daysBefore - 1)}
        >
          <Remove />
        </IconButton>
      </ListItemIcon>
      <ListItemText primary={`${daysBefore} days before start date`} />
      <ListItemSecondaryAction onClick={() => setDaysBefore(daysBefore + 1)}>
        <IconButton color="primary">
          <Add />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

interface CustomerRequirementFormProps {
  onCloseAction: () => void;
  onSubmitAction: (values: FieldValues) => void;
  requirement?: CustomerRequirement;
  setUseAllDivisions: (value: boolean) => void;
  useAllDivisions: boolean;
}

export const CustomerRequirementForm: FC<CustomerRequirementFormProps> = ({
  onCloseAction,
  onSubmitAction,
  requirement,
  setUseAllDivisions,
  useAllDivisions,
}) => {
  const { reload } = useResponse();

  // ** State
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [divisionId, setDivisionId] = useState<string>(
    String(requirement?.divisionId)
  );
  const [routing, setRouting] = useState<boolean>(
    Boolean(requirement?.routing)
  );
  const [fineLineTickets, setFineLineTickets] = useState<boolean>(
    Boolean(requirement?.fineLineTickets)
  );
  const [fineLineTrackingNo, setFineLineTrackingNo] = useState<boolean>(
    Boolean(requirement?.fineLineTrackingNo)
  );
  const [printTickets, setPrintTickets] = useState<boolean>(
    Boolean(requirement?.printTickets)
  );
  const [uccLabels, setUccLabels] = useState<boolean>(
    Boolean(requirement?.uccLabels)
  );
  const [palletLabels, setPalletLabels] = useState<boolean>(
    Boolean(requirement?.palletLabels)
  );
  const [individualSKUCartons, setIndividualSKUCartons] = useState<boolean>(
    Boolean(requirement?.individualSKUCartons)
  );
  const [daysBeforeStartDate, setDaysBeforeStartDate] = useState<number>(
    requirement?.daysBeforeStartDate ?? 0
  );
  const [daysBeforeCancelDate, setDaysBeforeCancelDate] = useState<number>(
    requirement?.daysBeforeStartDate ?? 0
  );

  const onSaveAction = () => {
    setIsSubmitting(true);

    try {
      const values = {
        divisionId,
        routing,
        fineLineTickets,
        fineLineTrackingNo,
        printTickets,
        uccLabels,
        palletLabels,
        individualSKUCartons,
        daysBeforeStartDate,
        daysBeforeCancelDate,
      };

      setUseAllDivisions(true);
      onSubmitAction(values);
      onCloseAction();
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (useAllDivisions) {
      setDivisionId('ALL');
    }
  }, [useAllDivisions]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={useAllDivisions}
                onChange={() => setUseAllDivisions(!useAllDivisions)}
              />
            }
            label={'Apply to all Divisions'}
          />
        </FormGroup>
      </Grid>

      {!useAllDivisions && (
        <Grid item xs={12}>
          <DivisionSelectWidget
            disabled={!!requirement}
            onChange={(division) => setDivisionId(division)}
            selected={divisionId}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <List>
          <ListItem key={`requirement-${requirement?.id}-rt`}>
            <ListItemIcon>
              <Icon icon="mdi:routes" fontSize={24} />
            </ListItemIcon>
            <ListItemText primary="Routing required" />

            <ListItemSecondaryAction>
              <Switch
                edge="end"
                checked={routing}
                onChange={() => setRouting(!routing)}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem key={`requirement-${requirement?.id}-flt`}>
            <ListItemIcon>
              <Icon icon="mdi:label-multiple" fontSize={24} />
            </ListItemIcon>
            <ListItemText primary="Use FINELINE Labels" />

            <ListItemSecondaryAction>
              <Switch
                edge="end"
                checked={fineLineTickets}
                onChange={() => setFineLineTickets(!fineLineTickets)}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem key={`requirement-${requirement?.id}-fln`}>
            <ListItemIcon>
              <Icon icon="mdi:radar" fontSize={24} />
            </ListItemIcon>
            <ListItemText primary="FINELINE Number Tracking" />

            <ListItemSecondaryAction>
              <Switch
                edge="end"
                checked={fineLineTrackingNo}
                onChange={() => setFineLineTrackingNo(!fineLineTrackingNo)}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem key={`requirement-${requirement?.id}-prt`}>
            <ListItemIcon>
              <Icon icon="mdi:printer-pos" fontSize={24} />
            </ListItemIcon>
            <ListItemText primary="Print Tickets" />

            <ListItemSecondaryAction>
              <Switch
                edge="end"
                checked={printTickets}
                onChange={() => setPrintTickets(!printTickets)}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem key={`requirement-${requirement?.id}-ucc`}>
            <ListItemIcon>
              <Icon icon="mdi:label" fontSize={24} />
            </ListItemIcon>
            <ListItemText primary="Use UCC Labels" />

            <ListItemSecondaryAction>
              <Switch
                edge="end"
                checked={uccLabels}
                onChange={() => setUccLabels(!uccLabels)}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem key={`requirement-${requirement?.id}-pll`}>
            <ListItemIcon>
              <Icon icon="mdi:shipping-pallet" fontSize={24} />
            </ListItemIcon>
            <ListItemText primary="Use Pallet Tickets" />

            <ListItemSecondaryAction>
              <Switch
                edge="end"
                checked={palletLabels}
                onChange={() => setPalletLabels(!palletLabels)}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem key={`requirement-${requirement?.id}-sku`}>
            <ListItemIcon>
              <Icon icon="mdi:barcode" fontSize={24} />
            </ListItemIcon>
            <ListItemText primary="Use individual SKU per carton" />

            <ListItemSecondaryAction>
              <Switch
                edge="end"
                checked={individualSKUCartons}
                onChange={() => setIndividualSKUCartons(!individualSKUCartons)}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <DaysBefore
            id={String(requirement?.id) ?? ''}
            daysBefore={daysBeforeStartDate}
            setDaysBefore={setDaysBeforeStartDate}
          />

          <DaysBefore
            id={String(requirement?.id) ?? ''}
            daysBefore={daysBeforeCancelDate}
            setDaysBefore={setDaysBeforeCancelDate}
          />
        </List>
      </Grid>

      <Grid item xs={6}>
        <Button
          disabled={isSubmitting}
          fullWidth
          onClick={onSaveAction}
          startIcon={<SaveIcon />}
          variant="contained"
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={isSubmitting}
          color="error"
          fullWidth
          onClick={() => {
            setUseAllDivisions(true);
            onCloseAction();
          }}
          startIcon={<CloseIcon />}
          variant="outlined"
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomerRequirementForm;
