import { Route, Routes, Outlet, Navigate } from 'react-router-dom';

import { PageLink, PageTitle } from '../../../../core';
import CustomersListWrapper from './components/CustomerList';
import { CustomerPage } from './components/customer/CustomerPage';
import { CustomerOverview } from './components/customer/CustomerOverview';

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },  {
    title: 'Customers',
    path: '/third-parties/customers/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const CustomersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="overview"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Overview</PageTitle>
              <CustomerOverview />
            </>
          }
        />
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Customer Listing</PageTitle>
              <CustomersListWrapper />
            </>
          }
        />
        <Route
          path=":id/*"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Customer Details</PageTitle>
              <CustomerPage />
            </>
          }
        />
        <Route index element={<Navigate to="/third-parties/customers/overview"/>}/>
      </Route>
    </Routes>
  );
};

export default CustomersPage;
