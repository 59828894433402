import { FC, ReactNode, useEffect } from "react";

import { WithChildren } from "../../../core";
import { ModalDialogHeader } from "./partials";

type ModalDialogProps = {
  description?: string,
  header?: Element,
  id?: string,
  onCloseAction?: Function,
  title?: string,
  icon?: ReactNode,
};

export const ModalDialog: FC<WithChildren & ModalDialogProps> = (
  {
    children,
    description,
    header,
    id,
    onCloseAction,
    title,
    icon
  }
) => {
  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id={id}
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-450px">
          <div className="modal-content">
            {!header && (<ModalDialogHeader
              icon={icon}
              title={title}
              onClose={onCloseAction}
            />)}

            <div className="p-4">
              <p className="text-muted">{description}</p>
            </div>

            <div className="modal-body scroll-y mx-5 mx-xl-10 my-5">{children}</div>
          </div>
        </div>
      </div>

      <div className="modal-backdrop fade show"></div>
    </>
  );
};