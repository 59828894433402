import { Box } from "@mui/material";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

import OptionMenuItemType from "./OptionMenuItemType";

interface MenuItemWrapperProps {
  children: ReactNode;
  option: OptionMenuItemType,
}

export const MenuItemWrapper: FC<MenuItemWrapperProps> = (
  {
    children,
    option
  }
) => {
  if (option.href) {
    return (
      <Box
        component={Link}
        href={option.href}
        {...option.linkProps}
        sx={{
          px: 4,
          py: 1.5,
          width: "100%",
          display: "flex",
          color: "inherit",
          alignItems: "center",
          textDecoration: "none"
        }}
      >
        {children}
      </Box>
    );
  }

  return (<>{children}</>);
};

export default MenuItemWrapper;