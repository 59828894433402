import { AvatarProps, Box, Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

import CustomAvatar from '../Avatar/Avatar';
import Icon from '../Icon';
import StatisticCardProps from './StatisticCardProps';

const Avatar = styled(CustomAvatar)<AvatarProps>(({ theme }) => ({
  width: 40,
  height: 40,
  marginRight: theme.spacing(4),
}));

export const StatisticCard: FC<StatisticCardProps> = ({
  color = 'primary',
  icon,
  stats,
  title,
  trend = 'none',
  trendNumber,
}) => {
  return (
    <Card>
      <CardContent
        sx={{
          py: (theme) => `${theme.spacing(4.125)} !important`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar skin="light" color={color} variant="rounded">
            {icon}
          </Avatar>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
            >
              <Typography variant="h6">{stats}</Typography>

              {trend !== 'none' && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      color:
                        trend === 'positive' ? 'success.main' : 'error.main',
                    }}
                  >
                    <Icon
                      icon={
                        trend === 'positive'
                          ? 'mdi:chevron-up'
                          : 'mdi:chevron-down'
                      }
                    />
                  </Box>

                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        trend === 'positive' ? 'success.main' : 'error.main',
                    }}
                  >
                    {trendNumber}
                  </Typography>
                </Box>
              )}
            </Box>

            <Typography variant="caption">{title}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default StatisticCard;
