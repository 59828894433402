import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";

import { useSettings } from "../../../../core";
import Icon from "../../Icon";
import MenuItemWrapper from "./MenuItemWrapper";

import { OptionsMenuType } from "./OptionsMenuType";
import { OptionType } from "./OptionType";

export const OptionsMenu: FC<OptionsMenuType> = (props) => {
  const {
    leftAlignMenu,
    icon,
    iconButtonProps,
    iconProps,
    menuProps,
    options
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { settings } = useSettings();
  const { direction } = settings;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-haspopup="true" onClick={handleClick} {...iconButtonProps}>
        {icon ? icon : (<Icon icon="mdi:dots-vertical" {...iconProps} />)}
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        {...(!leftAlignMenu) && {
          anchorOrigin: { vertical: "bottom", horizontal: direction === "ltr" ? "right" : "left" },
          transformOrigin: { vertical: "top", horizontal: direction === "ltr" ? "right" : "left" }
        }}
        {...menuProps}
      >
        {options.map((option: OptionType, index) => {
          if (typeof option === "string") {
            return (
              <MenuItem
                key={`option-${index}`}
                onClick={() => {
                  console.log(option);
                  handleClose();
                }}
              >
                {option}
              </MenuItem>
            );
          } else if ("divider" in option) {
            return option.divider && (
              <Divider key={`option-${index}`} {...option.dividerProps} />
            );
          } else {
            return (
              <MenuItem
                key={`option-${index}`}
                {...option?.menuItemProps}
                {...(option.href && { sx: { p: 0 } })}
                onClick={e => {
                  handleClose();

                  if (option.menuItemProps && option.menuItemProps.onClick) {
                    option.menuItemProps.onClick(e);
                  }
                }}
              >
                <MenuItemWrapper option={option}>
                  {option.icon && option.icon}
                  {option.text}
                </MenuItemWrapper>
              </MenuItem>
            );
          }
        })}
      </Menu>
    </>
  );
};

export default OptionsMenu;
