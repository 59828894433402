import { privateAxios } from "../../../api/axios";
import { ID } from "../../../models";
import { API_BASE_URL } from "../../auth";

const PICK_TICKET_URL = `${API_BASE_URL}/pick-ticket`
export const download3PlReport = async (pickTicket: ID): Promise<any> => {
  const response = await privateAxios.get(
    `${PICK_TICKET_URL}/${pickTicket}/export/3pl`
  );

  return response?.data;
};