import { hexToRGBA } from '../../../app/helpers';
import { OwnerStateThemeType } from './';

export const Backdrop = () => {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          backgroundColor:
            theme.palette.mode === 'light'
              ? `rgba(${(theme.palette as any).customColors.main}, 0.5)`
              : hexToRGBA('#101121', 0.87),
        }),
        invisible: {
          backgroundColor: 'transparent',
        },
      },
    },
  };
};

export default Backdrop;
