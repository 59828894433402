import { FC } from 'react';

import { WithChildren } from '../../../../../core';
import { DefaultToolbar, SearchWidget } from '../../../../components';
import { initialState } from '../../../../models';
import { useRequest } from '../../../../providers';

type Props = {
  enableSearch?: boolean,
  enableFiltering?: boolean,
  placeholder?: string,
  toolbar?: JSX.Element,
};

export const OrderListHeader: FC<WithChildren & Props> = (
  {
    children,
    enableSearch = true,
    placeholder,
    toolbar,
  },
) => {
  const { updateState } = useRequest();

  return (
    <div className="card-header border-0">
      {enableSearch && (<SearchWidget
        placeholder={placeholder}
        onChange={(value) => updateState({
          search: value,
          ...initialState,
        })}
      />)}

      <div className="card-toolbar">
        {toolbar ? toolbar : children ? children : <DefaultToolbar />}
      </div>
    </div>
  );
};