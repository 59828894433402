import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../../core/components';
import { DivisionsListWrapper } from './components';

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const DivisionsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Divisions List</PageTitle>
              <DivisionsListWrapper />
            </>
          }
        />
        <Route index element={<Navigate to="/third-parties/divisions/list"/>}/>
      </Route>
    </Routes>
  );
};

export default DivisionsPage;
