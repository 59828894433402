import { FC, useState } from 'react';
import { DownloadButton, FilterButton, ToggleButtons } from "../../../components";
import {
  CustomerSelectWidget,
  DivisionSelectWidget,
  WarehouseSelectWidget,
} from '../../third-parties';
import { useRequest, useResponseLoading } from "../../../providers";
import { initialState } from '../../../models';

type Props = {
  enableAdd?: boolean,
  enableDownload?: boolean,
  enableFiltering?: boolean,
  module?: string,
};

export const OrderListToolbar: FC<Props> = (
  {
    enableAdd = false,
    enableDownload = false,
    enableFiltering = false,
    module,
  },
) => {
  const { state, updateState } = useRequest();
  const isLoading = useResponseLoading();
  const [customer, setCustomer] = useState<string>('ALL');
  const [division, setDivision] = useState<string>('ALL');
  const [warehouse, setWarehouse] = useState<string>('ALL');

  const applyFilters = async () => {
    updateState({
      filter: {
        customer: customer !== 'ALL' ? customer : undefined,
        division: division !== 'ALL' ? division : undefined,
        warehouse: warehouse !== 'ALL' ? warehouse : undefined,
      },
      ...initialState,
    });
  };

  const clearFilters = () => {
    setCustomer('ALL');
    setDivision('ALL');
    setWarehouse('ALL');

    updateState({ filter: undefined, ...initialState });
  };

  const filterByStatus = (status: string) => {
    let filter = state?.filter;
    if (status !== 'ALL') {
      if (!filter) {
        filter = {};
      }

      (filter as any).status = status;
    }

    updateState({ filter, ...initialState })
  };

  return (
    <div className="d-flex justify-content-end">
      {enableFiltering && (
        <FilterButton
          actions={[
            <CustomerSelectWidget
              module={module}
              onChange={setCustomer}
              selected={customer}
            />,
            <DivisionSelectWidget
              module={module}
              onChange={setDivision}
              selected={division}
            />,
            <WarehouseSelectWidget
              module={module}
              onChange={setWarehouse}
              selected={warehouse}
            />
          ]}
          onApplyFilters={applyFilters}
          onClearFilters={clearFilters}
        >
          <ToggleButtons
            onStatusChange={filterByStatus}
            values={['ALL', 'OPEN', 'CNCL', 'SHIP']}
            disabled={isLoading}
          />
        </FilterButton>
      )}

      {enableDownload && (<DownloadButton fileName={`${module}-orders-report`} />)}
      {enableAdd && (<span className="me-3">enableAdd: {JSON.stringify(enableAdd)}</span>)}
    </div>
  );
}
