import {
  Card,
  ListHeader,
  ModalDialogProvider,
} from '../../../../components';
import { QUERIES } from '../../../../constants';
import {
  ListViewProvider,
  RequestProvider,
  ResponseProvider,
  useListView,
} from '../../../../providers';
import { getDivisions } from '../api';
import { DivisionsTable } from './table';

const queryKey = QUERIES.DIVISIONS;

const DivisionsList = () => {
  const { itemForUpdate } = useListView();

  return (
    <>
      <Card>
        <ListHeader queryKey={queryKey} />
        <DivisionsTable />
      </Card>
      {itemForUpdate !== undefined && (<>Division Form</>)}
    </>
  );
};

export const DivisionsListWrapper = () => (
  <RequestProvider>
    <ResponseProvider callback={getDivisions} queryKey={queryKey}>
      <ListViewProvider>
        <ModalDialogProvider>
          <DivisionsList />
        </ModalDialogProvider>
      </ListViewProvider>
    </ResponseProvider>
  </RequestProvider>
);