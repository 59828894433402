import { Add as AddIcon } from "@mui/icons-material";
import { SvgIconPropsColorOverrides } from "@mui/material/SvgIcon/SvgIcon";
import {
  IconButton,
  Tooltip,
  Zoom
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { FC } from "react";
import clsx from "clsx";

type Props = {
  color?: OverridableStringUnion<
    | "inherit"
    | "action"
    | "disabled"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning",
    SvgIconPropsColorOverrides
  >,
  disabled?: boolean,
  title?: string,
  onClick: () => void,
};

export const AddButton: FC<Props> = (
  {
    color = "primary",
    disabled = false,
    onClick,
    title = "Add new record",
  }
) => {
  return (
    <>
      <Tooltip
        title={disabled ? "" : title}
        TransitionComponent={Zoom}
        arrow
      >
        <span>
          <IconButton
            className={clsx({ "disabled-icon": disabled })}
            disabled={disabled}
            onClick={onClick}
          >
            <AddIcon color={color} />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};