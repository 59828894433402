import { FC, ReactNode } from "react";

import { SVGImage } from '../../image';

type ModalDialogHeaderProps = {
  onClose?: Function,
  icon?: ReactNode,
  title?: string,
};

export const ModalDialogHeader: FC<ModalDialogHeaderProps> = (
  {
    onClose,
    icon,
    title,
  },
) => {
  return (
    <div className="modal-header  bg-gray-300i pt-2 pb-2">
      <h2 className="fw-bolder">{icon ? icon : ''}{title}</h2>

      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-modal-action="close"
        aria-label="Close"
        onClick={() => {
          if (onClose) {
            onClose();
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <SVGImage path="/media/icons/x-mark.svg" className="svg-icon-1" />
      </div>
    </div>
  );
};