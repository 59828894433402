import { ModalDialogProvider } from '../../../../components';
import { QUERIES } from '../../../../constants';
import {
  ListViewProvider,
  RequestProvider,
  ResponseProvider,
} from '../../../../providers';
import { getProductionOrdersList } from '../../api';
import { PurchaseOrderList } from '../../purchases';

const queryKey = QUERIES.PRODUCTION_ORDERS;

const ProductionOrderList = () => <PurchaseOrderList module="production" />;

export const ProductionOrderListWrapper = () => (
  <RequestProvider>
    <ResponseProvider callback={getProductionOrdersList} queryKey={queryKey}>
      <ListViewProvider>
        <ModalDialogProvider>
          <ProductionOrderList />
        </ModalDialogProvider>
      </ListViewProvider>
    </ResponseProvider>
  </RequestProvider>
);
