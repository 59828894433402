import { isNotEmpty } from '@ialopezg/commonjs';
import { useQuery } from 'react-query';

import { getRoutingOrderByID } from '../../../api';
import { QUERIES } from '../../../../../constants';
import { useListView } from '../../../../../providers';
import { PurchaseOrder } from '../../../models';
import { RoutingDetailsForm } from './RoutingDetailsForm';

export const RoutingDetailsFormWrapper = () => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const enabledQuery = isNotEmpty(itemForUpdate);

  const { isLoading, data: order, error } = useQuery(
    [`${QUERIES.ROUTING_ORDERS}-id-${itemForUpdate}`, { id: itemForUpdate }],
    getRoutingOrderByID,
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (error) => {
        setItemForUpdate(undefined);
        console.log(error);
      },
    },
  );
  console.log(order);

  if (!itemForUpdate) {
    return (
      <RoutingDetailsForm
        isLoading={isLoading}
        purchaseOrder={{ routing: { id: undefined } } as PurchaseOrder}
      />
    );
  }

  if (!isLoading && !error && order) {
    return (
      <RoutingDetailsForm
        isLoading={isLoading}
        purchaseOrder={order}
      />
    );
  }

  return null;
};