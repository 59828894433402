import { FC, useState } from "react";
import { DownloadButton, SVGImage } from "../../../../components";
import { FilterButton } from "../../../../components/";
import { useRequest } from "../../../../providers";
import {
  CustomerSelectWidget,
  DivisionSelectWidget,
  WarehouseSelectWidget
} from "../../../third-parties";

type Props = {
  disabled?: boolean,
  onExportActionClick?: () => void,
  filterComponent?: JSX.Element,
  enableDownload?: boolean,
  enableFiltering?: boolean,
  module?: string,
}

export const DefaultToolbar: FC<Props> = (
  {
    filterComponent,
    disabled,
    onExportActionClick,
    enableDownload = false,
    enableFiltering = false,
    module = "sales"
  }
) => {
  const { updateState } = useRequest();
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState("");

  const onClearFilters = () => {
    setSelectedCustomer("");
    setSelectedWarehouse("");
    setSelectedDivision("");
    updateState({ filter: undefined });
  };

  const onApplyFilters = () => {
    updateState({
      filter: {
        customer: selectedCustomer,
        division: selectedDivision,
        warehouse: selectedWarehouse
      }
    });
  };

  return (
    <div className="d-flex justify-content-end">
      {enableDownload && (<DownloadButton />)}
      {enableFiltering && (
        <FilterButton
          actions={[
            <WarehouseSelectWidget
              module={module}
              onChange={(warehouse) => {
                setSelectedWarehouse(warehouse);
              }}
              selected={selectedWarehouse}
            />
          ]}
          title="Filter Sales Orders"
          disabled={disabled}
          onClearFilters={onClearFilters}
          onApplyFilters={onApplyFilters}
        >
          <CustomerSelectWidget
            module={module}
            onChange={(customer) => {
              setSelectedCustomer(customer);
            }}
            selected={selectedCustomer}
          />
          <DivisionSelectWidget
            module={module}
            onChange={(division) => {
              setSelectedDivision(division);
            }}
            // filterByCustomer={selectedCustomer}
            selected={selectedDivision}
          />
        </FilterButton>
      )}
      {filterComponent && filterComponent}

      {onExportActionClick && <button
        disabled={disabled}
        type="button"
        className="btn btn-color-primary me-3"
        onClick={onExportActionClick}
      >
        <SVGImage path="/media/icons/file-export.svg" className="svg-icon-2" />
        Export
      </button>}
    </div>
  );
};