import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from 'moment';
import { FC } from "react";
import { Button, FormGroup, InputGroup } from "react-bootstrap";
import * as Yup from "yup";
import { SVGImage } from "../../../../../components";

import { useListView, useResponse } from "../../../../../providers";
import { updateProductionOrder } from "../../../api";
import { PurchaseOrder } from "../../../models";

type Props = {
  order?: PurchaseOrder,
  showShipDate?: boolean,
  showNotes?: boolean,
  showMarkOrderAsReady?: boolean,
  isLoading?: boolean,
};

export const ProductionOrderForm: FC<Props> = (
  {
    order,
    showShipDate = true,
    showNotes = true,
    showMarkOrderAsReady = true,
    isLoading
  }
) => {
  const { setItemForUpdate } = useListView();
  const { reload } = useResponse();
  const productionOrders = order?.productionOrders;
  const productionInfo = productionOrders?.length ? productionOrders[0]?.productionInfo : undefined;

  const onCancelActionHandler = (withRefresh?: boolean) => {
    if (withRefresh) {
      reload();
    }

    setItemForUpdate(undefined);
  };

  return (
    <Formik
      validationSchema={Yup.object().shape({
        productionOrders: Yup.array().required().min(1),
        shipDate: Yup.date().nullable(),
        notes: Yup.string(),
        stage: Yup.string()
      })}
      initialValues={{
        productionOrders: productionOrders?.map((o) => o.id),
        shipDate: order?.shipDate
          ? new Date(order.shipDate).toISOString().slice(0, 10)
          : undefined,
        notes: productionInfo ? productionInfo?.notes : undefined,
        stage: order?.stage || undefined
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);

        try {
          if (values.shipDate) {
            values.shipDate = moment(values.shipDate).toISOString(true);
          }
          if (showMarkOrderAsReady && order?.stage === "WAREHOUSE") {
            values = { ...values, stage: "READY" };
          }
          await updateProductionOrder(order?.id, values);
          
          onCancelActionHandler(true);
        } catch (error: any) {
          if (error.response.status === 404) {
            setErrors({
              productionOrders: error.response.data.message
            });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
          isSubmitting,
          isValid,
          touched,
          values,
          errors
        }) => {
        return (
          <Form className="form">
            <div className="flex flex-column">
              <div className="p-1">
                <h3 className="font-size-lg text-dark font-weight-bold mb-6">1. Order Details:</h3>
                <div className="mb-5">
                  <div className="form-group row">
                    <label className="col-4 col-form-label text-end">Purchase Order:</label>
                    <div className="col-8 col-form-label">
                      <strong>{order?.id}</strong>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label text-end">Production Order(s):</label>
                    <div className="col-8 col-form-label">
                      <strong>
                        {productionOrders?.length
                          ? productionOrders[0]?.id
                          : `${productionOrders?.length} orders`
                        }
                      </strong>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-1">
                <h3 className="font-size-lg text-dark font-weight-bold mb-6">2. Warehouse Details:</h3>
                <div className="mb-3">
                  {errors && errors.productionOrders && (
                    <div className="text-danger">{errors.productionOrders}</div>
                  )}

                  <Field type="hidden" name="controls" value={values.productionOrders} />
                  {/* begin::shipDate */}
                  {showShipDate && (
                    <FormGroup className="row">
                      <label
                        className="col-lg-4 col-sm-12 col-form-label text-end text-sm-start"
                      >
                        Ship Date:
                      </label>
                      <div className="col-lg-8 col-sm-12">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>
                            <SVGImage path="/media/icons/calendar.svg" />
                          </InputGroup.Text>
                          <Field
                            type="date"
                            name="shipDate"
                            className="form-control"
                            placeholder="Choose the probable date"
                            value={values.shipDate}
                            pattern="\d{4}-\d{2}-\d{2}"
                          />
                        </InputGroup>
                        <ErrorMessage name="shipDate" />
                      </div>
                    </FormGroup>
                  )}
                  {/* end::shipDate */}

                  {/* begin::notes */}
                  {showNotes && (
                    <FormGroup className="row">
                      <label className="col-lg-4 col-sm-12 col-form-label text-end text-sm-start">Notes:</label>
                      <div className="col-lg-8 col-sm-12">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>
                            <SVGImage path="/media/icons/memo.svg" />
                          </InputGroup.Text>
                          <Field
                            as="textarea"
                            name="notes"
                            placeholder="Additional notes or instructions"
                            className="form-control"
                            value={values.notes}
                            aria-describedby="button-clear-notes"
                          />
                        </InputGroup>
                        <ErrorMessage name="notes" className="text-danger" />
                      </div>
                    </FormGroup>
                  )}
                  {/* end::notes */}

                  {showMarkOrderAsReady && (
                    <FormGroup className="row text-center">
                      <span className="danger">
                        You are about to mark as <strong>READY</strong> current order.
                      </span>

                      <span className="text-danger mb-3">
                        <strong>This action cannot be undone.</strong>
                      </span>

                      <span className="danger">
                        Are you sure?
                      </span>
                    </FormGroup>
                  )}
                </div>
              </div>
            </div>

            {/* begin::Actions */}
            <div className="text-center pt-15">
              <Button
                type="reset"
                variant="light"
                disabled={isSubmitting || isLoading}
                className="me-3"
                onClick={() => {
                  setItemForUpdate(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit" variant="primary"
                disabled={isSubmitting || isLoading || !isValid || !touched}
              >
                Update
              </Button>
            </div>
            {/* end::Actions */}
          </Form>
        );
      }}
    </Formik>
  );
};