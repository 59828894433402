import clsx from 'clsx';
import { ChangeEventHandler, FC } from 'react';

type Props = {
  checked?: boolean;
  type?: 'solid' | 'outline';
  size?: 'none' | 'small' | 'medium' | 'large';
  onChange?: ChangeEventHandler<HTMLInputElement>;
  readOnly?: boolean;
};

export const Switch: FC<Props> = (
  {
    type = 'solid',
    checked = false,
    size = 'none',
    onChange,
    readOnly = false },
) => {
  let checkboxSize;
  switch (size) {
    case 'large':
      checkboxSize = ' h-40px w-60px';
      break;
    case 'medium':
      checkboxSize = ' h-30px w-50px';
      break;
    case 'small':
      checkboxSize = ' h-20px w-30px';
      break;
    case 'none':
    default:
      checkboxSize = '';
      break;
  }

  return (
    <div
      className={clsx(
        'form-check form-switch form-check-custom',
        `form-check-${type}`,
      )}
    >
      <input
        className={`form-check-input${checkboxSize}`}
        type="checkbox"
        id="flexSwitchChecked"
        defaultChecked={checked}
        onChange={onChange}
        readOnly={readOnly}
      />
    </div>
  );
};
