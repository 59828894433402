import { useListView } from '../../../../../../providers';
import { SVGImage } from '../../../../../../components';

const EditModalHeader = () => {
  const { setItemForUpdate } = useListView();

  return (
    <div className="modal-header">
      {/* begin::Modal title */}
      <h2 className="fw-bolder">Add</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => setItemForUpdate(undefined)}
        style={{ cursor: 'pointer' }}
      >
        <SVGImage path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
      </div>
      {/* end::Close */}
    </div>
  );
};

export { EditModalHeader };
