import { FC, ReactNode } from 'react';
import { Box, Link, styled } from '@mui/material';

import { Avatar } from '../../../../../core';
import { getInitials } from '../../../../../core/helpers';
import IconWithTooltip from '../../../../../core/components/Icon/IconWithTooltip';
import { ThemeColor } from '../../../../../theme';
import { ID } from '../../../../models';

const LinkStyled = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1rem',
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

interface CustomerRequirementsWidgetProps {
  onlyEnabled?: boolean;
  routingRequired?: boolean;
  fineLineTicketsRequired?: boolean;
  fineLineTrackingNumberRequired?: boolean;
  printTicketsRequired?: boolean;
  uCCLabelsRequired?: boolean;
  palletLabelsRequired?: boolean;
  useIndividualSKUCartons?: boolean;
  deadlines?: boolean;
}

export const CustomerRequirementsWidget: FC<CustomerRequirementsWidgetProps> = (
  props
  //   {
  //   routingRequired,
  //   fineLineTicketsRequired,
  //   fineLineTrackingNumberRequired,
  //   printTicketsRequired,
  //   uCCLabelsRequired,
  //   palletLabelsRequired,
  //   useIndividualSKUCartons,
  //   deadlines,
  // }
) => {
  type ItemValue = keyof typeof props;

  const icons = [
    { id: 'routingRequired', icon: 'mdi:routes', title: 'Routing is required' },
    {
      id: 'fineLineTicketsRequired',
      icon: 'mdi:label-multiple',
      title: 'Use FINELINE labels is required',
    },
    {
      id: 'fineLineTrackingNumberRequired',
      icon: 'mdi:radar',
      title: 'FINELINE tracking is required',
    },
    {
      id: 'printTicketsRequired',
      icon: 'mdi:printer-pos',
      title: 'Print tickets is required',
    },
    {
      id: 'uCCLabelsRequired',
      icon: 'mdi:label',
      title: 'Use UCC labels is required',
    },
    {
      id: 'palletLabelsRequired',
      icon: 'mdi:shipping-pallet',
      title: 'Pallet tickets is required',
    },
    {
      id: 'useIndividualSKUCartons',
      icon: 'mdi:barcode',
      title: 'Use individual SKU per carton is required',
    },
    {
      id: 'deadlines',
      icon: 'mdi:calendar',
      title: 'Has deadline dates',
    },
  ];
  let items: string[];
  if (props.onlyEnabled) {
    items = Object.entries(props)
      .filter((entry) => entry[0] !== 'onlyEnabled')
      .filter((item) => !!item[1])
      .map((entry) => entry[0] as ItemValue);
  } else {
    items = Object.keys(props);
  }

  return (
    <Box display="flex" flexDirection="row">
      {items.map((item) => (
        <IconWithTooltip
          enabled={props[item as ItemValue]}
          title={icons.find((icon) => icon.id === item)?.title ?? ''}
          icon={icons.find((icon) => icon.id === item)?.icon ?? ''}
        />
      ))}
    </Box>
  );
};

const renderAvatar = (
  name: string,
  avatar?: string | ReactNode,
  color: ThemeColor = 'primary'
) => {
  if (avatar && typeof avatar === 'string') {
    return <Avatar src={avatar} sx={{ mr: 3, width: 34, height: 34 }} />;
  }

  if (avatar) {
    return (
      <Avatar skin="light" sx={{ mr: 3, width: 34, height: 34 }}>
        {avatar}
      </Avatar>
    );
  }

  return (
    <Avatar
      skin="light"
      color={color}
      sx={{ mr: 3, width: 34, height: 34, fontSize: '1rem' }}
    >
      {getInitials(String(name)).slice(0, 3)}
    </Avatar>
  );
};

interface CustomerCellProps extends CustomerRequirementsWidgetProps {
  avatar?: string | ReactNode;
  active?: boolean;
  id: ID;
  name?: string;
  onlyEnabled?: boolean;
  routingRequired?: boolean;
  fineLineTicketsRequired?: boolean;
  fineLineTrackingNumberRequired?: boolean;
  printTicketsRequired?: boolean;
  uCCLabelsRequired?: boolean;
  palletLabelsRequired?: boolean;
  useIndividualSKUCartons?: boolean;
  deadlines?: boolean;
}

export const CustomerCell: FC<CustomerCellProps> = ({
  active = false,
  avatar,
  id,
  name,
  ...rest
}) => {
  name = name ?? String(id);

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      {renderAvatar(name, avatar, active ? 'primary' : 'error')}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <LinkStyled href={`/third-parties/customers/${id}`}>{name}</LinkStyled>
        <CustomerRequirementsWidget {...rest} />
      </Box>
    </Box>
  );
};

export default CustomerCell;
