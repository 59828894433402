import clsx from 'clsx';
import { FC } from 'react';

type  Props = {
  active?: boolean,
  disabled?: boolean,
  isFirst?: boolean,
  isPrevious?: boolean,
  isNext?: boolean,
  isLast?: boolean,
  label?: string,
  page?: number,
  onClick?: (page: number) => void,
  useArrows?: boolean,
}

export const PageLink: FC<Props> = (
  {
    active = false,
    disabled = false,
    isFirst = false,
    isPrevious = false,
    isNext = false,
    isLast = false,
    label,
    page,
    onClick = (page: number) => console.log(page),
    useArrows = false,
  },
) => {
  if (useArrows || !label || isFirst) {
    label = '<<';
  }
  if (useArrows || !label || isPrevious) {
    label = '<';
  }
  if (useArrows || !label || isNext) {
    label = '>';
  }
  if (useArrows || !label || isLast) {
    label = '>>';
  }

  return (
    <li
      key={`page-${page}`}
      className={clsx('page-item', {
        active,
        disabled,
        previous: isPrevious,
        next: isNext,
      })}
    >
      <button
        className={clsx('page-link', {
          'page-text': isFirst || isPrevious || isNext || isLast,
          'me-5': isPrevious,
        })}
        onClick={() => onClick(page || 1)}
        style={{ cursor: 'pointer' }}
      >{String(label)}</button>
    </li>
  );
};
