import { hexToRGBA } from '../../../app/helpers';
import { OwnerStateThemeType } from './';

export const Tooltip = () => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }: OwnerStateThemeType) => ({
          borderRadius: 6,
          lineHeight: 1.455,
          backgroundColor: hexToRGBA((theme.palette as any).customColors.tooltipBg, 0.9),
        }),
        arrow: ({ theme }: OwnerStateThemeType) => ({
          color: hexToRGBA((theme.palette as any).customColors.tooltipBg, 0.9),
        }),
      },
    },
  };
};

export default Tooltip;
