import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import { QUERIES } from '../../../../constants';
import { AutocompleteWidget } from '../../../../components';
import { stringifyRequestQuery } from '../../../../helpers';
import { State } from '../../../../models';
import { getCustomers } from '../api';

type Props = {
  onChange?: (customer: string) => void;
  selected?: string;
  module?: string;
  title?: string;
};

export const CustomerSelectWidget: FC<Props> = ({
  module,
  onChange = (customer) => customer,
  selected,
  title = 'Customer',
}) => {
  const { data } = useQuery(QUERIES.CUSTOMERS, async () => {
    const query = stringifyRequestQuery({
      page: -1,
      filter: {
        [String(module)]: !!module ?? undefined,
        division: undefined,
        warehouse: undefined,
      },
    } as State);

    return getCustomers(query);
  });
  const options = useMemo(
    () =>
      (data?.data || []).map((c) => ({
        label: String(c?.name),
        value: String(c?.id),
      })),
    [data]
  );

  return (
    <AutocompleteWidget
      options={options}
      onChange={onChange}
      selected={selected}
      title={title}
    />
  );
};
