import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { Customer } from '../../../../../../models';
import ContentWrapper from '../../../../../../components/pane/ContentWrapper';
import { SupportAgent } from '@mui/icons-material';
import CustomerRequirementsForm from './CustomerRequirementsList';

interface CustomerFormProps {
  customer: Customer;
  onSubmitAction: () => void;
}

export const CustomerForm: FC<CustomerFormProps> = ({
  customer,
  onSubmitAction,
}) => {
  return (
    <Grid item xs={12}>
      <Card elevation={0}>
        <CardContent>
          <Grid container spacing={6}>
            <ContentWrapper title="General Information">
              <Grid item sm={5}>
                <Box display="flex" justifyContent="flex-end">
                  <Typography>Open Orders:</Typography>
                </Box>
              </Grid>
              <Grid item sm={7}>
                <Typography fontWeight="bold">
                  {customer?.openOrders}
                </Typography>
              </Grid>
              <Grid item sm={5}>
                <Box display="flex" justifyContent="flex-end">
                  <Typography>Picked Orders:</Typography>
                </Box>
              </Grid>
              <Grid item sm={7}>
                <Typography fontWeight="bold">
                  {customer?.pickedOrders ?? 0}
                </Typography>
              </Grid>
              <Grid item sm={5}>
                <Box display="flex" justifyContent="flex-end">
                  <Typography>Status:</Typography>
                </Box>
              </Grid>
              <Grid item sm={7} sx={{ display: 'flex', flexDirection: 'row' }}>
                <SupportAgent color={customer?.active ? 'primary' : 'error'} />
                <Typography color={`${customer?.active ? 'primary' : 'error'}.main`} fontWeight="bold">
                  {customer?.active ? 'Active' : 'Inactive'}
                </Typography>
              </Grid>
            </ContentWrapper>

            <Grid item xs={12}>
              <CustomerRequirementsForm customer={customer} onSubmitAction={onSubmitAction} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CustomerForm;
