import { lighten, useTheme } from "@mui/material";
import BaseAvatar from "@mui/material/Avatar";
import { forwardRef, Ref } from "react";

import useBgColor, { UseBgColorType } from '../../../app/hooks/useBgColor';
import { ThemeColor } from '../../../theme';
import AvatarProps from "./AvatarProps";

export const Avatar = forwardRef((props: AvatarProps, ref: Ref<any>) => {
  const { color, skin, src, sx } = props;
  const theme = useTheme();
  const bgColors: UseBgColorType = useBgColor();

  const getAvatarStyle = (skin: "filled" | "light" | "light-static" | undefined, color: ThemeColor) => {
    switch (skin) {
      case "light":
        return { ...bgColors[`${color}Light`] };
      case "light-static":
        return {
          color: bgColors[`${color}`].color,
          backgroundColor: lighten(theme.palette[color].main, 0.88)
        };
      case "filled":
      default:
        return { ...bgColors[`${color}Filled`] };
    }
  };

  const colors: UseBgColorType = {
    primary: getAvatarStyle(skin, "primary"),
    secondary: getAvatarStyle(skin, "secondary"),
    success: getAvatarStyle(skin, "success"),
    error: getAvatarStyle(skin, "error"),
    warning: getAvatarStyle(skin, "warning"),
    info: getAvatarStyle(skin, "info")
  };

  return (
    <BaseAvatar
      ref={ref}
      {...props}
      sx={!src && skin && color ? Object.assign(colors[color], sx) : sx}
    />
  );
});

export default Avatar;