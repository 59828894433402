import { Link, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../theme/helpers";
import { SVGImage } from "../../../../components";
import { MixedWidget8 } from "../../../third-parties/customers/components/CustomerWidget";

export const PurchaseOrderPage = () => {
  const { id } = useParams();

  return (
    <>
      {/* begin::Layout */}
      <div className="d-flex flex-column flex-xl-row">
        {/*  begin::Sidebar */}
        <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
          {/*  begin::Card */}
          <div className="card mb-5 mb-xl-8">
            {/*  begin::CardBody */}
            <div className="card-body pt-15">
              {/*  begin::Summary */}
              <div className="d-flex flex-center flex-column mb-5">
                {/*  begin::Avatar */}
                <div className="symbol symbol-100px symbol-circle mb-7">
                  <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt="Customer" />
                </div>
                {/*  end::Avatar */}

                {/*  begin::Name */}
                <Link
                  to="#"
                  className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1"
                >
                  Customer
                </Link>
                {/*  end::Name */}

                {/*  begin::Position */}
                <div className="fs-5 fw-semobold text-muted mb-6">{id}</div>
                {/*  end::Position */}

                {/*  begin::Info */}
                <div className="d-flex flex-wrap flex-center">
                  {/*  begin::Stats */}
                  <div
                    className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3"
                  >
                    <div className="fs-4 fw-bold text-gray-700">
                      <span className="w-75px">6,900</span>
                      <SVGImage path="/media/icons/arrow-up-from-line.svg" className="svg-icon svg-icon-3 svg-icon-success" />
                    </div>
                    <div className="fw-semobold text-muted">Open</div>
                  </div>
                  {/*  end::Stats */}

                  {/*  begin::Stats */}
                  <div
                    className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3"
                  >
                    <div className="fs-4 fw-bold text-gray-700">
                      <span className="w-75px">6,900</span>
                      <SVGImage path="/media/icons/arrow-down-from-line.svg" className="svg-icon svg-icon-3 svg-icon-success" />
                    </div>
                    <div className="fw-semobold text-muted">Pick</div>
                  </div>
                  {/*  end::Stats */}

                  {/*  begin::Stats */}
                  <div
                    className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3"
                  >
                    <div className="fs-4 fw-bold text-gray-700">
                      <span className="w-75px">6,900</span>
                      <SVGImage path="/media/icons/arrows-from-line.svg" className="svg-icon svg-icon-3 svg-icon-success" />
                    </div>
                    <div className="fw-semobold text-muted">Ship</div>
                  </div>
                  {/*  end::Stats */}
                </div>
                {/*  begin::Info */}
              </div>
              {/*  end::Summary */}

              {/*  begin::Details Toggle */}
              <div className="d-flex flex-stack fs-4 py-3">
                <div
                  className="fw-bold rotate collapsible"
                  data-bs-toggle="collapse"
                  // ref="#kt_customer_view_details"
                  role="button"
                  aria-expanded="false"
                  aria-controls="kt_customer_view_details"
                >
                  Details
                  <span className="ms-2 rotate-180">
                    <span className="svg-icon svg-icon-3">
                      <SVGImage path=" /media/icons/duotune/arrows/arr072.svg" />
                    </span>
                  </span>
                </div>

                <span
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  title="Edit customer details"
                >
                  <Link
                    to="#"
                    className="btn btn-sm btn-light-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_update_customer"
                  >
                    Edit
                  </Link>
                </span>
              </div>
              {/*  end::Details Toggle */}

              <div className="separator separator-dashed my-3"></div>

              {/*  begin::Content */}
              <div id="kt_customer_view_details" className="collapse show">
                <div className="py-5 fs-6">
                  {/*  begin::Badge */}
                  <div className="badge badge-light-info d-inline">Premium user</div>
                  {/*  end::Badge */}

                  {/*  begin::Details Item */}
                  <div className="fw-bold mt-5">Customer ID</div>
                  <div className="text-gray-600">ID-45453423</div>
                  {/*  end::Details Item */}
                  {/*  begin::Details Item */}
                  <div className="fw-bold mt-5">Billing Email</div>
                  <div className="text-gray-600">
                    <Link
                      to="#"
                      className="text-gray-600 text-hover-primary"
                    >info@keenthemes.com</Link>
                  </div>
                  {/*  end::Details Item */}
                  {/*  begin::Details Item */}
                  <div className="fw-bold mt-5">Billing Address</div>
                  <div className="text-gray-600">
                    101 Collin Street, <br />Melbourne 3000 VIC <br />Australia
                  </div>
                  {/*  end::Details Item */}
                  {/*  begin::Details Item */}
                  <div className="fw-bold mt-5">Language</div>
                  <div className="text-gray-600">English</div>
                  {/*  end::Details Item */}
                </div>
              </div>
              {/*  end::Content */}
            </div>
            {/*  end::CardBody */}
          </div>
          {/*  end::Sidebar */}
        </div>
        {/*  end::Sidebar */}

        {/*  begin::Content */}
        <div className="flex-lg-row-fluid ms-lg-15">
          {/*  begin::Tabs */}
          <ul
            className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semobold mb-8"
          >
            {/*  begin:::Tab item */}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary pb-4 active"
                data-bs-toggle="tab"
                href="src/app/modules/orders/purchases/components/index#kt_order_view_overview_tab"
              >Overview</a
              >
            </li>
            {/*  end:::Tab item */}
            {/*  begin:::Tab item */}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary pb-4"
                data-bs-toggle="tab"
                href="src/app/modules/orders/purchases/components/index#kt_order_view_controls_tab"
              >Orders</a
              >
            </li>
            {/*  end:::Tab item */}
            {/*  begin:::Tab item */}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary pb-4"
                data-bs-toggle="tab"
                href="src/app/modules/orders/purchases/components/index#kt_order_view_pick_tickets_tab"
              >Pick Tickets</a
              >
            </li>
            {/*  end:::Tab item */}
            {/*  begin:::Tab item */}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary pb-4"
                data-bs-toggle="tab"
                href="src/app/modules/orders/purchases/components/index#kt_order_view_shipping_tab"
              >Shipping</a
              >
            </li>
            {/*  end:::Tab item */}
            {/*  begin:::Tab item */}
            <li className="nav-item">
              <a
                className="nav-link text-active-primary pb-4"
                data-bs-toggle="tab"
                href="src/app/modules/orders/purchases/components/index#kt_order_view_routing_tab"
              >Routing</a
              >
            </li>
            {/*  end:::Tab item */}
            {/*  begin:::Tab item */}
            <li className="nav-item ms-auto">
              {/*  begin::Action menu */}
              <Link
                to="#"
                className="btn btn-primary ps-7"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                Actions
                <SVGImage
                  path="/media/icons/duotune/arrows/arr072.svg"
                  className="svg-icon svg-icon-2 me-0"
                />
              </Link>
              {/*<Dropdown3 />*/}
              {/*  end::Action menu */}
            </li>
            {/*  end:::Tab item */}
          </ul>
          {/*  end::Tabs */}

          {/*  begin::Tab Table */}
          <div className="tab-content" id="myTabContent">
            {/*  begin::Tab Pane */}
            <div
              className="tab-pane fade show active"
              id="kt_order_view_overview_tab"
              role="tabpanel"
            >
              <MixedWidget8 id="Customer" />
            </div>
            {/*  end::Tab Pane */}
            {/*  begin::Tab Pane */}
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="kt_order_view_controls_tab"
                role="tabpanel"
              >
                <MixedWidget8 id="Controls" />
              </div>
            </div>
            {/*  end::Tab Content */}
            {/*  begin::Tab Pane */}
            <div
              className="tab-pane fade"
              id="kt_order_view_pick_tickets_tab"
              role="tabpanel"
            >
              <MixedWidget8 id="Pick Tickets" />
            </div>
            {/*  end::Tab Pane */}
            {/*  begin::Tab Pane */}
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="kt_order_view_shipping_tab"
                role="tabpanel"
              >
                <MixedWidget8 id="Shipping" />
              </div>
            </div>
            {/*  end::Tab Pane */}
            {/*  begin::Tab Pane */}
            <div
              className="tab-pane fade"
              id="kt_order_view_routing_tab"
              role="tabpanel"
            >
              <MixedWidget8 id="Routing" />
            </div>
            {/*  end::Tab Pane */}
          </div>
        </div>
        {/*  end::Content */}
      </div>
      {/* end::Layout */}
    </>
  );
};

export default PurchaseOrderPage;
