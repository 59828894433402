import { isUndefined } from '@ialopezg/commonjs';
import { Component } from 'react';

import './DualListBox.scss';
import { ListBox, ListOption } from '../ListBox';

interface DualListBoxProps {
  disabled?: boolean;
  name?: string,
  onChange?: Function,
  options: Array<ListOption>;
  selected?: Array<string>;
}
interface DualListBoxState {
  selected: Array<string>;
  options: Array<ListOption>;
}

const isEqual = <T, U extends T>(a: T, b: U) => JSON.stringify(a) === JSON.stringify(b);

export class DualListBox extends Component<DualListBoxProps, DualListBoxState> {
  constructor(props: DualListBoxProps) {
    super(props);

    this.state = {
      selected: props?.selected || [],
      options: props?.options || [],
    };

    this.onSelect = this.onSelect.bind(this);
    this.onUnselect = this.onUnselect.bind(this);
  }

  componentDidUpdate(nextProps: DualListBoxProps, prevState: DualListBoxState): void {
    const { selected, options } = this.props;

    if (!isEqual(selected, nextProps.selected) || !isEqual(options, nextProps.options)) {
      this.setState({
        selected: nextProps.selected || [],
        options: nextProps.options || [],
      });
    }
  }

  onSelect(selection: string[]): void {
    const { onChange } = this.props;
    const selected = [...this.state.selected, ...selection];
    console.log('onSelect', selected, selection)

    if (!isUndefined(onChange)) {
      this.setState({ selected }, () => (onChange as Function)(selected));
    } else {
      this.setState({ selected });
    }
  }

  onUnselect(selection: string[]): void {
    const { onChange } = this.props;
    const { selected: currentSelection } = this.state;
    const selected = currentSelection.filter((s) => selection.indexOf(s) < 0);

    if (!isUndefined(onChange)) {
      this.setState({ selected }, () => (onChange as Function)(selected));
    } else {
      this.setState({ selected });
    }
  }

  render() {
    const { disabled } = this.props;
    const { selected, options } = this.state;
    const availableItems = options
      .filter((option) => selected.indexOf(String(option.value)) < 0);
    const selectedItems = options
      .filter((option) => selected.indexOf(String(option.value)) !== -1);

    return (
      <div className="wrapper">
        <ListBox
          disabled={disabled}
          options={availableItems}
          onTransfer={this.onSelect}
          transferActionButton='Select'
        />

        <ListBox
          disabled={disabled}
          options={selectedItems}
          onTransfer={this.onUnselect}
          transferActionButton='Unselect'
        />
      </div>
    );
  }
}