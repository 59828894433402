import { isNotEmpty } from '@ialopezg/commonjs';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { Navigate, Route, Routes, Outlet, useParams } from 'react-router-dom';

import { QUERIES } from '../../../../../constants';
import { getCustomerById } from '../../api';
import { CustomerOptionOverview } from './options/CustomerOptionOverview';
import { CustomerOptionsListWrapper } from './options/CustomerOptionsList';
import { PageLink, PageTitle } from '../../../../../../core/components';
import { CircularSpinner, ModulePageHeader } from '../../../../../components';
import { Error404 } from '../../../../errors/components/Error404';

export const CustomerPage: FC = () => {
  const { id } = useParams();
  const enabledQuery: boolean = isNotEmpty(id);
  const {
    data: customer,
    status,
  } = useQuery([`${QUERIES.CUSTOMERS}-id-${id}`], () => getCustomerById(id), { enabled: enabledQuery });
  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Customers',
      path: '/third-parties/customers/list',
      isSeparator: false,
      isActive: false,
    },
    {
      title: `${id}`,
      path: `/third-parties/customers/${customer?.id}/overview`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  const error = (customer as any)?.statusCode === 404;
  const errorTile = (customer as any)?.error;
  const errorMessage = (customer as any)?.message;

  return (
    <>
      {status === 'error' && <p>Error fetching data</p>}
      {status === 'loading' && <CircularSpinner />}
      {(status === 'success' && error) && (
        <Error404
          title={`Customer ${errorTile}`}
          description={errorMessage}
          buttonProps={{
            title: 'Return Customer List',
            url: '/third-parties/customers/list',
          }}
        />
      )}
      {(status === 'success' && !error) && (
        <Routes>
          <Route
            element={
              <>
                <ModulePageHeader
                  title={String(customer?.id)}
                  legends={[
                    { imageUrl: '/media/icons/id-card.svg', description: String(customer?.name) }
                  ]}
                  tabs={[
                    {
                      label: 'Overview',
                      link: `/third-parties/customers/${customer?.id}/overview`,
                      icon: '/media/icons/chart-line-up.svg',
                    },
                    {
                      label: 'Requirements',
                      link: `/third-parties/customers/${customer?.id}/options`,
                      icon: '/media/icons/list.svg',
                    },
                  ]}
                  status={customer?.active ? 'active' : 'inactive'}
                  staticsIcons={[]}
                />
                <Outlet />
              </>
            }
          >
            <Route
              path="overview"
              element={
                <>
                  <PageTitle breadcrumbs={breadcrumbs}>Overview</PageTitle>
                  <CustomerOptionOverview customer={customer || {}} />
                </>
              }
            />
            <Route
              path="options"
              element={
                <>
                  <PageTitle breadcrumbs={breadcrumbs}>Requirements</PageTitle>
                  <CustomerOptionsListWrapper customer={customer || {}} />
                </>
              }
            />
            <Route index element={<Navigate to={`/third-parties/customers/${id}/overview`} />} />
          </Route>
        </Routes>
      )}</>
  );
};
