import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { DataTableOptions, initialDataTableOptions } from './Prototypes';

type DataTableContextProps = {
  state: DataTableOptions,
  updateState: (options: DataTableOptions) => void,
};

const initialContext: DataTableContextProps = {
  state: initialDataTableOptions,
  updateState: (_: DataTableOptions) => {},
};

const DataTableContext = createContext<DataTableContextProps>(initialContext);

export const DataTableProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<DataTableOptions>(initialContext.state);

  const updateState = (updates: Partial<DataTableOptions>) => {
    let updatedState: DataTableOptions = { ...state, ...updates } as DataTableOptions;

    setState(updatedState);
  };

  return (
    <DataTableContext.Provider
      value={{ state, updateState }}
    >
      {children}
    </DataTableContext.Provider>
  );
};

export const useDataTable = () => useContext(DataTableContext);

export const useDataTableOptions = () => {
  const { state } = useDataTable();

  return state;
};

export const useDataTableData = () => {
  const { data } = useDataTableOptions();

  return data;
};

export const useDataTableColumns = () => {
  const { columns } = useDataTableData();

  return columns;
};

export const useDataTableRows = () => {
  const { rows } = useDataTableData();

  return rows;
};

export const useDataTableDimensions = () => {
  const { dimensions } = useDataTableOptions();

  return dimensions;
};
