import Button from '@mui/material/Button';
import { TransitionProps } from '@mui/material/transitions';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Slide,
  TextField,
  Tooltip,
  Zoom,
} from '@mui/material';
import { FC, forwardRef, ReactElement, Ref, useState } from 'react';
import clsx from 'clsx';

import './DownloadButton.styles.css';
import Icon from '../../../core/components/Icon';
import { ThemeColor } from '../../../theme';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return (
    <Slide direction="up" ref={ref} {...props} children={props.children} />
  );
});

type Props = {
  color?: ThemeColor;
  disabled?: boolean;
  title?: string;
  description?: string;
  fileName?: string;
  fileType?: string;
  enabledSelectedRow?: boolean;
  enabledCurrentPage?: boolean;
  enabledAllPages?: boolean;
  onDownloadAction?: (fileName: string) => void;
};

export const DownloadButton: FC<Props> = ({
  color = 'primary',
  title = 'Download',
  description = 'Download data from database in %s format',
  disabled = false,
  fileName = 'download',
  fileType = 'csv',
  enabledSelectedRow,
  enabledCurrentPage,
  enabledAllPages,
  onDownloadAction,
}) => {
  const [open, setOpen] = useState(false);
  const [downloadFileType, setDownloadFileType] = useState(fileType);
  const [downloadFileName, setDownloadFileName] = useState(fileName);

  const toggleDialog = (value: boolean) => {
    setDownloadFileName(fileName);
    setOpen(value);
  };

  const onDownload = () => {
    setOpen(false);

    onDownloadAction?.(`${downloadFileName}.${downloadFileType}`);
  };

  return (
    <>
      <Tooltip title={disabled ? '' : title} TransitionComponent={Zoom} arrow>
        <span>
          <IconButton
            className={clsx({ 'disabled-icon': disabled })}
            disabled={disabled}
            onClick={() => toggleDialog(true)}
            color={color}
          >
            <Icon icon="mdi:cloud-download-outline" />
          </IconButton>
        </span>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => toggleDialog(false)}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <>
            <Icon icon="mdi:cloud-download" color="action" /> {title}
          </>
          <IconButton
            aria-label="Close"
            className="close-icon"
            onClick={() => toggleDialog(false)}
          >
            <Icon icon="mdi-close" />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {description.replace('%s', downloadFileType.toUpperCase())}
          </DialogContentText>
          <br />

          <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <TextField
              variant="standard"
              label="File name"
              value={downloadFileName}
              helperText="Name of the file to be downloaded"
              error={!(downloadFileName.split(' ').join('').length > 0)}
              onChange={(e) => setDownloadFileName(e.target.value)}
              onFocus={(e) => e.target.select()}
              sx={{ m: 1, width: '25ch' }}
              autoFocus
            />
            <TextField
              id="standard-select"
              select
              defaultValue={downloadFileType}
              label="File format"
              variant="standard"
              onChange={(e) => setDownloadFileType(e.target.value)}
              helperText="Please select a file format"
              sx={{ m: 1, width: '25ch' }}
            >
              <MenuItem value="csv">.csv</MenuItem>
              <MenuItem value="json">.json</MenuItem>
            </TextField>
          </Box>
        </DialogContent>

        <Divider light variant="middle" />

        <DialogActions>
          <Button
            className="current-page"
            disabled={!enabledSelectedRow}
            onClick={onDownload}
            variant="contained"
            size="small"
            color="primary"
          >
            Current Selection
          </Button>

          <Button
            className="current-page"
            disabled={!enabledCurrentPage}
            onClick={onDownload}
            variant="contained"
            size="small"
            color="primary"
          >
            Current Page
          </Button>

            <Button
              className="all-pages"
              disabled={!enabledAllPages}
              onClick={onDownload}
              variant="contained"
              size="small"
              color="primary"
            >
              All pages
            </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DownloadButton;
