import { StatusBadge } from './StatusBadge';
import { FC } from 'react';

type Props = {
  title?: string;
};

export const ConfirmedStatus: FC<Props> = (
  { title = 'Confirmed' },
) => (<StatusBadge status="success" title={title.toUpperCase()} />);
