import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { PageLink, PageTitle } from '../../../../core/components';
import { RoutingOrderListWrapper } from './components';

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

export const RoutingPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Routing Orders List</PageTitle>
              <RoutingOrderListWrapper />
            </>
          }
      />
      <Route index element={<Navigate to="/orders/routing/list"/>}/>
    </Route>
  </Routes>
);

export default RoutingPage;
