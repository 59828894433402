import { isNotEmpty } from '@ialopezg/commonjs';
import { useQuery } from 'react-query';

import { useListView } from '../../../../../../providers';
import { getCustomerById } from '../../../api';
import { EditModalForm } from './EditModalForm';
import { QUERIES } from '../../../../../../../theme/helpers';

export const EditModalFormWrapper = () => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const enabledQuery: boolean = isNotEmpty(itemForUpdate);
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.CUSTOMERS}-user-${itemForUpdate}`,
    () => {
      return getCustomerById(itemForUpdate);
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemForUpdate(undefined);
        console.error(err);
      },
    },
  );

  if (!itemForUpdate) {
    return <EditModalForm isUserLoading={isLoading} customer={{ id: undefined }} />;
  }

  if (!isLoading && !error && user) {
    return <EditModalForm isUserLoading={isLoading} customer={user} />;
  }

  return null;
};
