import { FC, useMemo } from 'react';

import { ID } from '../../../models';
import { useListView } from '../../../providers';

type Props = {
  id: ID;
  relatedTableId: string;
}

export const SelectionCell: FC<Props> = ({ id, relatedTableId }) => {
  const { selected, onSelect } = useListView();
  const isSelected = useMemo(() => selected.includes(id), [id, selected]);

  return (
    <div className="form-check form-check-custom form-check-solid">
      <input
        className="form-check-input"
        type="checkbox"
        data-kt-check={isSelected}
        data-kt-check-target={`#${relatedTableId} .form-check-input`}
        checked={isSelected}
        onChange={() => onSelect(id)}
      />
    </div>
  );
};
