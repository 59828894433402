import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import Icon from '../../../../../../core/components/Icon';
import CardSnippet from '../../../../../components/Cards/snippet';
import List from '../../../../../components/list/List';
import { ID } from '../../../../../models';
import { useAuth } from '../../../../auth';
import { addPurchaseOrderNote, getPurchaseOrderNotes } from '../../../api';
import { PurchaseOrderNote } from '../../../models';

interface NoteFormProp {
  children: ReactNode;
  disabled: boolean;
  onCloseAction: () => void;
  onSubmitAction: () => void;
}

const NoteForm: FC<NoteFormProp> = ({
  children,
  disabled,
  onCloseAction,
  onSubmitAction,
}) => {
  return (
    <form onSubmit={onSubmitAction}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {children}
        </Grid>

        <Grid container spacing={6} sx={{ paddingLeft: 5 }}>
          <Grid item xs={6}>
            <Button
              disabled={disabled}
              type="submit"
              fullWidth
              size="large"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disabled}
              fullWidth
              color="error"
              onClick={onCloseAction}
              size="large"
              startIcon={<Icon icon="mdi:close-circle" />}
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

interface PurchaseOrderFormNotesProps {
  purchaseOrderID: ID;
}

export const PurchaseOrderFormNotes: FC<PurchaseOrderFormNotesProps> = ({
  purchaseOrderID,
}) => {
  const { currentUser } = useAuth();
  const schema = yup.object().shape({
    description: yup.string().required(),
  });

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { description: '' },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { data: notes, refetch } = useQuery(
    `purchase-order-${purchaseOrderID}-notes`,
    async () => {
      const data = await getPurchaseOrderNotes(purchaseOrderID);
      if (!data) {
        return [];
      }

      return data;
    }
  );
  const [action, setAction] = useState<'add' | 'delete' | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmitAction = async (data: PurchaseOrderNote) => {
    setIsSubmitting(true);

    try {
      await addPurchaseOrderNote(purchaseOrderID, data);

      setAction(undefined);
      setValue('description', '');
      reset();

      await refetch();
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CardSnippet title="Notes">
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          onChange={(_, newValue) => setAction(newValue)}
          sx={{ visibility: action !== undefined ? 'hidden' : 'visible' }}
          value={action}
        >
          <ToggleButton disabled={isSubmitting} value="add">
            <Icon color="blue" icon="mdi:plus" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <List disablePadding>
        {action !== undefined ? (
          action === 'add' ? (
            <NoteForm
              disabled={isSubmitting}
              onCloseAction={() => setAction(undefined)}
              onSubmitAction={handleSubmit(onSubmitAction)}
            >
              <FormControl fullWidth sx={{ mt: 6, mb: 6 }}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      error={Boolean(errors.description)}
                      fullWidth
                      label="Additional notes"
                      minRows={3}
                      multiline
                      onChange={onChange}
                      placeholder="Notes to WH team"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          alignItems: 'baseline',
                        },
                      }}
                      value={value}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon icon="mdi:message-outline" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                {errors.description && (
                  <FormHelperText sx={{ color: 'error.main' }}>
                    {String(errors.description.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </NoteForm>
          ) : (
            <>Delete Action</>
          )
        ) : (
          <>
            {!notes?.length ? (
              <ListItem>
                <ListItemIcon>
                  <SpeakerNotesOffIcon />
                </ListItemIcon>
                <ListItemText primary="No notes" />
              </ListItem>
            ) : (
              notes.map((note) => {
                return (
                  <ListItem key={`file-${note?.id}`}>
                    <ListItemButton>
                      {note?.createdBy?.id === currentUser?.id && (
                        <ListItemIcon>
                          <SpeakerNotesIcon color="primary" />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        id="checkbox-list-label-0"
                        primary={note?.description}
                        secondary={`Create by ${
                          note?.createdBy?.id === currentUser?.id
                            ? ' you'
                            : note?.createdBy?.displayName
                        }${
                          note?.createdAt
                            ? ` on ${new Date(
                                note?.createdAt
                              ).toLocaleDateString('default')}`
                            : ''
                        }`}
                      />
                      {note?.createdBy?.id !== currentUser?.id && (
                        <ListItemIcon>
                          <SpeakerNotesIcon color="secondary" />
                        </ListItemIcon>
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })
            )}
          </>
        )}
      </List>
    </CardSnippet>
  );
};

export default PurchaseOrderFormNotes;
