import { Tooltip } from "@mui/material";
import { forwardRef, LegacyRef } from "react";
import Icon from "./index";

interface IconWithArrowTooltipProps {
    icon: string;
    title: string;
    enabled?: boolean;
  }
  export const IconWithTooltip = forwardRef(function IconWithArrowTooltip(props: IconWithArrowTooltipProps, ref: LegacyRef<HTMLDivElement> | undefined) {
    const { enabled, icon, title } = props;
  
    return (
      <Tooltip arrow title={enabled ? title : ''}>
        <div ref={ref}>
          <Icon icon={icon} color={enabled ? 'black' : 'lightgray'} />
        </div>
      </Tooltip>
    );
  });

  export default IconWithTooltip;