import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { CssBaseline, Theme, ThemeProvider } from "@mui/material";
import { FC, useEffect } from "react";

import { DataTableContainer } from "./DataTableContainer";
import { useDataTable } from "./DataTableProvider";
import { DataTableOptions } from "./Prototypes";
import { DefaultTheme } from "./DefaultTheme";

type Props = {
  options: DataTableOptions,
  theme?: Theme,
  header?: Element,
  body?: Element,
  footer?: Element,
};

export const DataTableInitializer: FC<Props> = (
  {
    theme = DefaultTheme,
    options
  }) => {
  const { updateState } = useDataTable();

  useEffect(() => {
    const updateOptions = () => {
      updateState(options);
    };

    updateOptions();

    return () => undefined;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DataTableContainer />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
