import { FC } from 'react';

type Props = {
  colSpan?: number;
  title?: string;
};

export const EmptyRow: FC<Props> = ({ colSpan, title }) => (
  <tr>
    <td colSpan={colSpan}>
      <div className="d-flex text-center w-100 align-content-center justify-content-center">
        {title || 'No matching records found!'}
      </div>
    </td>
  </tr>
);
