import { FC } from 'react';

interface Props {
  value: Date;
  format?: string;
}

export const DateTimeCell: FC<Props> = ({ value, format = 'en-US' }) => (
  <div className="badge badge-light fw-bolder">
    {value ? new Date(value).toLocaleDateString(format) : ''}
  </div>
);
