import { FilterList } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { FC } from 'react';

import { DownloadButton } from '../../../../../components';
import { DataGridInstance } from '@ialopezg/datagrid';

interface ToolbarActionsProps<D extends {} = {}> {
  module?: 'sales' | 'picked' | 'production' | 'routed';
  table: DataGridInstance<D>;
}

export const ToolbarActions: FC<ToolbarActionsProps> = ({ table }) => {
  return (
    <Box>
      <DownloadButton
        fileName={`${module}-orders`}
        enabledSelectedRow={table.selectedFlatRows.length > 1}
        enabledCurrentPage={table.page.length > 0}
        enabledAllPages
        onDownloadAction={() => console.log('onDownloadAction')}
      />
      <IconButton>
        <FilterList />
      </IconButton>
    </Box>
  );
};

export default ToolbarActions;
