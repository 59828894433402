import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { PageLink, PageTitle } from '../../../../core';
import { SalesOrderListWrapper } from './components';

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
];

export const SalesOrdersPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path="list"
        element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Sales Orders List</PageTitle>
            <SalesOrderListWrapper />
          </>
        }
      />
      <Route index element={<Navigate to="/orders/sales/list" />} />
    </Route>
  </Routes>
);

export default SalesOrdersPage;
