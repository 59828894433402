import { TableColumn } from 'react-data-table-component';
import { ActionsCell } from '../../../../components';

import { OrderColumns } from '../../components';
import { SalesOrder } from '../models';

export const SalesOrderTableColumns: TableColumn<SalesOrder>[] = [
  ...OrderColumns,
  {
    name: 'ACTIONS',
    selector: (row) => String(row.id),
    cell: (row) => {
      return (
        <ActionsCell
          id={String(row.id)}
          type="horizontal"
          enableApprove={row.canApproveToWarehouse}
          enableView={true}
          viewAction={`/orders/sales/${row.id}`}
        />
      );
    },
  },
];
