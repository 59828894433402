import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { DateTimeCell, NumberCell, TileCell } from '../../../../components';
import { OrderOptionsCell } from '../../components';
import { Order } from "../../models/OrderModel";
import { OrderStatus } from '../../order/OrderStatus';

export const RoutingOrdersTableColumns: TableColumn<Order>[] = [
  {
    name: 'PO',
    selector: (order) => String(order?.id),
    sortable: true,
    cell: (row) => {
      return (
        <TileCell
          title={<>
            <span className="d-flex flex-row justify-content-center">
              <div className="align-self-center me-2">
                <OrderStatus
                  status={row.status === 'OPEN' && row.stage === 'READY'
                    ? row.stage
                    : row.stage !== 'SHIPPING'
                      ? row.stage
                      : row.status}
                />
              </div>
              {' '}
              {row?.id}
            </span>
          </>}
          url={`/purchases/${row.id}`}
        >
          <OrderOptionsCell objectValue={row} />
        </TileCell>
      );
    },
  },
  {
    id: 'pick-ticket',
    name: 'PT #',
    compact: true,
    cell: ({ pickTicket }) => pickTicket
      ? <Link to={`/production/pick-ticket/${pickTicket?.id}`}>{pickTicket?.id}</Link>
      : '',
  },
  {
    name: 'DIV',
    selector: ({ division }) => String(division),
    sortable: true,
    compact: true,
    center: true,
    cell: ({ division }) => (
      <Link to={`/divisions/${division?.id}`}>{division?.id}</Link>
    ),
  },
  {
    name: 'S. DATE',
    selector: (row) => String(row.startDate),
    sortable: true,
    compact: true,
    right: true,
    cell: ({ startDate }) => startDate
      ? (<DateTimeCell value={startDate} />)
      : ''
  },
  {
    name: 'C. DATE',
    selector: ({ cancelDate }) => String(cancelDate),
    sortable: true,
    compact: true,
    right: true,
    cell: ({ cancelDate }) => cancelDate
      ? (<DateTimeCell value={cancelDate} />)
      : ''
  },
  {
    name: 'SH. DATE',
    button: true,
    ignoreRowClick: true,
    compact: true,
    cell: ({ shipDate }) => shipDate
      ? (<DateTimeCell value={shipDate} />)
      : '',
  },
  {
    name: 'T. QTY',
    selector: ({ orderQuantity }) => String(orderQuantity),
    sortable: true,
    compact: true,
    right: true,
    cell: ({ orderQuantity }) => (
      <NumberCell value={Number(orderQuantity)} />
    ),
  },
  {
    name: 'SH. QTY',
    selector: ({ shippedQuantity }) => String(shippedQuantity),
    sortable: true,
    right: true,
    cell: ({ shippedQuantity }) => (
      <NumberCell value={Number(shippedQuantity)} />
    )
  },
  {
    name: 'ACTIONS',
    selector: (row) => String(row.id),
    compact: true,
    style: {
      marginRight: '5px'
    },
    cell: (_row) => {
      return (
        <div className="d-flex justify-content-end flex-shrink-0">
          {/*{row.stage !== 'READY' && row.shipDate && (<button*/}
          {/*  onClick={() => markOrderReady(row.id)}*/}
          {/*  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"*/}
          {/*  title="Mark as ready"*/}
          {/*>*/}
          {/*  <SVGImage path="/media/icons/check.svg" className="svg-icon-3" />*/}
          {/*</button>)}*/}
          {/*<button*/}
          {/*  onClick={() => {}}*/}
          {/*  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"*/}
          {/*  title="View order"*/}
          {/*>*/}
          {/*  <SVGImage path="/media/icons/eye.svg" className="svg-icon-3" />*/}
          {/*</button>*/}
          {/*{row?.requireRouting && (<button*/}
          {/*  onClick={() => setRoutingDetails(row.id)}*/}
          {/*  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"*/}
          {/*  title="Edit routing details"*/}
          {/*>*/}
          {/*  <SVGImage path="/media/icons/route.svg" className="svg-icon-3" />*/}
          {/*</button>)}*/}
          {/*{row.notes && (<button*/}
          {/*  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"*/}
          {/*  onClick={() => showPurchaseOrderNotes(row.id)}*/}
          {/*  title="Edit order notes"*/}
          {/*>*/}
            {/*<SVGImage path="/media/icons/memo.svg" className="svg-icon-3" />*/}
          {/*</button>)}*/}
        </div>
      );
    }
  }
];
