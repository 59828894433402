import { FC, ReactNode } from 'react';
import { WithChildren } from "../../../core";
import { SVGImage } from '../image';

type Props = {
  icon: ReactNode,
  onClose: () => void,
  size?: number,
  title: string,
};

export const FormDialog: FC<Props & WithChildren> = (
  {
    children,
    icon,
    onClose,
    size = 650,
    title,
  },
) => {
  return (
    <>
      <div
        className="modal fade show d-block"
        id="user_form"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className={`modal-dialog modal-dialog-centered mw-${size}px`}>
          <div className="modal-content">
            <div className="modal-header px-3 py-2 bg-gray-300">
              <h2 className="fw-bolder">
                {icon}
                {' '}
                {title}
              </h2>

              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-users-modal-action="close"
                onClick={() => onClose()}
                style={{ cursor: 'pointer' }}
              >
                <SVGImage
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon-1 text-black"
                />
              </div>
            </div>

            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {children}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-backdrop fade show"></div>
    </>
  );
};
