import { isUndefined } from '@ialopezg/commonjs';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  BoxProps,
  capitalize,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useQuery } from 'react-query';

import { useListView } from '../../../../../providers';
import { getPurchaseOrderByID } from '../../../api';
import { PurchaseOrder } from '../../../models';
import PurchaseOrderForm from './PurchaseOrderForm';
import { PurchaseOrderFormActionType } from './PurchaseOrderFormActionType';

const Header = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 4),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
}));

interface PurchaseOrderFormWrapperProps {
  action?: PurchaseOrderFormActionType;
  module?: 'sales' | 'picked' | 'production' | 'routing';
  open: boolean;
  onCloseAction: () => void;
}

export const PurchaseOrderFormDrawer: FC<PurchaseOrderFormWrapperProps> = ({
  action = 'view',
  module,
  open,
  onCloseAction,
}) => {
  const { itemForUpdate } = useListView();
  const enabled = !isUndefined(itemForUpdate);
  const title = itemForUpdate
    ? `Purchase Order ${itemForUpdate}`
    : action === 'filter' ? `Advanced options` : capitalize(action);

  const { data: purchaseOrder, isLoading } = useQuery(
    `purchase-order-id-${itemForUpdate}`,
    () => getPurchaseOrderByID(itemForUpdate),
    { enabled }
  );

  return (
    <Drawer
      anchor="right"
      onClose={onCloseAction}
      open={open}
      sx={{ '& .MuiDrawer-paper': { width: { xs: 300, sm: 400 } } }}
      variant="temporary"
      ModalProps={{ keepMounted: true }}
    >
      <Header>
        <Typography variant="h6">{title}</Typography>
        <IconButton
          size="small"
          onClick={onCloseAction}
          sx={{ color: 'text.primary' }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Header>

      <Grid container spacing={6}>
        {isLoading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100%"
            minWidth="100%"
            paddingTop="50%"
          >
            <Typography fontWeight="bold" sx={{ paddingBottom: 10 }}>
              Please, wait data is loading!
            </Typography>
            <CircularProgress />
          </Box>
        ) : (
          <PurchaseOrderForm
            action={action}
            module={module}
            onCloseAction={onCloseAction}
            purchaseOrder={purchaseOrder as PurchaseOrder}
          />
        )}
      </Grid>
    </Drawer>
  );
};

export default PurchaseOrderFormDrawer;
