import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import theme from '../../config/theme';

import PageSpecificSettings from './PageSpecificSettings';
import Settings from './Settings';

const initialSettings: Settings = {
  themeColor: 'primary',
  mode: theme.mode,
  skin: theme.skin,
  footer: theme.footer,
  layout: theme.layout,
  lastLayout: theme.layout,
  direction: theme.direction,
  navHidden: theme.navHidden,
  appBarBlur: theme.appBarBlur,
  navCollapsed: theme.navCollapsed,
  contentWidth: theme.contentWidth,
  toastPosition: theme.toastPosition,
  verticalNavToggleType: theme.verticalNavToggleType,
  appBar: theme.layout === 'horizontal' && theme.appBar === 'hidden'
    ? 'fixed'
    : theme.appBar,
};

const staticSettings = {
  appBar: initialSettings.appBar,
  footer: initialSettings.footer,
  layout: initialSettings.layout,
  navHidden: initialSettings.navHidden,
  lastLayout: initialSettings.lastLayout,
  toastPosition: initialSettings.toastPosition,
};

const restoreSettings = (): Settings | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem('settings');

    if (storedData) {
      settings = { ...JSON.parse(storedData), ...staticSettings };
    } else {
      settings = initialSettings;
    }
  } catch (err) {
    console.error(err);
  }

  return settings;
};

const storeSettings = (settings: Settings) => {
  const initSettings = Object.assign({}, settings);

  delete initSettings.appBar;
  delete initSettings.footer;
  delete initSettings.layout;
  delete initSettings.navHidden;
  delete initSettings.lastLayout;
  delete initSettings.toastPosition;

  window.localStorage.setItem('settings', JSON.stringify(initSettings));
};

export interface SettingsContextProps {
  settings: Settings;
  save?: (updates: Settings) => void;
}

export const SettingsContext = createContext<SettingsContextProps>({
  save: () => {},
  settings: initialSettings,
});

export interface SettingsProviderProps {
  children: ReactNode,
  pageSettings?: PageSpecificSettings | void;
}

export const SettingsProvider: FC<SettingsProviderProps> = (
  {
    children,
    pageSettings,
  },
) => {
  const [settings, setSettings] = useState<Settings>({ ...initialSettings });

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings({ ...restoredSettings });
    }
    if (pageSettings) {
      setSettings({ ...settings, ...pageSettings });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings]);

  useEffect(() => {
    if (settings.layout === 'horizontal' && settings.mode === 'semi-dark') {
      saveSettings({ ...settings, mode: 'light' });
    }
    if (settings.layout === 'horizontal' && settings.appBar === 'hidden') {
      saveSettings({ ...settings, appBar: 'fixed' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.layout]);

  const saveSettings = (updatedSettings: Settings) => {
    storeSettings(updatedSettings);
    setSettings(updatedSettings);
  };

  return (
    <SettingsContext.Provider value={{ settings, save: saveSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export const useSettings = () => useContext(SettingsContext);
