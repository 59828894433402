/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

export function CustomerOverview() {
  const [year, setYear] = useState(new Date().getFullYear().toString());
  console.log(year);

  return (
    <>
      <div className="d-flex flex-wrap flex-stack mb-6">
        <h3 className="fw-bolder my-2">
          Customer History
          <span className="fs-6 text-gray-400 fw-bold ms-1">{year}</span>
        </h3>

        <div className="d-flex flex-wrap my-2">
          <div className="me-4">
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              className="form-select form-select-sm form-select-white w-125px"
              value={year}
              onChange={(event) => setYear(event.target.value)}
            >
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
            </select>
          </div>
        </div>
      </div>

      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-body p-9">
          <div className="row mb-7">
            {/*<BarChart*/}
            {/*  className="card-xxl-stretch mb-5 mb-xl-10"*/}
            {/*  series={[]}*/}
            {/*/>*/}
          </div>
        </div>
      </div>
    </>
  );
}
