import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../theme/helpers'

type Props = {
  title?: string,
  description?: string,
  buttonProps?: {
    title: string,
    url: string,
  },
};

const Error404: FC<Props> = (
  {
    title = 'Oops!',
    description = "We can't find that page.",
    buttonProps = {
      title: 'Return Home',
      url: '/dashboard',
    }
  },
) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>{title}</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>{description}</div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-3'>
        <img
          src={toAbsoluteUrl('/media/auth/404-error.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <Link to={buttonProps.url} className='btn btn-sm btn-primary'>
          {buttonProps.title}
        </Link>
      </div>
      {/* end::Link */}
    </div>
  )
}

export { Error404 }
