import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { Button, FormGroup, InputGroup } from 'react-bootstrap';
import * as Yup from 'yup';

import { updatePickedOrder } from '../../../api';
import { CircularSpinner, DualListBox, ListOption, SVGImage } from '../../../../../components';
import { useListView, useResponse } from '../../../../../providers';
import { PurchaseOrder } from '../../../models';

type Props = {
  isLoading: boolean,
  order: PurchaseOrder,
};

const validationSchema = Yup.object().shape({
  control: Yup.number(),
  name: Yup.string(),
});

export const ShippingOrderForm: FC<Props> = ({ isLoading, order }) => {
  const { setItemForUpdate } = useListView();
  const { reload } = useResponse();
  const productionOrders = order ? order?.productionOrders : [];
  const productionOrder = productionOrders?.length ? productionOrders[0] : undefined;

  const onCancelActionHandler = (withRefresh?: boolean) => {
    if (withRefresh) {
      reload();
    }

    setItemForUpdate(undefined);
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          selected: order?.productionOrders?.length === 1 ? [String((order?.productionOrders || [])[0]?.id)] : [],
          notes: productionOrder ? productionOrder.productionInfo?.notes : undefined,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);

          try {
            await updatePickedOrder(order.id, values);
            onCancelActionHandler(true);
          } catch (error: any) {
            setErrors({
              selected: error.response.data.message,
            });
          } finally {
            setSubmitting(true);
          }
        }}>
        {({
            isSubmitting,
            isValid,
            touched,
            errors,
            values,
            setFieldValue,
          }) => (
          <Form>
            {isLoading && (<CircularSpinner />)}
            <div
              className="d-flex flex-column scroll-y me-n7 pe-7"
              id="approval_order_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#approval_order__header"
              data-kt-scroll-wrappers="#approval_order_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="flex flex-column">
                <div className="p-1">
                  <h3 className="font-size-lg text-dark font-weight-bold mb-6">1. Order Details:</h3>
                  <div className="mb-5">
                    <div className="form-group row">
                      <label className="col-4 col-form-label text-end">Purchase Order:</label>
                      <div className="col-8 col-form-label">
                        <strong>{order?.id}</strong>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-4 col-form-label text-end">Production Order(s):</label>
                      <div className="col-8 col-form-label">
                        <strong>
                          {productionOrders?.length
                            ? productionOrder?.id
                            : `${productionOrders?.length} orders`
                          }
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-1">
                  <h3 className="font-size-lg text-dark font-weight-bold mb-6">2. Warehouse Details:</h3>
                  <div className="mb-3">
                    <Field type="hidden" name="controls" />
                    {errors && errors.selected && (
                      <div className="text-danger">{errors.selected}</div>
                    )}
                    {(order && Number(order?.productionOrdersCount) > 1) && (
                      <div className="mb-10 fv-row">
                        <label className="d-flex align-items-center form-label mb-3">
                          Specify the Controls # to be approved
                        </label>

                        <div className="row">
                          <DualListBox
                            disabled={order?.productionOrdersCount === 1}
                            options={(order?.productionOrders || []).map((order) => ({
                              label: String(order.id),
                              value: order.id,
                            }) as ListOption)}
                            onChange={(selected: string[]) => setFieldValue('selected', selected)}
                            selected={
                              order?.productionOrdersCount === 1
                                ? [String((order?.productionOrders || [])[0]?.id)]
                                : []
                            }
                          />
                        </div>
                      </div>
                    )}
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <SVGImage path="/media/icons/memo.svg" />
                        </InputGroup.Text>
                        <Field
                          as="textarea"
                          name="notes"
                          placeholder="Additional notes or instructions"
                          className="form-control"
                          value={values.notes}
                        />
                      </InputGroup>
                      <ErrorMessage name="notes" />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>

            {/* begin::Actions */}
            <div className="text-center pt-15">
              <Button
                type="reset"
                variant="light"
                disabled={isSubmitting || isLoading}
                className="me-3"
                onClick={() => onCancelActionHandler()}
              >
                Cancel
              </Button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading || isSubmitting || !isValid || !touched}
              >
                <span className="indicator-label">{!order ? 'Submit' : 'Update'}</span>
                {(isSubmitting || isLoading) && (
                  <span className="indicator-progress">
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};