import { Box, Typography } from "@mui/material";
import { FC } from "react";

type Props = {
  title: string,
  subtitle?: string,
};

export const Header: FC<Props> = ({ title, subtitle }) => {
  return (
    <Box mb="3px">
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      
      {subtitle && (<Typography variant="h6" color="gray">
        {subtitle}
      </Typography>)}
    </Box>
  );
};
