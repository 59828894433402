import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { PageLink, PageTitle } from "../../../../core/components";
import { WarehouseListWrapper } from "./components";

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const WarehousesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Warehouses List</PageTitle>
              <WarehouseListWrapper />
            </>
          }
        />
        <Route index element={<Navigate to="/third-parties/warehouses/list"/>}/>
      </Route>
    </Routes>
  );
};

export default WarehousesPage;
