import { capitalize } from '@ialopezg/commonjs';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { FieldValues } from 'react-hook-form';

import CustomerForm from './Forms/CustomerForm';
import { Customer } from '../../../../../models';
import { Row } from 'react-table';
import ImportForm from '../forms/ImportForm';

const Header = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 4),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
}));

export type ActionType = 'preview' | 'edit' | 'import' | 'export';

interface CustomerFormWrapperProps {
  action?: ActionType;
  open: boolean;
  onCloseAction: () => void;
  onSubmitAction: (values?: FieldValues) => void;
  row?: Row<Customer>;
}

export const CustomerFormWrapper: FC<CustomerFormWrapperProps> = ({
  action = 'preview',
  open,
  onCloseAction,
  onSubmitAction,
  row,
}) => {
  const customer = row?.original;

  return (
    <Drawer
      anchor="right"
      onClose={onCloseAction}
      open={open}
      sx={{ '& .MuiDrawer-paper': { width: { xs: 300, sm: 400 } } }}
      variant="temporary"
      ModalProps={{ keepMounted: true }}
    >
      <Header>
        <Typography variant="h6">
          {row ? `Customer ${customer?.id}` : capitalize(action)}
        </Typography>
        <IconButton
          size="small"
          onClick={onCloseAction}
          sx={{ color: 'text.primary' }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Header>

      <Grid container spacing={6}>
        {row && (
          <Grid item xs={12}>
            <Card elevation={0}>
              <CardContent></CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} sx={{ m: 5 }}>
          {action === 'edit' && (
            <CustomerForm
              customer={customer as Customer}
              onSubmitAction={onSubmitAction}
            />
          )}
          {action === 'import' && (
            <ImportForm
              onCloseAction={onCloseAction}
              onSubmitAction={(values) => onSubmitAction(values)}
            />
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default CustomerFormWrapper;
