import { AxiosResponse } from 'axios';
import { FieldValues } from 'react-hook-form';

import { privateAxios } from '../../../api/axios';
import { ID, OrdersResponse } from '../../../models';
import { ORDERS_URL } from '../../../api';

const PICKED_ORDERS_URL = `${ORDERS_URL}/picked`;

export const getPickedOrders = (query: string): Promise<OrdersResponse> => {
  return privateAxios
    .get(`${PICKED_ORDERS_URL}?${query}`, { withCredentials: true })
    .then((response: AxiosResponse<OrdersResponse>) => response.data)
    .catch((error) => error.response.data);
};

export const approvePickedOrder = (id: ID, data: FieldValues) => {
  id = encodeURIComponent(String(id));
  const { attachments, ...rest } = data;
  const body = new FormData();

  for (const key of Object.keys(rest)) {
    body.append(key, data[key]);
  }
  for (const file of attachments as File[]) {
    body.append('attachments', file);
  }

  return privateAxios
    .post(`${PICKED_ORDERS_URL}/${id}/approve`, body, {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true,
    })
    .then((response: AxiosResponse) => response.data);
};

export const getPickedOrderByID = ({ queryKey }: any) => {
  const id = encodeURIComponent(queryKey[1].id);

  return privateAxios
    .get(`${PICKED_ORDERS_URL}/${id}`, { withCredentials: true })
    .then((response: any) => response.data);
};

export const updatePickedOrder = (id: ID, body: any) => {
  id = encodeURIComponent(String(id));

  return privateAxios
    .post(`${PICKED_ORDERS_URL}/${id}`, body, { withCredentials: true })
    .then((response: AxiosResponse) => response.data);
};

export const exportPickedOrders = (query: string): any => {
  return privateAxios
    .get(`${PICKED_ORDERS_URL}/export?filterByPicked=true`)
    .then((response) => response.data);
};

export const export3PLReport = async (id: ID): Promise<any> => {
  const response = await privateAxios.get(`${PICKED_ORDERS_URL}/${id}/export`);

  return response.data;
};
