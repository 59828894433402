import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { FC, ReactNode } from "react";
import Direction from "../../layouts/components/Direction";

import themeConfig from '../../config/theme'
import Settings from "../context/Settings";
import getThemeOptions from "./getThemeOptions";
import GlobalStyling from "./GlobalStyles";

interface ThemeComponentProps {
  children: ReactNode;
  settings: Settings;
}

export const ThemeComponent: FC<ThemeComponentProps> = ({ children, settings }) => {
  let theme = createTheme(getThemeOptions(settings, "light"));

  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return (
    <ThemeProvider theme={theme}>
      <Direction direction={settings.direction}>
        <CssBaseline />
        <GlobalStyles styles={() => GlobalStyling(theme) as any} />
        {children}
      </Direction>
    </ThemeProvider>
  );
};

export default ThemeComponent;