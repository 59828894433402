import { PaginationState } from './pagination.state';

// Response type model for all request objects
export type Response<T> = {
  /**
   * Data into the response.
   */
  data: T;
  /**
   * Optional payload into the response.
   */
  payload?: {
    /**
     * Errors while request.
     */
    errors?: {
      [key: string]: Array<string>;
    };
    /**
     * Additional message while request.
     */
    message?: string;
    /**
     * Pagination result while request
     */
    pagination?: PaginationState;
  };
}

export type ResponseContextProps<T> = {
  isLoading: boolean;
  isReloading: boolean;
  query: string;
  reload: () => void;
  response?: Response<Array<T>> | undefined;
};

export const initialResponse = {
  isLoading: false,
  isReloading: false,
  query: '',
  reload: () => {},
};
