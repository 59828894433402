import { AxiosResponse } from 'axios';

import { privateAxios } from '../../../../api/axios';
import { stringifyRequestQuery } from '../../../../helpers';
import { initialState } from '../../../../models';
import { Division, DivisionResponse } from '../models';

const DIVISIONS_URL = `/divisions`;

export const getDivisions = async (
  query: string
): Promise<DivisionResponse> => {
  const response = await privateAxios.get(`${DIVISIONS_URL}?${query}`);

  return response && response.data ? response.data : [];
};

export const getDivisionsOnOrders = ({
  queryKey,
}: any): Promise<Division[]> => {
  let { state } = queryKey[1];
  const query = stringifyRequestQuery({ ...initialState, ...state });

  return privateAxios
    .get(`${DIVISIONS_URL}?${query}`, { withCredentials: true })
    .then((response: AxiosResponse<DivisionResponse>) => response.data)
    .then((response: DivisionResponse) => response.data);
};

export const getDivisionsWithOpenOrders = (): Promise<Division[]> => {
  return privateAxios
    .get(`${DIVISIONS_URL}?filterWithOpenOrders=true`, {
      withCredentials: true,
    })
    .then((response: AxiosResponse<DivisionResponse>) => response.data)
    .then((response) => response.data);
};
