import { AxiosResponse } from 'axios';
import { privateAxios } from '../../../api/axios';
import { ID } from '../../../models';
import { PURCHASE_ORDERS_URL } from './purchase.api';

export const uploadFiles = (id: ID, files: File[]) => {
  id = encodeURIComponent(String(id));
  const body = new FormData();
  for (const file of files) {
    body.append('attachments', file);
  }

  return privateAxios
    .post(`${PURCHASE_ORDERS_URL}/${id}/attachments`, body, {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true,
    })
    .then((response: AxiosResponse) => response.data);
};

export const getPurchaseOrderAttachments = (id: ID): Promise<any[]> => {
  return privateAxios
    .get(`${PURCHASE_ORDERS_URL}/${id}/attachments`, { withCredentials: true })
    .then((response: AxiosResponse) => response.data);
};
