import { privateAxios } from '../../../../api/axios';
import { Warehouse, WarehouseResponse } from "../models";
import { AxiosResponse } from 'axios';
import { stringifyRequestQuery } from '../../../../helpers';
import { initialState, State } from "../../../../models";

const WAREHOUSE_URL = 'third-parties/warehouses';

export const getWarehouses = (query: string): Promise<WarehouseResponse> => {
  return privateAxios
    .get(`${WAREHOUSE_URL}?${query}`, { withCredentials: true })
    .then((response: AxiosResponse<WarehouseResponse>) => response.data);
};

export const getWarehouseOnOrders = (
  { queryKey }: any,
): Promise<Warehouse[]> => {
  const { state } = queryKey[1];
  const query = stringifyRequestQuery({ ...initialState, ...state } as State);

  return privateAxios
    .get(`${WAREHOUSE_URL}?${query}`, { withCredentials: true })
    .then((response: AxiosResponse<WarehouseResponse>) => response.data)
    .then((response: WarehouseResponse) => response.data);
};
