import { Table, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC, useMemo } from 'react';
import { Column, useTable } from 'react-table';

import { useResponseData, useResponseLoading } from '../../providers';
import { CardBody } from '../card';
import { Pagination } from '../pagination';
import { CircularSpinner } from '../spinners';
import { HeaderRender } from './headers';
import { DefaultRow, EmptyRow } from './rows';

type Props = {
  relatedTableId: string;
  columns: Array<Column>;
}

export const ListTable: FC<Props> = ({ relatedTableId, columns }) => {
  const isLoading = useResponseLoading();
  const data = useResponseData();
  const tableColumns = useMemo(() => columns, [columns]);
  const tableData = useMemo(() => data, [data]);
  const {
    getTableBodyProps,
    getTableProps,
    headers,
    prepareRow,
    rows,
  } = useTable({ columns: tableColumns, data: tableData });

  return (
    <CardBody>
      <TableContainer>
        <Table
          id={relatedTableId}
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps}
        >
          <TableHead>
            <TableRow>
              {headers.map((column) => (<HeaderRender key={column.id} column={column} />))}
            </TableRow>
          </TableHead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
          {
            rows.length > 0
              ? (rows.map((row, index) => {
                prepareRow(row);

                return (<DefaultRow key={`row-${index}-${row.id}`} row={row} />);
              }))
              : (<EmptyRow colSpan={headers.length} />)
          }
          </tbody>
        </Table>
      </TableContainer>
      <Pagination />
      {isLoading && <CircularSpinner />}
    </CardBody>
  );
};
