import {
  Box,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListProps,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import MuiList from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Icon from '../../../core/components/Icon';
import CardSnippet from '../Cards/snippet';
import FilePreviewIcon from '../inputs/uploader/FilePreviewIcon';

const List = styled(MuiList)<ListProps>(({ theme }) => ({
  '& .MuiListItem-container': {
    border: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius
    },
    '&:last-child': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    },
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '& .MuiListItem-root': {
      paddingRight: theme.spacing(24)
    },
    '& .MuiListItemText-root': {
      marginTop: 0,
      '& .MuiTypography-root': {
        fontWeight: 500
      }
    }
  }
}));

interface FileListProps {
  enableAdd?: boolean;
  enableRemove?: boolean;
  enableClear?: boolean;
  files: File[];
  onChange: (files: File[]) => void;
  title: string;
}

export const FileList: FC<FileListProps> = (
  {
    enableAdd = false,
    enableRemove = false,
    enableClear = false,
    files = [],
    onChange,
    title
  }) => {
  const onDrop = useCallback((selected: File[]) => {
    onChange(files.concat(selected));
  }, [files, onChange]);
  const { open } = useDropzone({ onDrop });
  const [action, setAction] = useState<'add' | 'remove' | 'clear' | undefined>(
    undefined
  );
  const [checked, setChecked] = useState<number[]>([]);

  const onFileSelected = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const onAction = (action: 'add' | 'remove' | 'clear') => {
    setAction(action);
    try {
      if (action === 'remove') {
        onChange(files.filter((file, index) => !checked.includes(index)));
        setChecked([]);
      }

      if (action === 'clear') {
        setChecked([]);
        onChange([]);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setAction(undefined);
    }
  };


  return (
    <CardSnippet title={title}>
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <ToggleButtonGroup
          exclusive
          size='small'
          color='primary'
          value={action}
          onChange={(e, newValue) => onAction(newValue)}
        >
          {enableAdd && (
            <ToggleButton onClick={open} value='add'>
              <Icon icon='mdi:plus' color='blue' />
            </ToggleButton>
          )}
          {(enableRemove && checked.length > 0) && (
            <ToggleButton color='error' value='remove'>
              <Icon icon='mdi:trash' color='orange' />
            </ToggleButton>
          )}
          {enableClear && (
            <ToggleButton value='clear'>
              <Icon icon='mdi:notification-clear-all' color='red' />
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Box>

      <List disablePadding>
        {!files?.length ? (
          <ListItem key='no-files'>
            <ListItemIcon>
              <Icon icon='mdi:file-question-outline' />
            </ListItemIcon>
            <ListItemText primary='No files' />
          </ListItem>
        ) : (
          files.map((file, index) => {
            return (
              <ListItem key={`file-${file?.name}-${index}`}>
                <ListItemButton>
                  <ListItemIcon>
                    <FilePreviewIcon file={file} />
                  </ListItemIcon>
                  <ListItemText
                    id='checkbox-list-label-0'
                    primary={file.name}
                  />
                </ListItemButton>

                <ListItemSecondaryAction onClick={onFileSelected(index)}>
                  <Checkbox
                    edge='end'
                    tabIndex={-1}
                    disableRipple
                    onChange={onFileSelected(index)}
                    checked={checked.indexOf(index) !== -1}
                    inputProps={{ 'aria-labelledby': 'checkbox-list-label-0' }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        )}
      </List>
    </CardSnippet>
  );
};

export default FileList;
