import { FC } from 'react';
import { ColumnInstance } from 'react-table';

import { useListView } from '../../../providers';

type Props = {
  column: ColumnInstance<any>;
  relatedTableId: string;
}

export const SelectionHeader: FC<Props> = ({ column, relatedTableId }) => {
  const { isAllSelected, onSelectAll } = useListView();
  return (
    <th {...column.getHeaderProps()} className="w-10px pe-2">
      <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
        <input
          className="form-check-input"
          type="checkbox"
          data-kt-check={isAllSelected}
          data-kt-check-target={`#${relatedTableId} .form-check-input`}
          checked={isAllSelected}
          onChange={onSelectAll}
        />
      </div>
    </th>
  );
};
