import axios, { HttpStatusCode } from 'axios';
import { refresh } from '../modules/auth/api';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const privateAxios = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

privateAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response) {
      if (
        error?.response?.status === HttpStatusCode.Unauthorized &&
        !prevRequest.sent
      ) {
        prevRequest.sent = true;

        try {
          await refresh();

          return privateAxios(prevRequest);
        } catch (e: any) {
          if (e.response && e.response.data) {
            return Promise.reject(e.response.data);
          }

          return Promise.reject(e);
        }
      }

      return Promise.reject(error);
    }
    console.log('None', error.response);
    return Promise.reject(error);
  }
);
