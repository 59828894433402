import { privateAxios } from '../../../../api/axios';
import { ID } from '../../../../models';

const ASSETS_URL = 'assets';

export const upload = async (file: File, onProgress: (percent: number) => void) => {
  const response = await privateAxios
    .post(ASSETS_URL, { file });
  onProgress(100);

  return response?.data;
};

export const getById = async (id: ID) => {
  const response = await privateAxios(
    `${ASSETS_URL}/${id}`, { withCredentials: true },
  );

  return response?.data;
};
