import { Grid, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { WithChildren } from '../../../../core';
import Avatar from '../../../../core/components/Avatar/Avatar';
import { ThemeColor } from '../../../../theme';

type Props = {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  icon?: string | ReactNode;
  color?: ThemeColor;
  url?: string;
};

export const TileCell: FC<Props & WithChildren> = ({
  title,
  subtitle,
  icon,
  color = 'primary',
  url,
  children,
}) => (
  <Grid container spacing={6}>
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icon && (
        <Avatar skin="light" color={color}>
          {icon}
        </Avatar>
      )}

      <Grid container sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {url ? (
          <Link
            to={url}
            className="text-gray-800 text-hover-primary mb-1 fw-bolder"
          >
            <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
          </Link>
        ) : (
          <span className="text-gray-800 mb-1 fw-bolder">{title}</span>
        )}
        {typeof subtitle === 'string' ? (
          <Typography variant="subtitle1">
            <span>{subtitle}</span>
          </Typography>
        ) : (
          subtitle
        )}
        {children}
      </Grid>
    </Grid>
  </Grid>
);
