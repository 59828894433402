import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import { QUERIES } from '../../../../constants';
import { AutocompleteWidget } from '../../../../components';
import { stringifyRequestQuery } from '../../../../helpers';
import { State } from '../../../../models';
import { getWarehouses } from '../api';

type Props = {
  onChange?: (division: string) => void;
  selected?: string;
  module?: string;
};

export const WarehouseSelectWidget: FC<Props> = ({
  onChange = (warehouse) => warehouse,
  selected,
  module,
}) => {
  const { data } = useQuery(QUERIES.WAREHOUSE, async () => {
    const query = stringifyRequestQuery({
      page: -1,
      filter: {
        [String(module)]: !!module ?? undefined,
        customer: undefined,
        division: undefined,
      },
    } as State);

    return getWarehouses(query);
  });
  const options = useMemo(
    () =>
      (data?.data || []).map((w) => ({
        label: String(w?.name),
        value: String(w?.id),
      })),
    [data]
  );

  return (
    <AutocompleteWidget
      options={options}
      onChange={onChange}
      selected={selected}
      title="Warehouse"
    />
  );
};
