import { isEmpty, isNumber } from "@ialopezg/commonjs";
import { FC, useEffect, useMemo } from "react";
import DataTable, { ExpanderComponentProps, TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";

import {
  ActionsCell,
  CardBody,
  CircularSpinner,
  DateTimeCell, DownloadButton,
  NumberCell,
  TileCell
} from "../../../../../components";
import {
  useRequest,
  useResponseData,
  useResponseLoading,
  useResponsePagination
} from "../../../../../providers";
import { OrderColumns, OrderOptionsCell } from "../../../components";
import { PurchaseOrder } from "../../../models";
import { Orders } from "../../../models/OrderModel";
import { OrderStatus } from "../../../order/OrderStatus";

export const ExpandableRow: FC<ExpanderComponentProps<PurchaseOrder>> = ({ data }) => {
  const productionOrders = data.productionOrders as Orders;
  const orders = useMemo(() => productionOrders, [productionOrders]);
  const columns = useMemo(() => [...OrderColumns, {
    name: "ACTIONS",
    selector: (row) => String(row.id),
    cell: (row) => {
      const { orderLines } = row;
      const warehouse = orderLines ? [...new Set(orderLines.map((ol) => ol?.warehouse?.id))][0] : undefined;

      return (
        <>
          <ActionsCell
            id={String(row.id)}
            type="horizontal"
            enableView={true}
            viewAction={`/orders/shipping/${row.id}`}
          />
          {warehouse === "HT" && <DownloadButton color="primary" />}
        </>
      );
    }
    // eslint-disable-next-line
  }], []);

  return (
    <DataTable
      columns={columns}
      data={orders}
      className="ms-15"
    />
  );
};

export const PurchaseOrderTable: FC = () => {
  const isLoading = useResponseLoading();
  const pagination = useResponsePagination();
  const orders = useResponseData();
  const { updateState } = useRequest();
  // const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const columns: TableColumn<PurchaseOrder>[] = [
    {
      name: "PO",
      sortable: true,
      grow: 3,
      selector: (order) => String(order?.id),
      cell: ({ id, ...row }) => {
        console.log(row?.status, row?.stage)
        const status = row?.status === "OPEN" && (!row?.stage || row?.stage !== "NONE")
          ? row?.stage
          : row?.status;

        return (
          <TileCell
            title={<>
            <span className="d-flex flex-row justify-content-center">
              <div className="align-self-center me-2">
                <OrderStatus status={status} />
              </div>
              {" "}
              {id}
            </span>
            </>}
            url={`/orders/purchases/${id}`}
          >
            <OrderOptionsCell objectValue={row} />
          </TileCell>
        );
      }
    },
    {
      name: "CUSTOMER",
      id: "customer",
      sortable: true,
      grow: 2,
      cell: (row) => {
        return (
          <Link to={`/third-parties/customers/${row?.customer}`}>
            {String(row?.customer)}
          </Link>
        );
      }
      // omit: hiddenColumns.find((c) => c === "customer") !== undefined
    },
    {
      name: "DIV",
      sortable: true,
      compact: true,
      right: true,
      selector: ({ division }) => String(division),
      cell: ({ division }) => {
        if (isNumber(division)) {
          return (<span className="badge badge-white">{division}</span>);
        }

        return (<Link to={`/divisions/${division}`}>{division}</Link>);
      }
    },
    {
      name: "WH",
      sortable: true,
      compact: true,
      right: true,
      selector: ({ warehouse }) => String(warehouse),
      cell: ({ warehouse }) => (
        <Link to={`/third-parties/warehouses/${warehouse}`}>{warehouse}</Link>
      )
    },
    {
      name: "S. DATE",
      right: true,
      compact: true,
      sortable: true,
      selector: ({ startDate }) => startDate ? new Date(String(startDate)).toLocaleDateString() : "",
      cell: ({ startDate }) => startDate ? <DateTimeCell value={startDate} /> : ""
    },
    {
      name: "C. DATE",
      right: true,
      compact: true,
      sortable: true,
      selector: ({ cancelDate }) => cancelDate ? new Date(String(cancelDate)).toLocaleDateString() : "",
      cell: ({ cancelDate }) => cancelDate ? <DateTimeCell value={cancelDate} /> : ""
    },
    {
      name: "SH. DATE",
      right: true,
      compact: true,
      sortable: true,
      selector: ({ shipDate }) => String(shipDate),
      cell: ({ shipDate }) => shipDate ? <DateTimeCell value={shipDate} /> : ""
    },
    {
      name: "P. DATE",
      right: true,
      compact: true,
      sortable: true,
      selector: ({ pickupDate }) => pickupDate ? new Date(String(pickupDate)).toLocaleDateString() : "",
      cell: (row) => {
        const order = row?.productionOrders?.length ? row?.productionOrders[0] : null;
        const productionInfo = order ? order?.productionInfo : null;
        const routingInfo = productionInfo ? productionInfo?.routing : null;
        const routed = routingInfo?.customerContacted;
        const pickupDate = routingInfo ? routingInfo?.pickupDate : "";

        return (
          <>
            {isEmpty(pickupDate) && !routed
              ? ""
              : routed
                ? "Routed"
                : <DateTimeCell value={new Date(String(pickupDate))} />
            }
          </>
        );
      }
    },
    {
      name: "CONTROLS",
      right: true,
      compact: true,
      id: "controls",
      cell: (row) => (<NumberCell value={Number(row?.productionOrders?.length)} />)
    },
    {
      name: "T. QTY",
      right: true,
      compact: true,
      sortable: true,
      selector: ({ orderQuantity }) => Number(orderQuantity).toLocaleString(),
      cell: ({ orderQuantity }) => (<NumberCell value={Number(orderQuantity)} />)
    },
    {
      name: "P. QTY",
      right: true,
      compact: true,
      selector: ({ pickedQuantity }) => Number(pickedQuantity).toLocaleString(),
      cell: ({ pickedQuantity }) => (<NumberCell value={Number(pickedQuantity)} />)
    },
    {
      name: "O. QTY",
      right: true,
      compact: true,
      selector: ({ openQuantity }) => Number(openQuantity).toLocaleString(),
      cell: ({ openQuantity }) => (<NumberCell value={Number(openQuantity)} />)
    },
    {
      name: "SH. QTY",
      right: true,
      compact: true,
      selector: ({ shippedQuantity }) => Number(shippedQuantity).toLocaleString(),
      cell: ({ shippedQuantity }) => (<NumberCell value={Number(shippedQuantity)} />)
    },
    {
      name: "ACTIONS",
      id: "actions",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-end flex-shrink-0">
            <ActionsCell
              id={String(row.id)}
              type="horizontal"
              enableView={true}
              viewAction={`/orders/purchases/${row.id}`}
            />
          </div>
        );
      }
    }
  ];

  const handlePageChange = (page: number) => {
    if (!page || isLoading || pagination.page === page) {
      return;
    }

    updateState({ page, pageSize: pagination.take || 10 });
  };

  useEffect(() => {
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardBody className="py-4">
      <DataTable
        columns={columns}
        data={orders}
        pagination
        paginationServer
        progressPending={isLoading}
        onChangePage={handlePageChange}
        paginationTotalRows={pagination.totalItems}
        expandableRows={true}
        expandableRowsComponent={ExpandableRow}
      />

      {isLoading && (<CircularSpinner />)}
    </CardBody>
  );
};
