import {
  Avatar,
  Badge,
  Box,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
import Icon from '../../../components/Icon';
import Settings from '../../../context/Settings';

const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

interface CurrentUserActionProps {
  settings: Settings;
}

export const CurrentUserAction: FC<CurrentUserActionProps> = ({ settings }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  // ** states
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // ** vars
  const { direction } = settings;
  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      mr: 2,
      fontSize: '1.375rem',
      color: 'text.primary',
    },
  };

  const onMenuOpen = (e: SyntheticEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const onMenuClose = (url?: string) => {
    if (url) {
      navigate(url);
    }

    setAnchorEl(null);
  };

  const onLogoutAction = () => {
    logout();
    onMenuClose();
  };

  return (
    <>
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<BadgeContentSpan />}
        onClick={onMenuOpen}
        overlap="circular"
        sx={{ ml: 2, cursor: 'pointer' }}
      >
        <Avatar
          alt={currentUser?.displayName}
          onClick={onMenuOpen}
          sx={{ width: 40, height: 40 }}
          src={currentUser?.avatar}
        />
      </Badge>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
        open={!!anchorEl}
        onClose={() => onMenuClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        transformOrigin={{
          vertical: 'top',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Avatar
                alt={currentUser?.displayName}
                src={currentUser?.avatar}
                sx={{ width: '2.5rem', height: '2.5rem' }}
              />
            </Badge>
            <Box
              sx={{
                display: 'flex',
                ml: 3,
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {currentUser?.displayName}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: '0.8rem', color: 'text.disabled' }}
              >
                {currentUser?.userAuth?.roleName}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ mt: '0 !important' }} />

        <MenuItem sx={{ p: 0 }} onClick={() => onMenuClose('/account')}>
          <Box sx={styles}>
            <Icon icon="mdi:account-outline" />
            Profile
          </Box>
        </MenuItem>
        {/*<MenuItem sx={{ p: 0 }} onClick={() => onMenuClose('/apps/email')}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon="mdi:email-outline" />*/}
        {/*    Inbox*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem sx={{ p: 0 }} onClick={() => onMenuClose('/apps/chat')}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon="mdi:message-outline" />*/}
        {/*    Chat*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}

        {/*<Divider />*/}

        {/*<MenuItem*/}
        {/*  sx={{ p: 0 }}*/}
        {/*  onClick={() => onMenuClose('/pages/account-settings/account')}*/}
        {/*>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon="mdi:cog-outline" />*/}
        {/*    Settings*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem sx={{ p: 0 }} onClick={() => onMenuClose('/pages/pricing')}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon="mdi:currency-usd" />*/}
        {/*    Pricing*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem sx={{ p: 0 }} onClick={() => onMenuClose('/pages/faq')}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon="mdi:help-circle-outline" />*/}
        {/*    FAQ*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}

        <Divider />

        <MenuItem
          onClick={onLogoutAction}
          sx={{
            py: 2,
            '& svg': { mr: 2, fontSize: '1.375rem', color: 'text.primary' },
          }}
        >
          <Icon icon="mdi:logout-variant" />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default CurrentUserAction;
