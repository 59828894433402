import { CircularProgress, Stack, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { Option } from "../../../../components";
import { QUERIES } from "../../../../constants";
import { stringifyRequestQuery } from "../../../../helpers";
import { State } from '../../../../models';
import { getDivisions } from "../api";

type DivisionSelectWidgetProps = {
  defaultOption?: Option,
  module: 'sales' | 'picked' | 'production' | 'routing';
  onChange?: (division: string) => void,
  selected?: string,
  options?: Option[],
  title?: string,
  filterByCustomer?: string,
};

export const DivisionSelectWidget: FC<DivisionSelectWidgetProps> = (
  {
    module,
    // defaultOption = { label: "ALL DIVISIONS", value: "ALL" },
    onChange = (division) => division,
    // selected,
    title = "Division"
  }
) => {
  const { data: customers, isLoading } = useQuery([
    QUERIES.DIVISIONS
  ], () => {
    const query = stringifyRequestQuery({
      page: -1,
      filter: {
        [String(module)]: !!module ?? undefined,
        customer: undefined,
        warehouse: undefined,
      }
    } as State);

    return getDivisions(query);
  });
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly Option[]>([]);
  const [search, setSearch] = useState("");
  const loading = open && options?.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        const matches = customers?.data?.reduce((result: Option[], customer) => {
          if ([customer?.id, customer?.name].join(" - ").toLowerCase().includes(search.toLowerCase())) {
            result.push({ label: String(customer?.name), value: String(customer?.id) });
          }
          return result;
        }, []);
        setOptions(matches as Option[]);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, search]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => `${option.value} - ${option.label}`}
        options={options}
        loading={loading || isLoading}
        onChange={(_, value) => onChange(String(value?.value))}
        onInputChange={(_, value: string) => {
          setOptions([]);
          setSearch(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            variant={"standard"}
          />
        )}
      />
    </Stack>
  );
};
