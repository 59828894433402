import { Close as CloseIcon, FilterAlt as FilterIcon } from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Divider,
  IconButton,
  Zoom
} from "@mui/material";
import { FC, ReactNode, useState } from "react";

import { WithChildren } from "../../../core";
import { BaseButton } from "./BaseButton";
import clsx from "clsx";

type Props = {
  actions?: ReactNode[],
  disabled?: boolean,
  title?: string,
  description?: string,
  onClearFilters?: () => void,
  onApplyFilters?: () => void,
};

export const FilterButton: FC<Props & WithChildren> = (
  {
    actions = [],
    children,
    disabled,
    title = "Filters",
    description = "Filter actions",
    onApplyFilters = () => {},
    onClearFilters = () => {}
  }
) => {
  const [open, setOpen] = useState(false);

  const toggleDialog = (value: boolean) => {
    setOpen(value);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          bgcolor: 'background.paper',
          "& hr": {
            mx: 0.5
          }
        }}
      >
        <Divider orientation="vertical" flexItem />
        <BaseButton
          className={clsx({ "disabled-icon": disabled })}
          disabled={disabled}
          icon={<FilterIcon color="primary" />}
          onlyIcon
          title={title}
          onClick={() => setOpen(true)}
        />
      </Box>

      <Dialog
        open={open}
        onClose={() => toggleDialog(false)}
        TransitionComponent={Zoom}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <FilterIcon color="primary" className="mr-2 mb-1" />
          {title}
          <IconButton
            aria-label="Close"
            className="close-icon"
            onClick={() => toggleDialog(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {description}
          </DialogContentText>
          <br />

          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            {children}
            {actions && actions.map(
              (action, index) => (<div key={`action-${index}`}>{action}</div>)
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => {
            onClearFilters();

            setOpen(false);
          }}>Clear Filters</Button>
          <Button onClick={() => {
            onApplyFilters();

            setOpen(false);
          }}>Apply Filters</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};