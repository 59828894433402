import { Card, Grid } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../core';
import Icon from '../../../core/components/Icon';
import ApexChartWrapper from '../../../core/styles/ApexChartWrapper';
import { VerticalStatsCard } from '../../components';
import { useAuth } from '../../modules/auth';
import AwardWidget from './partials/AwardWidget';
import InvoicedWidget from './partials/InvoicedWidget';
import OrderWidget from './partials/OrderWidget';
import OrderStatusWidget from './partials/OrderStatusWidget';

const DashboardPage: FC = () => {
  const { currentUser } = useAuth();
  const isAdmin = [
    'ROOT_ROLE',
    'MANAGEMENT_ROLE',
    'DIRECTOR_ROLE',
    'ADMINISTRATOR',
  ].includes(String(currentUser?.userAuth?.role));

  return (
    <ApexChartWrapper>
      <Grid container spacing={6} className="match-height">
        <Grid item xs={12} md={4}>
          <AwardWidget />
        </Grid>

        <Grid item xs={6} md={2} sm={3}>
          {isAdmin ? (
            <VerticalStatsCard
              chipText="Last Month"
              stats={'12.3K'}
              title="Total Invoiced"
              icon={<Icon icon="mdi:currency-usd" />}
              trendNumber="+1%"
            />
          ) : (
            <Card>R1C1</Card>
          )}
        </Grid>
        <Grid item xs={6} md={2} sm={3}>
          {isAdmin ? (
            <VerticalStatsCard
              chipText="Last Month"
              color="success"
              stats="43.19K"
              title="Total Orders"
              icon={<Icon icon="mdi:cart-plus" />}
              trendNumber="+1%"
            />
          ) : (
            <Card>R1C2</Card>
          )}
        </Grid>
        <Grid item xs={6} md={2} sm={3}>
          <Card>R1C3</Card>
        </Grid>
        <Grid item xs={6} md={2} sm={3}>
          <Card sx={{ display: 'flex', alignItems: 'space-between' }}>
            R1C4
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          {isAdmin ? (
            <InvoicedWidget title="Invoiced Orders" />
          ) : (
            <Card>R2C1</Card>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {isAdmin ? (
            <OrderWidget title="Open Orders" color="primary" />
          ) : (
            <Card>R2C3</Card>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', alignItems: 'space-between' }}>
            R2C3
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          {isAdmin ? (<OrderStatusWidget title="Open Orders" />) : (
            <Card>R3C1</Card>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', alignItems: 'space-between' }}>
            R3C2
          </Card>
        </Grid>
      </Grid>

      {/* begin::Row */}
      <div className="row gx-5 gx-xl-10">
        {/* begin::Col */}
        <div className="col-xxl-6 mb-5 mb-xl-10">
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className="col-xxl-6 mb-5 mb-xl-10">
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row gy-5 gx-xl-8">
        <div className="col-xxl-4">
          {/*<ListsWidget3 className='card-xxl-stretch mb-xl-3' />*/}
        </div>
        <div className="col-xl-8">
          {/*<TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />*/}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-4">
          {/*<ListsWidget2 className='card-xl-stretch mb-xl-8' />*/}
        </div>
        <div className="col-xl-4">
          {/*<ListsWidget6 className='card-xl-stretch mb-xl-8' />*/}
        </div>
        <div className="col-xl-4">
          {/*<ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />*/}
          {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
        </div>
      </div>
      {/* end::Row */}
    </ApexChartWrapper>
  );
};

export const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};
