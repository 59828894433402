/* eslint-disable react-hooks/exhaustive-deps */
import { isNil } from '@ialopezg/commonjs';
import {
  Box,
  Button,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import React, { FC, useEffect, useState } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import { OptionsMenu } from '../../../../core';
import Icon from '../../../../core/components/Icon';

import { DateRangePicker, DateType } from '../../../components';
import { DialogForm } from '../../../components/Dialogs/DialogForm';
import { initialState } from '../../../models';
import { getSummary } from '../../../modules/orders';
import {
  ListViewProvider,
  RequestProvider,
  ResponseProvider,
  useRequest, useRequestFilters,
  useResponseData,
  useResponseLoading,
} from '../../../providers';


type DateTypeRange = {
  from?: DateType,
  to?: DateType,
};

interface OrderStatusWidgetType {
  title: string,
}

const OrderStatusWidgetWrapper: FC<OrderStatusWidgetType> = ({ title }) => {
  const theme = useTheme();
  const { direction } = theme;
  const popperPlacement: ReactDatePickerProps['popperPlacement'] = direction === 'ltr' ? 'bottom-start' : 'bottom-end';

  const { updateState } = useRequest();
  const summary = useResponseData();
  const { orders, totalOpen, totalNotPicked, totalPicked, totalRouted, totalPickup } = summary;
  const isLoading = useResponseLoading();
  const filters = useRequestFilters();

  // state
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  const [current, setCurrent] = useState<'customer' | 'division' | 'warehouse'>('division');
  const [showQuantity, setShowQuantity] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<DateTypeRange>({ from: undefined, to: undefined });
  const [cancelDate, setCancelDate] = useState<DateTypeRange>({ from: undefined, to: undefined });
  const [pickupDate, setPickupDate] = useState<DateTypeRange>({ from: undefined, to: undefined });

  const onApplyFilters = async () => {
    const startDateRange = [
      startDate.from && new Date(startDate.from).toISOString().slice(0, 10),
      startDate.to && new Date(startDate.to).toISOString().slice(0, 10),
    ].toString();
    const cancelDateRange = [
      cancelDate?.from ? new Date(cancelDate?.from).toISOString().slice(0, 10) : '',
      cancelDate?.to ? new Date(cancelDate?.to).toISOString().slice(0, 10) : '',
    ].join();
    const pickupDateRange = [
      pickupDate?.from ? new Date(pickupDate?.from).toISOString().slice(0, 10) : '',
      pickupDate?.to ? new Date(pickupDate?.to).toISOString().slice(0, 10) : '',
    ].join();

    updateState({
      filter: {
        [current]: true,
        startDate: startDateRange.length > 1 ? startDateRange : undefined,
        cancelDate: startDateRange.length ? cancelDateRange : undefined,
        pickupDate: pickupDateRange.length > 1 ? pickupDateRange : undefined,
      },
      ...initialState,
    });
  };

  const onClearFilters = async () => {
    setStartDate({ from: undefined, to: undefined });
    setCancelDate({ from: undefined, to: undefined });
    setPickupDate({ from: undefined, to: undefined });
    setCurrent('division');

    updateState({ filter: { [current]: true } });
  };

  useEffect(() => {
    (async () => {
      updateState({ filter: { [current]: true } });
    })();
  }, [current]);

  useEffect(() => {
    const hasStartDate = startDate.from !== undefined || startDate.to !== undefined;
    const hasCancelDate = cancelDate.from !== undefined || cancelDate.to !== undefined;
    const hasPickupDate = pickupDate.from !== undefined || pickupDate.to !== undefined;
    const filtered = Object.keys(filters || {}).some((item: string) => item !== 'division');

    setHasFilters(filtered || hasStartDate || hasCancelDate || hasPickupDate);
  }, [startDate, cancelDate, pickupDate]);

  const parseNumber = (value: string) => ['0', '$0'].includes(value) ? '' : value;

  return (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={{
          sx: {
            lineHeight: '1.5rem !important',
            letterSpacing: '0.15px !important',
          },
        }}
        subheader={`By ${capitalize(current)}`}
        action={
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <DialogForm
              icon={'filter'}
              title="Filter"
              onSubmit={() => onApplyFilters()}
              closeButtonProps={{
                title: 'Clear Filters',
                onClick: onClearFilters,
              }}
              okButtonProps={{
                title: 'Apply Filters',
                onClick: onApplyFilters,
              }}
            >
              <DemoContainer components={['DateRangePicker', 'DateRangePicker']}>
                <DemoItem label="Start Date" component="DateRangePicker">
                  <DateRangePicker
                    selected={[startDate?.from, startDate?.to]}
                    popperPlacement={popperPlacement}
                    onChange={(start: DateType, end: DateType) => setStartDate({ from: start, to: end })}
                  />
                </DemoItem>
                <DemoItem label="Cancel Date" component="DateRangePicker">
                  <DateRangePicker
                    selected={[cancelDate?.from, cancelDate?.to]}
                    popperPlacement={popperPlacement}
                    onChange={(start: DateType, end: DateType) => setCancelDate({ from: start, to: end })}
                  />
                </DemoItem>
                <DemoItem label="Pickup Date" component="DateRangePicker">
                  <DateRangePicker
                    selected={[pickupDate?.from, pickupDate?.to]}
                    popperPlacement={popperPlacement}
                    onChange={(start: DateType, end: DateType) => setPickupDate({ from: start, to: end })}
                  />
                </DemoItem>
              </DemoContainer>
            </DialogForm>

            {(hasFilters && !isLoading) && (<Button
              color="error"
              onClick={onClearFilters}
              startIcon={<Icon icon="mdi:filter-remove-outline" />}
            >
              Clear
            </Button>)}

            <OptionsMenu
              options={[
                {
                  icon: <Icon icon={`mdi:radiobox-${current === 'division' ? 'marked' : 'blank'}`} />,
                  menuItemProps: {
                    onClick: () => setCurrent('division'),
                    selected: current === 'division',
                  },
                  text: 'By Division',
                },
                {
                  icon: <Icon icon={`mdi:radiobox-${current === 'customer' ? 'marked' : 'blank'}`} />,
                  menuItemProps: {
                    onClick: () => setCurrent('customer'),
                    selected: current === 'customer',
                  },
                  text: 'By Customer',
                },
                {
                  icon: <Icon icon={`mdi:radiobox-${current === 'warehouse' ? 'marked' : 'blank'}`} />,
                  menuItemProps: {
                    onClick: () => setCurrent('warehouse'),
                    selected: current === 'warehouse',
                  },
                  text: 'By Warehouse',
                },
              ]}
              iconButtonProps={{ size: 'small', className: 'card-more-options' }}
            />
          </Box>
        }
      />

      <CardContent>
        <Box sx={{ mb: 1.5, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isLoading && <Typography onClick={() => {
              if (!totalOpen?.amount) {
                setShowQuantity(false);
              }

              setShowQuantity(!showQuantity);
            }} variant="h4" sx={{ mr: 3.5 }}>
              {showQuantity
                ? `${totalOpen?.orders}${
                  totalPicked?.picks
                    ? ` [PT: ${totalPicked?.picks}]`
                    : ''}`
                : totalOpen?.amount}
            </Typography>}
          </Box>
        </Box>

        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    py: theme => `${theme.spacing(1.25)} !important`,
                  },
                }}
              >
                <TableCell>
                  <Typography
                    fontWeight={600}
                    variant="subtitle2"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    {current.toUpperCase()}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    fontWeight={600}
                    color="primary"
                    variant="subtitle2"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Not Picked
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    fontWeight={600}
                    color="success.main"
                    variant="subtitle2"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Picked
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    fontWeight={600}
                    color="error"
                    variant="subtitle2"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Routed
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    fontWeight={600}
                    color="info.main"
                    variant="subtitle2"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Pickup
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && (orders || []).map((order: any, index: number) => {
                return (
                  <TableRow
                    key={`summary-${order?.id}-${index}`}
                    sx={{
                      py: theme => `${theme.spacing(2.5)} !important`,
                    }}
                  >
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
                          {order?.id}
                        </Typography>
                        <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
                          {order?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 600, color: 'primary.main' }}
                      >
                        {parseNumber(showQuantity ? order?.notPicked?.orders : order?.notPicked?.amount)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: 'success.main' }}>
                          {parseNumber(showQuantity ? order?.picked?.orders : order?.picked?.amount)}
                        </Typography>
                        <Typography
                          variant="caption"
                        >
                          {parseNumber(order?.picked?.picks).length ? `[PT: ${order?.picked?.picks}]` : ''}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: 'error.main' }}>
                          {parseNumber(showQuantity ? order?.routed?.orders : order?.routed?.amount)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: 'info.main' }}>
                          {parseNumber(showQuantity ? order?.pickup?.orders : order?.pickup?.amount)}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {(isNil(summary) || isLoading) && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    {isLoading && (
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress color="secondary" />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {!isLoading && <TableFooter>
              <TableRow>
                <TableCell align="right">
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}>
                    TOTALS
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color="primary.main"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}>
                    {parseNumber(showQuantity ? totalNotPicked?.orders : totalNotPicked?.amount)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color="success.main"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}>
                    {parseNumber(showQuantity ? totalPicked?.orders : totalPicked?.amount)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color="error.main"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                  >
                    {parseNumber(showQuantity ? totalRouted?.orders : totalRouted?.amount)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color="info.main"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                  >
                    {parseNumber(showQuantity ? totalPickup?.orders : totalPickup?.amount)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export const OrderStatusWidget: FC<OrderStatusWidgetType> = ({ title }) => {
  return (
    <RequestProvider>
      <ResponseProvider callback={getSummary} queryKey={'summary-by-division'}>
        <ListViewProvider>
          <OrderStatusWidgetWrapper title={title} />
        </ListViewProvider>
      </ResponseProvider>
    </RequestProvider>
  );
};

export default OrderStatusWidget;