import { isNotEmpty } from "@ialopezg/commonjs";
import { useQuery } from "react-query";

import { getPickedOrderByID } from "../../../api";
import { QUERIES } from "../../../../../constants";
import { useListView } from "../../../../../providers";
import { PurchaseOrder } from '../../../models';
import { ShippingOrderForm } from "./ShippingOrderForm";

export const ShippingOrderFormWrapper = () => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const enabledQuery = isNotEmpty(itemForUpdate);

  const { isLoading, data: order, error } = useQuery(
    [`${QUERIES.SHIPPING_ORDERS}-id-${itemForUpdate}`, { id: itemForUpdate }],
    getPickedOrderByID,
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (error) => {
        setItemForUpdate(undefined);
        console.log(error);
      }
    }
  );

  if (!itemForUpdate) {
    return (<ShippingOrderForm isLoading={isLoading} order={{ id: undefined } as PurchaseOrder} />);
  }

  if (!isLoading && !error && order) {
    return (<ShippingOrderForm isLoading={isLoading} order={order} />);
  }

  return null;
};