import { useEffect, useState } from 'react';

import { MenuComponent } from '../../../theme/assets/ts/components';
import { initialState } from '../../models';
import { useRequest, useResponse } from '../../providers';
import { SVGImage } from '../image';

export const ListFilterAction = () => {
  const { updateState } = useRequest();
  const { isLoading } = useResponse();
  const [status, setStatus] = useState<string | undefined>('ALL');

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const resetData = () => {
    setStatus('OPEN');
    updateState({ filter: { status }, ...initialState });
  };

  const filterData = () => {
    updateState({
      filter: { status: status ? status : 'ALL' },
      ...initialState,
    });
  };

  return (
    <>
      {/* begin::Filter Fab */}
      <button
        disabled={isLoading}
        type="button"
        className="btn btn-sm btn-light-primary me-3"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        <SVGImage path="/media/icons/duotune/general/gen031.svg" className="svg-icon-2"/>
        Filters
      </button>
      {/* end::Filter Fab */}
      {/* begin::SubMenu */}
      <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
        {/* begin::Header */}
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className="separator border-gray-200"></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className="px-7 py-5" data-kt-user-table-filter="form">
          {/* begin::Input group */}
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">Status:</label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-hide-search="true"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value="All">ALL</option>
              <option value="ACTIVE">ACTIVE</option>
              <option value="INACTIVE">INACTIVE</option>
              <option value="DEACTIVATE">DEACTIVATE</option>
            </select>
          </div>
          {/* end::Input group */}


          {/* begin::Actions */}
          <div className="d-flex justify-content-end">
            <button
              type="button"
              disabled={isLoading}
              onClick={resetData}
              className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="reset"
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type="button"
              onClick={filterData}
              className="btn btn-primary fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter"
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  );
};
