import { FilterState } from './filter.state';
import { PaginationState } from './pagination.state';
import { SearchState } from './search.state';
import { SortState } from './sort.state';

export type State = PaginationState & SortState & FilterState & SearchState;

export const initialState: State = {
  page: 1,
  pageSize: 10,
  maxPages: 10,
};
