import { privateAxios } from '../../../api/axios';
import { ID } from '../../../models';
import { PurchaseOrderNote, PurchaseOrderNotes } from '../models';
import { PURCHASE_ORDERS_URL } from './purchase.api';

export const addPurchaseOrderNote = async (
  purchaseOrderId: ID,
  body: PurchaseOrderNote
): Promise<PurchaseOrderNote> => {
  return privateAxios
    .post(`${PURCHASE_ORDERS_URL}/${purchaseOrderId}/notes`, body, {
      withCredentials: true,
    })
    .then((response) => response.data);
};

export const getPurchaseOrderNotes = async (
  purchaseOrderId: ID
): Promise<PurchaseOrderNotes> => {
  return privateAxios
    .get(`${PURCHASE_ORDERS_URL}/${purchaseOrderId}/notes`, {
      withCredentials: true,
    })
    .then((response) => response.data);
};

export const getPurchaseOrderNotesById = async (
  purchaseOrderId: ID,
  id: ID
): Promise<PurchaseOrderNote> => {
  return privateAxios
    .get(`${PURCHASE_ORDERS_URL}/${purchaseOrderId}/notes/${id}`, {
      withCredentials: true,
    })
    .then((response) => response.data);
};

export const updatePurchaseOrderNote = async (
  purchaseOrderId: ID,
  id: ID,
  body: PurchaseOrderNote
): Promise<PurchaseOrderNote> => {
  return privateAxios
    .patch(`${PURCHASE_ORDERS_URL}/${purchaseOrderId}/notes/${id}`, body, {
      withCredentials: true,
    })
    .then((response) => response.data);
};

export const deletePurchaseOrderNote = async (
  purchaseOrderId: ID,
  id: ID
): Promise<PurchaseOrderNote> => {
  return privateAxios
    .delete(`${PURCHASE_ORDERS_URL}/${purchaseOrderId}/notes/${id}`, {
      withCredentials: true,
    })
    .then((response) => response.data);
};
