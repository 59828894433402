import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import { AutocompleteWidget } from '../../../components';
import { stringifyRequestQuery } from '../../../helpers';
import { State } from '../../../models';
import { getStatuses } from '../api';

interface OrderStatusSelectWidgetProps {
  module?: string;
  onChange?: (status: string) => void;
  selected?: string;
  title?: string;
}

export const OrderStatusSelectWidget: FC<OrderStatusSelectWidgetProps> = ({
  module = 'sales',
  onChange,
  selected,
  title = 'Order Status',
}) => {
  const { data } = useQuery('sales-order-statuses', async () => {
    const query = stringifyRequestQuery({
      page: -1,
      filter: {
        [String(module)]: module ? true : undefined,
      },
    } as State);

    return getStatuses(query);
  });

  const options = useMemo(
    () =>
      ((data as any[]) ?? []).map((item: any) => ({
        label: item?.name,
        value: String(item?.id),
      })),
    [data]
  );

  return (
    <AutocompleteWidget
      options={options}
      onChange={onChange}
      onlyName
      selected={selected}
      title={title}
    />
  );
};

export default OrderStatusSelectWidget;
