import { isNotEmpty } from '@ialopezg/commonjs';
import { FC } from 'react';
import { useQuery } from 'react-query';

import { QUERIES } from '../../../../../../constants';
import { ID } from '../../../../../../models';
import { useListView } from '../../../../../../providers';
import { getCustomerOptionsByCustomerAndUUID } from '../../../api';
import { CustomerOptionForm } from './CustomerOptionForm';

type Props = {
  customer?: ID;
};

export const CustomerOptionsFormWrapper: FC<Props> = ({ customer }) => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const enabledQuery: boolean = isNotEmpty(itemForUpdate);
  const {
    isLoading,
    data,
    error,
  } = useQuery(
    `${QUERIES.CUSTOMER_OPTIONS}-options-${itemForUpdate}`,
    () => {
      return getCustomerOptionsByCustomerAndUUID(customer, itemForUpdate);
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemForUpdate(undefined);
        console.error(err);
      },
    },
  );

  if (!itemForUpdate) {
    return (<CustomerOptionForm isLoading={isLoading} customer={customer} option={{ uuid: undefined }}/>);
  }

  if (!isLoading && !error && data) {
    return (<CustomerOptionForm isLoading={isLoading} customer={customer} option={data}/>);
  }

  return null;
};
