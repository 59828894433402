import axios, { AxiosResponse } from 'axios';
import { FieldValues } from 'react-hook-form';
import { privateAxios } from '../../../../api/axios';

import { ID, CustomerRequirement, CustomerOptionResponse, Response } from '../../../../models';
import { CUSTOMER_URL } from './customer.api';

export const createCustomerRequirement = (customer: ID, requirement: Partial<CustomerRequirement>): Promise<CustomerRequirement | undefined> => {
  return axios
    .post(
      `${CUSTOMER_URL}/${customer}/requirements`,
      requirement,
      { withCredentials: true },
    )
    .then((response: AxiosResponse<Response<CustomerRequirement>>) => response.data)
    .then((response: Response<CustomerRequirement>) => response.data);
};

export const getCustomerRequirements = async ({ queryKey }: any): Promise<CustomerOptionResponse> => {
  const { id } = queryKey[1];
  console.log(queryKey);
  const response = await axios
    .get(
      `${CUSTOMER_URL}/${id}/requirements`,
      { withCredentials: true });

  return response && response.data ? response.data : [];
};

export const getCustomerOptionsByCustomerAndUUID = (customer: ID, uuid: ID): Promise<CustomerRequirement | undefined> => {
  return axios
    .get(`${CUSTOMER_URL}/${customer}/requirements/${uuid}`, { withCredentials: true })
    .then((response: AxiosResponse<Response<CustomerRequirement>>) => response.data)
    .then((response: Response<CustomerRequirement>) => response.data);
};

export const updateCustomerRequirement = (
  id: ID,
  requirement: Partial<CustomerRequirement>,
): Promise<CustomerRequirement | undefined> => {
  const { customerId, ...body } = requirement;

  return axios
    .patch(
      `${CUSTOMER_URL}/${customerId}/requirements/${id}`,
      body,
      { withCredentials: true },
    )
    .then((response: AxiosResponse<Response<CustomerRequirement>>) => response.data)
    .then((response: Response<CustomerRequirement>) => response.data);
};

export const deleteCustomerOptionById = (option: CustomerRequirement): Promise<number> => {
  const { customerId, uuid } = option;

  return axios.delete(`${CUSTOMER_URL}/${customerId}/${uuid}`, { withCredentials: true })
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data);
};

export const importCustomersRequirements = async (values: FieldValues) => {

  const body = new FormData();
  body.append('action', values.action);
  body.append('file', values.file);

  const response = await privateAxios.post(
    `${CUSTOMER_URL}/requirements/import`,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );

  return response.data;
};

export const exportCustomersRequirements = async (query?: string) => {
  console.log(query);
  query = `${query ? `?${query}` : ''}`;

  const response = await privateAxios.get(
    `${CUSTOMER_URL}/requirements/export${query}`
  );

  return response.data;
};
