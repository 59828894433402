import { FC, ReactElement, ReactNode, useEffect } from "react";
import { useAuth } from "../../../app/modules/auth";
import { useNavigation } from "react-router-dom";

interface GuestGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

export const GuestGuard: FC<GuestGuardProps> = ({ children, fallback }) => {
  const auth = useAuth();
  const navigate = useNavigation();


  useEffect(() => {
  }, [navigate.location]);

  if (auth.currentUser !== null) {
    return fallback;
  }

  return (<>{children}</>);
};
