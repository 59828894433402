import { IconButton } from '@mui/material';
import React, { FC } from 'react';
import Icon from '../../../components/Icon';
import Settings from '../../../context/Settings';
import Mode from '../../types/Mode';

interface ThemeModeProps {
  settings: Settings;
  saveSettings: (values: Settings) => void;
}

export const ThemeModeAction: FC<ThemeModeProps> = ({
  settings,
  saveSettings,
}) => {
  const saveSettingsAction = (mode: Mode) => {
    saveSettings({ ...settings, mode });
  };

  const onThemeModeChange = () => {
    if (settings.mode === 'light') {
      saveSettingsAction('dark' as Mode);
    } else {
      saveSettingsAction('light' as Mode);
    }
  };

  return (
    <IconButton
      aria-haspopup="true"
      color="inherit"
      onClick={onThemeModeChange}
    >
      <Icon
        icon={`mdi:${
          settings.mode === 'dark' ? 'weather-sunny' : 'weather-night'
        }`}
      />
    </IconButton>
  );
};

export default ThemeModeAction;
