import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ID } from '../../models';

import { useListView, useResponse } from '../../providers';

type Props = {
  callback: (selected: Array<ID>) => void;
  queryKey: string;
};

export const ListGroupActions: FC<Props> = ({ queryKey, callback }) => {
  const { selected, clearSelection } = useListView();
  const queryClient = useQueryClient();
  const { query } = useResponse();

  const deleteSelectedItems = useMutation(async () => callback(selected), {
    onSuccess: () => {
      void queryClient.invalidateQueries([`${queryKey}-${query}`]);
      clearSelection();
    },
  });

  return (
    <div className="d-flex justify-content-end align-items-center">
      <div className="fw-bolder me-5">
        <span className="me-2">{selected.length}</span> Selected
      </div>

      <button
        type="button"
        className="btn btn-danger"
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Delete Selected
      </button>
    </div>
  );
};
