import { AxiosResponse } from 'axios';

import { ORDERS_URL } from '../../../api';
import { privateAxios } from '../../../api/axios';
import { SalesOrdersResponse } from '../../../models';

const SALES_ORDERS_URL = `${ORDERS_URL}/sales`;

export const getSalesOrders = (query: string): Promise<SalesOrdersResponse> => {
  return privateAxios
    .get(`${SALES_ORDERS_URL}?${query}`, { withCredentials: true })
    .then((response: AxiosResponse<SalesOrdersResponse>) => response.data);
};

export const getOrderStatistics = (query: string) => {
  return privateAxios
    .get(`${SALES_ORDERS_URL}/statistics${query ? `?${query}` : ''}`)
    .then((response: AxiosResponse) => response?.data);
};
