import { useRequest, useResponseLoading, useResponsePagination } from '../../providers';
import { PageLink } from './PageLink';

export const Pagination = () => {
  const isLoading = useResponseLoading();
  const { updateState } = useRequest();
  const pagination = useResponsePagination();
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return;
    }

    updateState({ page, pageSize: pagination.take || 10 });
  };
  const mappedLabel = (label: string): string => label
    .replace('&laquo;', '')
    .replace('&raquo;', '')
    .trim();

  return (
    <div className="row">
      <div
        className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
      >
        {pagination.totalPages > 1 && (<span className="align-self-end">
          {'Page '}
          <strong>{pagination.page.toLocaleString()}</strong>
          {/*<input type="text" name="page" value={pagination.page} className="mr-3 text-center w-50px" />*/}
          {' of '}
          <strong>{(pagination?.totalPages || 1).toLocaleString()}</strong>
          {' pages'}
        </span>)}
      </div>
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            {pagination.hasPreviousPage && (
              <PageLink
                page={1}
                isFirst
                onClick={() => updatePage(1)}
              />
            )}
            {pagination.hasPreviousPage && (
              <PageLink
                page={pagination.page - 1}
                isPrevious
                onClick={() => updatePage(1)}
              />
            )}
            {pagination.pages
              ?.map((link: any) => ({ ...link, label: mappedLabel(link.label) }))
              .map((link: any) => {
                return (
                  <PageLink
                    key={`page-${link.label}-${link.page}`}
                    active={pagination.page === link.page}
                    disabled={isLoading}
                    page={link.page}
                    label={link.label}
                    onClick={() => updatePage(link.page)}
                  />
                );
              })}
            {pagination.hasNextPage && (
              <PageLink
                page={pagination.page + 1}
                isNext
                onClick={() => updatePage(pagination.page + 1)}
              />
            )}
            {pagination.hasNextPage && (
              <PageLink
                page={pagination.totalPages}
                isLast
                onClick={() => updatePage(pagination.totalPages)}
              />)}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;