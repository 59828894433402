import { FC } from "react";
import { FileError } from "react-dropzone";

export interface UploadFileProps {
  file: File;
  onDelete: (file: File) => void;
  errors: FileError[];
}

export const UploadError: FC<UploadFileProps> = (
  {
    file,
    onDelete,
    errors,
  }) => {
  return (
    <>
      {JSON.stringify({ file, onDelete, errors })}
    </>
  );
};
