import { useIntl } from 'react-intl';

import { useAuth } from '../../../../../app/modules/auth';
import { SidebarMenuItem } from './SidebarMenuItem';

export const SidebarMenuMain = () => {
  const { currentUser } = useAuth();
  const { userAuth: auth } = currentUser || {};
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="/media/icons/swatch-book.svg"
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon="bi-app-indicator"
      />

      {auth?.role !== 'USER_ROLE' && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Third-Parties
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to="/third-parties/customers/list"
            title="Customers"
            icon="/media/icons/users.svg"
          />
          <SidebarMenuItem
            to="/third-parties/divisions/list"
            title="Divisions"
            icon="/media/icons/industry-windows.svg"
          />
          <SidebarMenuItem
            to="/third-parties/warehouses/list"
            title="Warehouses"
            icon="/media/icons/warehouse.svg"
          />
        </>
      )}
      {/* End::Third-Party separator */}

      {auth?.role !== 'USER_ROLE' && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Operations
              </span>
            </div>
          </div>
          {['SALES_ROLE', 'SHIPPING_ROLE', 'ADMINISTRATOR', 'ROOT_ROLE'].includes(
            String(auth?.role)
          ) && (
            <SidebarMenuItem
              to="/orders/sales"
              title="Sales Orders"
              icon="/media/icons/bags-shopping.svg"
            />
          )}
          {['SHIPPING_ROLE', 'ADMINISTRATOR', 'ROOT_ROLE'].includes(
            String(auth?.role)
          ) && (
            <SidebarMenuItem
              to="/orders/shipping"
              title="Shipping Orders"
              icon="/media/icons/duotone/ship.svg"
            />
          )}
          {['WAREHOUSE_ROLE', 'ADMINISTRATOR', 'ROOT_ROLE'].includes(
            String(auth?.role)
          ) && (
            <SidebarMenuItem
              to="/orders/production"
              title="Warehouse Orders"
              icon="/media/icons/warehouse.svg"
            />
          )}
          {['ROUTING_ROLE', 'ADMINISTRATOR', 'ROOT_ROLE'].includes(
            String(auth?.role)
          ) && (
            <SidebarMenuItem
              to="/orders/routing"
              title="Routing Orders"
              icon="/media/icons/duotone/route.svg"
            />
          )}
          {['ADMINISTRATOR', 'ROOT_ROLE'].includes(String(auth?.role)) && (
            <SidebarMenuItem
              to="/operations/pick-ticket"
              title="Pick Tickets"
              icon="/media/icons/duotone/hand-holding-box.svg"
            />
          )}
        </>
      )}

      {auth?.role !== 'USER_ROLE' && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Production
              </span>
            </div>
          </div>
          {['ADMINISTRATOR', 'ROOT_ROLE'].includes(String(auth?.role)) && (
            <SidebarMenuItem
              to="/production/shipments"
              title="Shipments"
              icon="/media/icons/duotone/ship.svg"
            />
          )}
        </>
      )}

      {['USER_ROLE', 'ADMINISTRATOR', 'ROOT_ROLE'].includes(
        String(auth?.role)
      ) && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Tools
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to="/tools/assets"
            icon="/media/icons/link.svg"
            title="Asset Manager"
            fontIcon="bi-layers"
          />
        </>
      )}

      {['ADMINISTRATOR', 'ROOT_ROLE'].includes(String(auth?.role)) && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                HR Manager
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to="/hrm/departments"
            icon="/media/icons/duotone/puzzle.svg"
            title="Departments"
          />
          <SidebarMenuItem
            to="/hrm/titles"
            icon="/media/icons/duotone/puzzle.svg"
            title="Job Titles"
          />

          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                System
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to="/security/users"
            icon="/media/icons/shield-keyhole.svg"
            title="User Manager"
            fontIcon="bi-layers"
          />
        </>
      )}
    </>
  );
};
