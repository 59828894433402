import classNames from 'classnames';
import { Component, ReactElement } from 'react';

import { SVGImage } from '../../image';

type DualListActionProps = {
  direction: 'left' | 'right',
  isMoveAll?: boolean,
  onClick: Function,
};

export class DualListAction extends Component<DualListActionProps, {}> {
  constructor(props: DualListActionProps) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  private getIcon(direction: 'left' | 'right', isMoveAll: boolean = false): string {
    return `/media/icons/arrows/chevron${isMoveAll ? 's' : ''}-${direction}.svg`;
  }

  private getLabel(direction: 'left' | 'right', isMoveAll:  boolean): string {
    return `Move${isMoveAll ? ' all' : ''} ${direction}`;
  }

  onClick() {
    const { direction, isMoveAll, onClick } = this.props;

    onClick({
      direction,
      isMoveAll,
    });
  }

  renderIcon(
    direction: 'left' | 'right' = 'left',
    isMoveAll: boolean = false,
  ): ReactElement {
    return (
      <SVGImage
        path={this.getIcon(direction, isMoveAll)}
        className="svg-icon-4"
      />
    );
  }

  /**
   * @returns {React.Component}
   */
  render() {
    const { direction, isMoveAll } = this.props;
    const label = this.getLabel(direction, Boolean(isMoveAll));
    const className = classNames({
      'action-btn': true,
      'action-btn-all': isMoveAll,
      'action-btn-right':  direction === 'right',
      'action-btn-left': direction === 'left',
    });

    return (
      <button
        aria-label={label}
        className={className}
        title={label}
        data-move-all={isMoveAll ? 1 : 0}
        data-move-direction={direction}
        type="button"
        onClick={this.onClick}
      >
        {this.renderIcon(direction, isMoveAll)}
      </button>
    );
  }
}
