import { ID } from '../../../models';

export interface AuthModel {
  api_token: string;
  refreshToken?: string;
}

export interface UserAddressModel {
  addressLine: string;
  city: string;
  state: string;
  postCode: string;
}

export interface UserCommunicationModel {
  email: boolean;
  sms: boolean;
  phone: boolean;
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean;
  sendCopyToPersonalEmail?: boolean;
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  };
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  };
}

export interface UserSocialNetworksModel {
  linkedIn: string;
  facebook: string;
  twitter: string;
  instagram: string;
}

export interface UserAuth {
  id: ID;

  role: string;
  roleName?: string;

  email: string;
  username?: string;

  isEmailConfirmed: boolean;

  lastLogin?: string;
}

export interface Department {
  id: ID;
  name: string;
  icon?: string;
  color?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface JobTitle {
  id: ID;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface WorkerInfo {
  department: Department;
  title: JobTitle;
}

export interface User {
  id?: ID;
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  initials?: string,
  occupation?: string;
  companyName?: string;
  phone?: string;
  role?: any;
  avatar?: string;
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru';
  timeZone?: string;
  website?: 'https://ops.axnygroup.com';
  emailSettings?: UserEmailSettingsModel;
  auth?: AuthModel;
  communication?: UserCommunicationModel;
  address?: UserAddressModel;
  socialNetworks?: UserSocialNetworksModel;
  joinedDay?: Date;

  userAuth?: UserAuth;
  department?: Department;
  title?: JobTitle;
  location?: string;
}

export const initialUser: User = {
  firstName: '',
  lastName: '',
  email: '',
}