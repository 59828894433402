import { ComponentsPropsList, Theme } from '@mui/material';

import Settings from '../../context/Settings';

import Accordion from './Accordion';
import Alert from './Alert';
import Autocomplete from './Autocomplete';
import Avatar from './Avatar';
import backdrop from './Backdrop';
import Breadcrumbs from './Breadcrumbs';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import DataGrid from './DataGrid';
import Dialog from './Dialog';
import Divider from './Divider';
import Fab from './Fab';
import Card from './Card';
import Chip from './Chip';
import Input from './Input';
import Link from './Link';
import List from './List';
import Menu from './Menu';
import Pagination from './Pagination';
import Paper from './Paper';
import Popover from './Popover';
import Progress from './Progress';
import Rating from './Rating';
import Select from './Select';
import Snackbar from './Snackbar';
import Switch from './Switch';
import Table from './Table';
import Tabs from './Tabs';
import Timeline from './Timeline';
import ToggleButton from './ToggleButton';
import Tooltip from './Tooltip';
import Typography from './Typography';

export type OwnerStateThemeType = {
  theme: Theme
  ownerState: ComponentsPropsList[keyof ComponentsPropsList] & Record<string, unknown>
}

export const Overrides = (settings: Settings) => {
  const { mode, skin } = settings;

  const accordion = Accordion();
  const alert = Alert(mode);
  const autocomplete = Autocomplete(skin);
  const avatar = Avatar();
  const button = Button();
  const card = Card(skin);
  const chip = Chip();
  const dataGrid = DataGrid();
  const dialog = Dialog(skin);
  const divider = Divider();
  const fab = Fab();
  const list = List();
  const input = Input();
  const menu = Menu(skin);
  const pagination = Pagination();
  const popover = Popover(skin);
  const progress = Progress();
  const rating = Rating();
  const select = Select();
  const snackbar = Snackbar(skin);
  const switches = Switch();
  const table = Table();
  const tabs = Tabs();
  const timeline = Timeline();
  const tooltip = Tooltip();

  return Object.assign(
    fab,
    chip,
    list,
    menu,
    tabs,
    card,
    input,
    select,
    alert,
    button,
    dialog,
    rating,
    table,
    avatar,
    divider,
    popover,
    tooltip,
    Link,
    backdrop,
    dataGrid,
    Paper,
    progress,
    snackbar,
    switches,
    timeline,
    accordion,
    pagination,
    autocomplete,
    Typography,
    Breadcrumbs,
    ButtonGroup,
    ToggleButton,
  );
};

export default Overrides;
