import { OwnerStateThemeType } from './'

export const Rating = () => {
  return {
    MuiRating: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          color: theme.palette.warning.main,
          '& svg': {
            flexShrink: 0
          }
        }),
        iconEmpty: ({ theme }: OwnerStateThemeType) => ({
          color: `rgba(${(theme.palette as any).customColors.main}, 0.22)`
        })
      }
    }
  }
}

export default Rating
