import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';

import { PageLink, PageTitle } from '../../../../core';
import PurchaseOrderPage from './components/PurchaseOrderPage';

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false
  },
  {
    title: 'Purchases Orders',
    path: '/purchases/list',
    isSeparator: false,
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
];

export const PurchaseOrdersPage = () => {
  const { id } = useParams();

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Purchases Orders List</PageTitle>
              <>{id}</>
            </>
          }
        />
        <Route
          path=":id/*"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Purchase Order Page</PageTitle>
              <PurchaseOrderPage />
            </>
          }
        />
        <Route index element={<Navigate to="/purchases/list" />} />
      </Route>
    </Routes>
  );
};

export default PurchaseOrdersPage;
