import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { SVGImage } from '../../../../../components';
import { PurchaseOrder } from '../../../models';
import { PurchaseOrderFormActionType } from './PurchaseOrderFormActionType';
import PurchaseOrderFormApproval from './PurchaseOrderFormApproval';
import PurchaseOrderFormAttachments from './PurchaseOrderFormAttachments';
import PurchaseOrderFormFilters from './PurchaseOrderFormFilters';
import PurchaseOrderFormGetReady from './PurchaseOrderFormGetReady';
import PurchaseOrderFormNotes from './PurchaseOrderFormNotes';
import ContentWrapper from '../../../../../components/pane/ContentWrapper';
import PurchaseOrderFormRouting from './PurchaseOrderFormRouting';

interface PurchaseOrderFormProps {
  action?: PurchaseOrderFormActionType;
  module?: 'sales' | 'picked' | 'production' | 'routing';
  onCloseAction: () => void;
  purchaseOrder: PurchaseOrder;
}

export const PurchaseOrderForm: FC<PurchaseOrderFormProps> = ({
  action = 'view',
  module = 'sales',
  onCloseAction,
  purchaseOrder,
}) => {
  const isFiltering = action === 'filter';

  const statusColor = purchaseOrder?.isCancelled
    ? 'error'
    : purchaseOrder?.isShipped
    ? 'success'
    : 'primary';
  const statusIconPath = `/media/icons/regular/${
    purchaseOrder?.isCancelled
      ? 'file-slash'
      : purchaseOrder?.isShipped
      ? 'ship'
      : 'box-open'
  }.svg`;
  const color =
    purchaseOrder?.isReady || purchaseOrder?.hasAppointment
      ? 'success'
      : purchaseOrder?.isRouted
      ? 'error'
      : purchaseOrder?.isManufacturing
      ? 'warning'
      : 'secondary';
  const iconPath = `/media/icons/regular/${
    purchaseOrder?.isReady
      ? 'check'
      : purchaseOrder?.isRouted
      ? 'route'
      : purchaseOrder?.hasAppointment
      ? 'calendar-day'
      : purchaseOrder?.isManufacturing
      ? 'warehouse'
      : 'question'
  }.svg`;

  return (
    <Grid item xs={12}>
      <Card elevation={0}>
        <CardContent>
          <Grid container spacing={6}>
            {isFiltering ? null : (
              <ContentWrapper title="General Information">
                <Grid item sm={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography>Total Controls:</Typography>
                  </Box>
                </Grid>
                <Grid item sm={7}>
                  <Typography fontWeight="bold">
                    {purchaseOrder?.totalOrders}
                  </Typography>
                </Grid>
                <Grid item sm={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography>Picked Orders:</Typography>
                  </Box>
                </Grid>
                <Grid item sm={7}>
                  <Typography fontWeight="bold">
                    {purchaseOrder?.pickedOrders ?? 0}
                  </Typography>
                </Grid>
                <Grid item sm={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography>Status:</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={7}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <SVGImage
                    path={statusIconPath}
                    className={`svg-icon-3 pe-2 text-${
                      statusColor === 'error' ? 'danger' : statusColor
                    }`}
                  />
                  <Typography fontWeight="bold">
                    {purchaseOrder?.status}
                  </Typography>
                </Grid>
                <Grid item sm={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography>Production Stage:</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={7}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <SVGImage
                    path={iconPath}
                    className={`svg-icon-3 pe-2 text-${
                      color === 'error' ? 'danger' : color
                    }`}
                  />
                  <Typography fontWeight="bold">
                    {purchaseOrder?.isReady
                      ? 'READY'
                      : purchaseOrder?.isRouted
                      ? 'ROUTED'
                      : purchaseOrder?.hasAppointment
                      ? 'APPOINTMENT'
                      : purchaseOrder?.isManufacturing
                      ? 'MANUFACTURING'
                      : 'NONE'}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography>Start Date:</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <ScheduleIcon color="success" fontSize="small" />
                  <Typography fontWeight="bold">
                    {purchaseOrder?.startDate
                      ? new Date(
                          String(purchaseOrder?.startDate)
                        ).toLocaleDateString('default')
                      : ''}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography>Cancel Date:</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <ScheduleIcon color="error" fontSize="small" />
                  <Typography fontWeight="bold">
                    {purchaseOrder?.startDate
                      ? new Date(
                          String(purchaseOrder?.cancelDate)
                        ).toLocaleDateString('default')
                      : ''}
                  </Typography>
                </Grid>
                {purchaseOrder?.estimatedShipDate && (
                  <>
                    <Grid item xs={5}>
                      <Box display="flex" justifyContent="flex-end">
                        <Typography>Estimated Ship Date:</Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <DirectionsBoatIcon color="success" fontSize="small" />
                      <Typography fontWeight="bold">
                        {purchaseOrder?.startDate
                          ? new Date(
                              String(purchaseOrder?.cancelDate)
                            ).toLocaleDateString('default')
                          : ''}
                      </Typography>
                    </Grid>
                  </>
                )}
              </ContentWrapper>
            )}

            <Grid item xs={12}>
              {action === 'approve' && (
                <PurchaseOrderFormApproval
                  purchaseOrderID={purchaseOrder?.reference}
                  onCloseAction={onCloseAction}
                />
              )}
              {action === 'attachments' && (
                <PurchaseOrderFormAttachments
                  purchaseOrderID={purchaseOrder?.reference}
                />
              )}
              {action === 'notes' && (
                <PurchaseOrderFormNotes purchaseOrderID={purchaseOrder?.id} />
              )}
              {action === 'routing' && (
                <PurchaseOrderFormRouting
                  onCloseAction={onCloseAction}
                  purchaseOrder={purchaseOrder}
                />
              )}
              {action === 'ready' && (
                <PurchaseOrderFormGetReady
                  onCloseAction={onCloseAction}
                  purchaseOrder={purchaseOrder}
                />
              )}
              {isFiltering && (
                <PurchaseOrderFormFilters
                  onCloseAction={onCloseAction}
                  module={module}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PurchaseOrderForm;
