import { FC, useMemo } from 'react';
import { Pagination } from '../../../../components/pagination';
import {
  useListView,
  useResponseData,
  useResponseLoading,
} from '../../../../providers';
import {
  CardBody,
  CircularSpinner,
  DateTimeCell,
  FormDialog,
  NumberCell,
  SVGImage,
  TileCell,
  useModalDialog,
} from '../../../../components';
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from 'react-data-table-component';
import { PurchaseOrder } from '../../models';
import { OrderStatus } from '../../order/OrderStatus';
import { OrderOptionsCell } from '../../components';
import { Link } from 'react-router-dom';
import { RoutingDetailsFormWrapper } from './Forms/RoutingDetailsFormWrapper';
import { isNumber, isUndefined } from '@ialopezg/commonjs';
import clsx from 'clsx';
import { ID, Order, Orders } from '../../../../models';
import { RoutingOrdersTableColumns } from './RoutingOrderTableColumns';

type ExpandableRowProps = {};
const ExpandableRow: FC<
  ExpandableRowProps & ExpanderComponentProps<PurchaseOrder>
> = ({ data }) => {
  const columns = useMemo(
    () => RoutingOrdersTableColumns as TableColumn<Order>[],
    []
  );
  const orders = data.productionOrders as Orders;

  return <DataTable columns={columns} data={orders} className="ms-15" />;
};

export const RoutingOrderTable = () => {
  const data = useResponseData();
  const isLoading = useResponseLoading();
  const orders = useMemo(() => data, [data]);
  const { itemForUpdate, setItemForUpdate } = useListView();
  const { setTitle } = useModalDialog();

  const setRoutingDetails = (id: ID) => {
    setItemForUpdate(id);
    setTitle(`Purchase Order ${id}`);
  };

  const columns: TableColumn<PurchaseOrder>[] = useMemo(
    () => [
      {
        name: 'PO',
        sortable: true,
        grow: 2,
        selector: (row) => String(row?.id),
        cell: (row) => {
          return (
            <TileCell
              title={
                <>
                  <OrderStatus
                    status={
                      row.status === 'OPEN' &&
                      (row?.stage === 'WAREHOUSE' || row.stage === 'READY')
                        ? row.stage
                        : row?.status
                    }
                  />{' '}
                  {row.id}
                </>
              }
              url={`/orders/purchases/${row.id}`}
            >
              <OrderOptionsCell objectValue={row} />
            </TileCell>
          );
        },
      },
      {
        name: 'CUSTOMER',
        sortable: true,
        grow: 1,
        compact: true,
        selector: ({ customer }) => String(customer),
        cell: ({ customer }) => {
          return (
            <Link
              to={`/third-parties/customers/${customer}`}
              className="text-center min-w-75px"
            >
              {String(customer)}
            </Link>
          );
        },
      },
      {
        name: 'DIV',
        sortable: true,
        compact: true,
        center: true,
        selector: ({ division }) => String(division),
        cell: ({ division }) => {
          if (isNumber(division)) {
            return <span className="badge badge-white">{division}</span>;
          }

          return <Link to={`/divisions/${division}`}>{division}</Link>;
        },
      },
      {
        name: 'WH',
        sortable: true,
        grow: 1,
        center: true,
        selector: ({ warehouse }) => String(warehouse),
        cell: ({ warehouse }) => {
          return (
            <Link
              to={`/third-parties/warehouses/${warehouse}`}
              className="text-center min-w-75px"
            >
              {String(warehouse)}
            </Link>
          );
        },
      },
      {
        name: 'A. DATE',
        sortable: true,
        compact: true,
        right: true,
        selector: ({ warehouseApprovalDate }) => String(warehouseApprovalDate),
        cell: ({ warehouseApprovalDate }) =>
          warehouseApprovalDate ? (
            <DateTimeCell value={warehouseApprovalDate} />
          ) : (
            ''
          ),
      },
      {
        name: 'S. DATE',
        sortable: true,
        compact: true,
        right: true,
        selector: (row) => String(row.startDate),
        cell: ({ startDate }) =>
          startDate ? <DateTimeCell value={startDate} /> : '',
      },
      {
        name: 'C. DATE',
        sortable: true,
        compact: true,
        right: true,
        selector: (row) => String(row.cancelDate),
        cell: ({ cancelDate }) =>
          cancelDate ? <DateTimeCell value={cancelDate} /> : '',
      },
      {
        name: 'SH. DATE',
        sortable: true,
        ignoreRowClick: true,
        compact: true,
        right: true,
        cell: ({ shipDate }) =>
          shipDate ? <DateTimeCell value={shipDate} /> : '',
      },
      {
        name: 'CTRLS',
        compact: true,
        right: true,
        cell: (row) => (
          <NumberCell value={Number(row?.productionOrders?.length)} />
        ),
      },
      {
        name: 'T. QTY',
        selector: (row) => String(row.orderQuantity),
        sortable: true,
        compact: true,
        right: true,
        cell: ({ orderQuantity }) => (
          <NumberCell value={Number(orderQuantity)} />
        ),
      },
      {
        name: 'O. QTY',
        selector: (row) => String(row.openQuantity),
        sortable: true,
        compact: true,
        right: true,
        cell: ({ openQuantity }) => <NumberCell value={Number(openQuantity)} />,
      },
      {
        name: 'P. QTY',
        selector: (row) => String(row.pickedQuantity),
        sortable: true,
        compact: true,
        right: true,
        cell: ({ pickedQuantity }) => (
          <NumberCell value={Number(pickedQuantity)} />
        ),
      },
      {
        name: 'SH. QTY',
        selector: (row) => String(row.shippedQuantity),
        sortable: true,
        right: true,
        cell: ({ shippedQuantity }) => (
          <NumberCell value={Number(shippedQuantity)} />
        ),
      },
      {
        name: 'ACTIONS',
        selector: (row) => String(row.id),
        compact: true,
        style: {
          marginRight: '5px',
        },
        cell: (row) => {
          const routing = (row?.productionOrders as Orders)[0]?.productionInfo
            ?.routing;

          return (
            <div className="d-flex justify-content-end flex-shrink-0">
              {row?.routingRequired && (
                <button
                  onClick={() => setRoutingDetails(row.id)}
                  className={clsx(
                    'btn btn-icon btn-active-color-primary btn-sm me-1',
                    {
                      'btn-light-primary': isUndefined(routing),
                      'btn-light-success':
                        !isUndefined(routing) && routing?.pickupDate,
                      'btn-light-warning': routing && !routing?.pickupDate,
                    }
                  )}
                  title="Edit routing details"
                >
                  <SVGImage
                    path="/media/icons/route.svg"
                    className="svg-icon-3"
                  />
                </button>
              )}
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    []
  );

  return (
    <>
      <CardBody className="py-4">
        <DataTable
          columns={columns}
          data={orders}
          expandableRows={true}
          expandableRowsComponent={ExpandableRow}
        />
        {isLoading && <CircularSpinner />}
        <Pagination />
      </CardBody>

      {itemForUpdate !== undefined && (
        <FormDialog
          icon={
            <SVGImage path="/media/icons/routing.svg" className="svg-icon-1" />
          }
          onClose={() => setItemForUpdate(undefined)}
          title="Routing Order"
        >
          <RoutingDetailsFormWrapper />
        </FormDialog>
      )}
    </>
  );
};
