import { Card, CardContent, CardHeader } from '@mui/material';
import { FC } from 'react';

import { CardSnippetProps } from './CardSnippetProps';

export const CardSnippet: FC<CardSnippetProps> = ({ action, children, className, id, title, sx }) => {
  return (
    <Card
      className={className}
      id={id || `card-snippet--${String(title).toLowerCase().replace(/ /g, '-')}`}
      sx={{ '& .MuiCardHeader-action': { lineHeight: 0.8 }, ...sx }}
    >
      <CardHeader
        action={action}
        title={title}
      />

      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardSnippet;
