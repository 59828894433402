import { isNil } from "@ialopezg/commonjs";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import { grey } from "@mui/material/colors/";
import React, { FC, useState } from "react";

import { SVGImage } from "../../../components";
import { getSummary } from "../../../modules/orders";
import {
  RequestProvider,
  ResponseProvider,
  useResponseData,
  useResponseLoading
} from "../../../providers";

type OrderWidgetProps = {
  color?: string,
  title?: string,
}

const OrderWidgetWrapper: React.FC<OrderWidgetProps> = (
  {
    title = "Summary Widget"
  }
) => {
  // state
  const summary = useResponseData();
  const { totalOpen, totalPicked } = summary;
  const isLoading = useResponseLoading();
  const [showQuantity, setShowQuantity] = useState<boolean>(false);

  return (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={{
          sx: {
            lineHeight: "1.5rem !important",
            letterSpacing: "0.15px !important"
          }
        }}
      />

      <CardContent sx={{ pt: theme => `${theme.spacing(1.75)} !important` }}>
        <Box sx={{ mb: 1.5, display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography onClick={() => {
              if (!totalOpen?.amount) {
                setShowQuantity(false);
              }

              setShowQuantity(!showQuantity);
            }} variant="h4" sx={{ mr: 3.5 }}>
              {showQuantity
                ? `${totalOpen?.orders}${totalPicked?.picks
                  ? ` [PT: ${totalPicked?.picks}]`
                  : ""}`
                : totalOpen?.amount}
            </Typography>
          </Box>
        </Box>

        <TableContainer>
          <Table>
            <TableBody>
              {!isNil(summary) && Object
                .keys(summary || {})
                ?.filter((key) => !['orders', 'totalOpen'].includes(key))
                ?.map((item, index) => {
                  let icon: string;
                  let color: string;
                  let title: string;
                  let subtitle: string;
                  let progressColor: any;
                  switch (item) {
                    case "totalNotPicked":
                      icon = "box-open";
                      color = "primary.main";
                      title = "Not Picked";
                      subtitle = "Open Orders without PT";
                      progressColor = color.replace(".main", "");
                      break;
                    case "totalPicked":
                      icon = "ship";
                      color = "success.main";
                      title = "Picked";
                      subtitle = "Open Orders with PT";
                      progressColor = color.replace(".main", "");
                      break;
                    case "totalRouted":
                      icon = "route";
                      color = "error.main";
                      title = "Routed";
                      subtitle = "Open Orders with Route actions";
                      progressColor = color.replace(".main", "");
                      break;
                    case "totalPickup":
                      icon = "timer";
                      color = "secondary.main";
                      title = "Pickup";
                      subtitle = "Open Orders with Pickup Date";
                      progressColor = color.replace(".main", "");
                      break;
                    case "totalOpen":
                    default:
                      icon = "box-open";
                      color = "primary.main";
                      title = item === "open" ? "Open Orders" : "";
                      subtitle = item === "open" ? "Open Orders not Picked" : "";
                      progressColor = color.replace(".main", "");
                      break;
                  }
                  const amount = (summary as any)[item]?.amount || 0;
                  const orders = (summary as any)[item]?.orders || 0;
                  const variance = (summary as any)[item]?.variance || 0;

                  return (
                    <TableRow
                      key={`item-order-${index}`}
                      sx={{
                        "&:last-of-type td": { border: 0, pb: 0 },
                        "&:first-of-type td": { borderTop: theme => `1px solid ${theme.palette.divider}` },
                        "& .MuiTableCell-root": {
                          "&:last-of-type": { pr: 0 },
                          "&:first-of-type": { pl: "0 !important" },
                          py: theme => `${theme.spacing(0.5)} !important`
                        }
                      }}
                    >
                      <TableCell>
                        <Box
                          key={`summary-${index}`}
                          sx={{
                            display: "flex",
                            alignItems: "center"
                            // ...(index !== summary?.orders.length - 1 ? { mb: 3.5 } : {}),
                            // ...(index !== summary?.orders.length - 1 ? { mb: 3.5 } : {})
                          }}
                        >
                          <Avatar
                            variant="rounded"
                            sx={{
                              mr: 2,
                              width: 40,
                              backgroundColor: grey[100]
                            }}
                          >
                            <SVGImage
                              path={`/media/icons/duotone/${icon}.svg`}
                              className={`svg-icon svg-icon-2 svg-icon-${progressColor === "error"
                                ? "danger"
                                : progressColor === "secondary"
                                  ? "info"
                                  : progressColor}`
                              }
                            />
                          </Avatar>

                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "space-between"
                            }}
                          >
                            <Box sx={{ marginRight: 2, display: "flex", flexDirection: "column" }}>
                              <Typography variant="body2" sx={{ fontWeight: 600, color }}>
                                {title}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{ whiteSpace: "nowrap" }}
                              >
                                {subtitle}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box sx={{ minWidth: 85, display: "flex", flexDirection: "column" }}>
                          <Typography
                            align="right"
                            variant="body2"
                            sx={{ mb: 1.5, fontWeight: 600, color: "text.primary" }}
                          >
                            {showQuantity ? orders : amount}
                          </Typography>
                          <LinearProgress
                            color={progressColor}
                            value={variance} variant="determinate"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>);
                })}
              {(isNil(summary) || isLoading) && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    {isLoading && (
                      <Box sx={{ display: "flex", justifyContent: 'center' }}>
                        <CircularProgress color="secondary" />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export const OrderWidget: FC<OrderWidgetProps> = ({ title }) => {
  return (
    <RequestProvider>
      <ResponseProvider callback={getSummary} queryKey={"open-orders-summary"}>
        <OrderWidgetWrapper title={title} />
      </ResponseProvider>
    </RequestProvider>
  );
};

export default OrderWidget;
