import { FC } from 'react';

import { WithChildren } from "../../../core";
import { ID } from '../../models';
import { SearchWidget } from './SearchWidget';
import { useRequest } from '../../providers';

type Props = {
  deleteSelectedCallback?: (selected: Array<ID>) => Promise<void> | void;
  queryKey?: string;
  enableSearch?: boolean;
  placeholder?: string;
  enableAdd?: boolean;
  enableFiltering?: boolean;
  toolbar?: JSX.Element,
};

export const ListHeader: FC<Props & WithChildren> = (
  {
    children,
    enableSearch = true,
    placeholder,
    toolbar,
  },
) => {
  const { state, updateState } = useRequest();

  return (
    <div className="card-header border-0">
      {enableSearch && (<SearchWidget
        onChange={(value) => updateState({
          ...state,
          search: value,
        })}
        placeholder={placeholder}
        value={state.search}
      />)}

      <div className="card-toolbar">
        {(!children && toolbar) && toolbar}
        {(children && !toolbar) && children}
      </div>
    </div>
  );
};
