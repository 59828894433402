import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, Stack, TextField } from '@mui/material';

import { Option } from '../Prototypes';
import { FC, useEffect, useState } from 'react';

type Props = {
  disabled?: boolean;
  options?: Option[];
  onChange?: (value: string) => void;
  onlyName?: boolean;
  selected?: string;
  title?: string;
};

export const AutocompleteWidget: FC<Props> = ({
  disabled,
  onChange = (value: string) => value,
  onlyName = false,
  options = [],
  selected,
  title = 'AutocompleteWidget',
}) => {
  const [open, setOpen] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    readonly Option[]
  >([]);
  const [value, setValue] = useState<Option | null>(
    options.find((o) => String(o?.value) === selected) || null
  );
  const [search, setSearch] = useState(
    onlyName ? value?.label : `${value?.value} - ${value?.label}` || ''
  );
  const loading = open && autocompleteOptions?.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        const matches = options.reduce((result: Option[], option) => {
          if (onlyName) {
            if (option.label.toLowerCase().includes(String(search).toLowerCase())) {
              result.push(option);
            }
          } else {
            if (
              [option.value, option.label]
                .join(' - ')
                .toLowerCase()
                .includes(String(search).toLowerCase())
            ) {
              result.push(option);
            }
          }
          return result;
        }, []);
        setAutocompleteOptions(matches);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, options, search]);

  useEffect(() => {
    if (!open) {
      setAutocompleteOptions([]);
    }
  }, [open]);

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <Autocomplete
        disabled={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        getOptionLabel={(option) =>
          onlyName ? option.label : `${option.value} - ${option.label}`
        }
        isOptionEqualToValue={(o, v) => o.value === v.value}
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue as Option);
          onChange(String(newValue?.value));
        }}
        inputValue={search}
        onInputChange={(_, newInputValue) => {
          setSearch(newInputValue);
        }}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            variant={'standard'}
          />
        )}
      />
    </Stack>
  );
};
