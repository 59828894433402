import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../theme/helpers';
import { SVGImage } from '../image';

type StaticIcon = {
  direction?: string;
  label: string;
  value?: string;
}

type Tab = {
  label: string;
  link: string;
  icon?: string;
};

type Legend = {
  imageUrl?: string,
  abbreviation?: string,
  description: string,
};

type Props = {
  title?: string;
  image?: string;
  legends?: Legend[];
  staticsIcons?: StaticIcon[];
  tabs?: Tab[];
  accuracy?: number;
  status?: ReactElement | string;
};

export const ModulePageHeader: FC<Props> = (
  {
    title,
    image,
    legends,
    staticsIcons,
    tabs,
    accuracy,
    status,
  },
) => {
  const location = useLocation();

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          {image && (
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img src={toAbsoluteUrl(image)} alt={title}/>
                <div
                  className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
              </div>
            </div>
          )}

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div id="header-title" className="d-flex align-items-center mb-2">
                  {title && (<span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{title}</span>)}
                  {typeof status === 'string' ? (
                    <>
                      {(status === 'active' || status === 'open' || status === 'true' || status === 'y') && (<SVGImage
                        path="/media/icons/circle-check.svg"
                        className="svg-icon-1 svg-icon-primary"
                      />)}
                      {(status === 'inactive' || status === 'closed' || status === 'false' || status === 'n') && (
                        <SVGImage
                          path="/media/icons/circle-xmark.svg"
                          className="svg-icon-1 svg-icon-secondary"
                        />)}
                    </>
                  ) : (
                    <>{status}</>
                  )}
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  {legends?.map((legend, index) => (
                    <span
                      key={`${legend.abbreviation}-${index}`}
                      className="d-flex align-items-center text-gray-400 me-3 mb-2"
                    >
                      {legend.imageUrl && (<SVGImage path={legend.imageUrl} className="svg-icon-4 me-1"/>)}
                      {legend.abbreviation && (<span className="badge bg-black me-2">{legend.abbreviation}</span>)}
                      <span>{legend.description}</span>
                    </span>
                  ))}
                </div>
              </div>

              {/*<div className="d-flex my-4">*/}
              {/*  <a href="#" className="btn btn-sm btn-light me-2"*/}
              {/*     id="kt_user_follow_button">*/}
              {/*    <SVGImage*/}
              {/*      path="/media/icons/duotone/arrows/arr012.svg"*/}
              {/*      className="svg-icon-3 d-none"*/}
              {/*    />*/}

              {/*    <span className="indicator-label">Follow</span>*/}
              {/*    <span className="indicator-progress">*/}
              {/*      Please wait...*/}
              {/*      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>*/}
              {/*    </span>*/}
              {/*  </a>*/}
              {/*  <a*/}
              {/*    href="#"*/}
              {/*    className="btn btn-sm btn-primary me-3"*/}
              {/*    data-bs-toggle="modal"*/}
              {/*    data-bs-target="#kt_modal_offer_a_deal"*/}
              {/*  >*/}
              {/*    Hire Me*/}
              {/*  </a>*/}
              {/*  <div className="me-0">*/}
              {/*    <button*/}
              {/*      className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"*/}
              {/*      data-kt-menu-trigger="click"*/}
              {/*      data-kt-menu-placement="bottom-end"*/}
              {/*      data-kt-menu-flip="top-end"*/}
              {/*    >*/}
              {/*      <i className="bi bi-three-dots fs-3"></i>*/}
              {/*    </button>*/}
              {/*    <Dropdown1/>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>

            <div className="d-flex flex-wrap flex-stack">
              <div id="container" className="d-flex flex-column flex-grow-1 pe-8">
                <div className="d-flex flex-wrap">
                  {staticsIcons?.map((icon) => {
                    const prefix = `${icon.direction ? '' : 's'}-`;
                    const suffix = `${icon.direction ? '-' : ''}`;
                    const iconImage = `/media/icons/arrow${prefix}${
                      !icon.direction ? '' : icon.direction === 'up' ? 'up' : 'down'
                    }${suffix}from-line.svg`;

                    return (<div
                      key={icon.label}
                      className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                    >
                      <div className="d-flex align-items-center">
                        <SVGImage
                          path={iconImage}
                          className={`svg-icon-3 svg-icon-${
                            !icon.direction ? 'warning' : icon.direction === 'up' ? 'primary' : 'danger'
                          } me-2`}
                        />
                        <div className="fs-2 fw-bolder">{icon.value}</div>
                      </div>
                      <div className="fw-bold fs-6 text-gray-400 text-center">{icon.label}</div>
                    </div>);
                  })}
                </div>
              </div>

              {accuracy && (<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                  <span className="fw-bold fs-6 text-gray-400">Order accuracy</span>
                  <span className="fw-bolder fs-6">{accuracy}%</span>
                </div>
                <div className="h-5px mx-3 w-100 bg-light mb-3">
                  <div
                    className="bg-success rounded h-5px"
                    role="progressbar"
                    style={{ width: `${accuracy}%` }}
                  ></div>
                </div>
              </div>)}
            </div>
          </div>
        </div>

        {tabs?.length && (
          <div className="d-flex overflow-auto h-55px">
            <ul
              className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
            >
              {tabs?.map((tab) => (
                <li key={tab.label} className="nav-item">
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === tab.link && 'active')
                    }
                    to={tab.link}
                  >
                    {tab.icon && (<SVGImage path={tab.icon} className="svg-icon-2 px-2"/>)}
                    {tab.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
