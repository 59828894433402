import FaxIcon from '@mui/icons-material/Fax';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Typography } from '@mui/material';

import { StatusBadge, TileCell } from '../../../../../components';
import CustomerCell from '../CustomerCell';

export const createCustomerColumns = () => [
  {
    id: 'id' as const,
    Header: 'ID',
    Cell: ({ ...props }) => {
      const customer = props.data[props.row.index];
      const { active } = customer;

      return (
        <CustomerCell
          active={customer?.active}
          avatar={
            <SupportAgentIcon
              color={active ? 'primary' : 'error'}
              fontSize="large"
            />
          }
          id={customer?.id}
          name={customer?.name}
          routingRequired={customer?.routingRequired}
          fineLineTicketsRequired={customer?.fineLineTicketsRequired}
          fineLineTrackingNumberRequired={
            customer?.fineLineTrackingNumberRequired
          }
          printTicketsRequired={customer?.printTicketsRequired}
          uCCLabelsRequired={customer?.uCCLabelsRequired}
          palletLabelsRequired={customer?.palletLabelsRequired}
          useIndividualSKUCartons={customer?.useIndividualSKUCartons}
          deadlines={customer?.deadlines}
        />
      );
    },
  },
  {
    id: 'name' as const,
    Header: 'NAME',
    minWidth: 350,
    Cell: ({ ...props }) => {
      const customer = props.data[props.row.index];
      const { country, state, city } = customer;
      const location = [country, state, city].join(', ').replace(/^,/, '');
      return (
        <TileCell
          title={props.row.original.name}
          subtitle={
            <Typography>
              <PlaceIcon fontSize="small" />
              {location}
            </Typography>
          }
        />
      );
    },
    disableCopy: true,
  },
  {
    Header: 'Phone',
    id: 'phone',
    minWidth: 100,
    Cell: ({ ...props }) => {
      const customer = props.data[props.row.index];

      return (
        <TileCell
          title={
            <Typography>
              {customer?.phone && <PhoneIcon fontSize="small" />}
              {customer?.phone && customer?.phone}
            </Typography>
          }
          subtitle={
            <Typography>
              {customer?.fax && <FaxIcon fontSize="small" />}
              {customer?.fax && customer?.fax}
            </Typography>
          }
        />
      );
    },
    disableCopy: true,
  },
  {
    Header: 'O. Orders',
    id: 'pickedOrders',
    Cell: ({ ...props }) => {
      const customer = props.data[props.row.index];

      return (
        <StatusBadge
          status="primary"
          title={customer.openOrders.toLocaleString() || 0}
        />
      );
    },
    disableCopy: true,
  },
  // {
  //   Header: 'P. Orders',
  //   id: 'openOrders',
  //   Cell: ({ ...props }) => (
  //     <StatusBadge
  //       status="success"
  //       title={
  //         props.data[props.row.index].pickedOrders?.toLocaleString('default') ||
  //         0
  //       }
  //     />
  //   ),
  // },
  // {
  //   Header: 'P. Orders',
  //   id: 'picks',
  //   Cell: ({ ...props }) => (
  //     <StatusBadge
  //       status="info"
  //       title={
  //         props.data[props.row.index].picks?.toLocaleString('default') || 0
  //       }
  //     />
  //   ),
  //  disableCopy: true,
  // },
  // {
  //   Header: 'Entry Date',
  //   id: 'entryDate',
  //   Cell: ({ ...props }) => (
  //     <DateTimeCell value={props.data[props.row.index].entryDate} />
  //   ),
  //  disableCopy: true,
  // },
  // {
  //   Header: 'Actions',
  //   id: 'actions',
  //   Cell: ({ ...props }) => (
  //     <ActionsCell
  //       type="horizontal"
  //       id={props.data[props.row.index].id}
  //       enableView={true}
  //       viewAction={`/third-parties/customers/${
  //         props.data[props.row.index].id
  //       }`}
  //     />
  //   ),
  //  disableCopy: true,
  // },
];
