import { FC, ReactElement } from 'react';

import { SVGImage, WithChildren } from '../../../../../../core/components';
import { useListView } from '../../../../../providers';

type Props = {
  enableAdd?: boolean;
  exportAction?: Function;
  filterComponent?: ReactElement;
  enableRefresh?: boolean,
  refreshAction?: () => void,
  refreshButtonText?: string,
};

export const ShippingOrderListToolbar: FC<Props & WithChildren> = (
  {
    children,
    filterComponent = true,
    exportAction,
    enableAdd = true,
    enableRefresh = false,
    refreshAction = () => {},
    refreshButtonText = 'Refresh'
  }) => {
  const { setItemForUpdate } = useListView();

  const openAddUserModal = () => {
    setItemForUpdate(null);
  };

  const exportActionClick = () => {
    if (exportAction) {
      exportAction();
    }
  };

  return (
    <div className="d-flex justify-content-end">
      {filterComponent && filterComponent}

      {
        children
          ? children
          : (
            <>
              {/* begin::Refresh Fab */}
              {enableRefresh && (
                <>
                  <button
                    type="button"
                    className="btn btn-sm btn-icon btn-light-primary me-3"
                    onClick={refreshAction}
                  >
                    <SVGImage path="/media/icons/arrows/rotate.svg" className="svg-icon-2" />
                    {(refreshButtonText || refreshButtonText.length > 0) && refreshButtonText}
                  </button>
                </>
              )}
              {/* end::Refresh Fab */}
              {exportAction && (
                <>
                  {/* begin::Export Fab */}
                  <button
                    type="button"
                    className="btn btn-sm btn-light-primary me-3"
                    onClick={exportActionClick}
                  >
                    <SVGImage path="/media/icons/duotune/arrows/arr078.svg" className="svg-icon-2"/>
                    Export
                  </button>
                  {/* end::Export Fab */}
                </>
              )}

              {enableAdd && (
                <>
                  {/* begin::Add Fab */}
                  <button type="button" className="btn btn-sm btn-light-primary" onClick={openAddUserModal}>
                    <SVGImage path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2"/>
                    Add
                  </button>
                  {/* end::Add Fab */}
                </>
              )}
            </>
          )
      }
    </div>
  );
};
