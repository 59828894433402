import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';

import { DateTimeCell, NumberCell, TileCell } from '../../../components';
import { Order } from '../../../models';
import { OrderOptionsCell } from './OrderOptionsCell';
import { OrderStatus } from '../order/OrderStatus';

export const OrderColumns: TableColumn<Order>[] = [
  {
    id: 'production-order',
    name: 'CONTROL',
    sortable: true,
    grow: 2,
    cell: ({ id, status, ...rest }) => {
      return (
        <TileCell
          title={<>
            <OrderStatus
              status={status === 'OPEN' && rest.stage !== 'SHIPPING'
                ? rest?.stage
                : status}
            />
            {' '}
            {id}
          </>}
          url={`/orders/${id}`}
        >
          <OrderOptionsCell objectValue={rest} />
        </TileCell>
      );
    },
  },
  {
    id: 'pick-ticket',
    name: 'PT #',
    compact: true,
    right: true,
    cell: ({ pickTicket }) => pickTicket
      ? <Link to={`/production/pick-ticket/${pickTicket?.id}`}>{pickTicket?.id}</Link>
      : '',
  },
  {
    id: 'division',
    name: 'DIV',
    sortable: true,
    grow: 1,
    right: true,
    cell: ({ division }) => {
      return (
        <Link to={`/third-parties/divisions/${division?.id}`}>
          {division?.id}
        </Link>
      );
    },
  },
  {
    id: 'order-date',
    name: 'O. DATE',
    sortable: true,
    compact: true,
    right: true,
    cell: ({ orderDate }) => orderDate
      ? (<DateTimeCell value={orderDate} />)
      : '',
  },
  {
    id: 'start-date',
    name: 'S. DATE',
    sortable: true,
    compact: true,
    right: true,
    cell: ({ startDate }) => startDate
      ? (<DateTimeCell value={startDate} />)
      : '',
  },
  {
    id: 'cancel-date',
    name: 'C. DATE',
    sortable: true,
    compact: true,
    right: true,
    cell: ({ cancelDate }) => cancelDate
      ? (<DateTimeCell value={cancelDate} />)
      : '',
  },
  {
    id: 'ship-date',
    name: 'SH. DATE',
    sortable: true,
    compact: true,
    right: true,
    cell: ({ shipDate }) => shipDate
      ? (<DateTimeCell value={shipDate} />)
      : '',
  },
  {
    id: 'total-quantity',
    name: 'T. QTY',
    sortable: true,
    compact: true,
    right: true,
    cell: ({ orderQuantity }) => (
      <NumberCell value={Number(orderQuantity)} />
    ),
  },
  {
    id: 'open-quantity',
    name: 'O. QTY',
    sortable: true,
    compact: true,
    right: true,
    cell: ({ openQuantity }) => (
      <NumberCell value={Number(openQuantity)} />
    ),
  },
  {
    id: 'picked-quantity',
    name: 'P. QTY',
    sortable: true,
    compact: true,
    right: true,
    cell: ({ pickedQuantity }) => (
      <NumberCell value={Number(pickedQuantity)} />
    ),
  },
  {
    id: 'shipped-quantity',
    name: 'SH. QTY',
    sortable: true,
    compact: true,
    right: true,
    cell: ({ shippedQuantity }) => (
      <NumberCell value={Number(shippedQuantity)} />
    ),
  },
];