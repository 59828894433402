import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import { SettingsConsumer, SettingsProvider } from './core';
import { ThemeComponent } from './core/theme';
import { LanguageProvider } from './theme/i18n/Metronici18n';
import { AppRoutes } from './app/routing/AppRoutes';
import { API_BASE_URL, AuthProvider, setupAxios } from './app/modules/auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FC, ReactNode } from 'react';
import { GuestGuard } from './core/components/Auth/GuestGuard';
import { Spinner } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';

import './theme/assets/sass/style.scss';
import './theme/assets/sass/plugins.scss';
import './theme/assets/sass/style.react.scss';

setupAxios(
  axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: { 'Content-type': 'application/json' },
  })
);

interface GuardProps {
  authGuard: boolean;
  children: ReactNode;
  guestGuard: boolean;
}

const Guard: FC<GuardProps> = ({ authGuard, children, guestGuard }) => {
  if (guestGuard) {
    return (
      <GuestGuard fallback={<Spinner animation="border" />}>
        {children}
      </GuestGuard>
    );
  }

  if (!authGuard && !guestGuard) {
    return <>{children}</>;
  }

  return <>{children}</>;
};

Chart.register(...registerables);
const queryClient = new QueryClient();
const container = document.getElementById('root');
const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <GoogleOAuthProvider clientId={clientId}>
          <AuthProvider>
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <ThemeComponent settings={settings}>
                      <AppRoutes />
                    </ThemeComponent>
                  );
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </LanguageProvider>
    </QueryClientProvider>
  );
}
