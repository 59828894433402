import { ID } from './id';
import { Response } from './response';

export type CustomerRequirement = {
  id?: ID;
  uuid?: ID;
  customerId?: ID;
  divisionId?: string,
  routing?: boolean;
  fineLineTickets?: boolean;
  fineLineTrackingNo?: boolean;
  printTickets?: boolean;
  palletLabels?: boolean;
  uccLabels?: boolean;
  individualSKUCartons?: boolean;
  deadlines?: boolean;
  daysBeforeStartDate?: number;
  daysBeforeCancelDate?: number;
}

export type CustomerOptionResponse = Response<Array<CustomerRequirement>>;

export const initialCustomerOption = {
  divisionId: undefined,
  routing: false,
  fineLineTickets: false,
  fineLineTrackingNo: false,
  printTickets: false,
  palletLabels: false,
  uccLabels: false,
  individualSKUCartons: false,
  daysBeforeStartDate: 0,
  daysBeforeCancelDate: 0,
} as CustomerRequirement;
