import { FC, useContext, useState } from 'react';
import { WithChildren } from '../../../../core/components';
import { initialModalContextProps, ModalDialogContext } from '../context';

export const ModalDialogProvider: FC<WithChildren> = ({ children }) => {
  const [title, setTitle] = useState(initialModalContextProps.title);

  return (
    <ModalDialogContext.Provider value={{ title, setTitle }}>
      {children}
    </ModalDialogContext.Provider>
  );
};

export const useModalDialog = () => useContext(ModalDialogContext);

