import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, IconButton
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { FC, ReactNode, useState } from "react";
import Icon from "../../../core/components/Icon";

interface DialogFormProps {
  icon: string;
  title: string;
  description?: string;
  children: ReactNode;
  onSubmit?: () => void;
  okButtonProps?: ButtonProps;
  closeButtonProps?: ButtonProps;
}

export const DialogForm: FC<DialogFormProps> = (
  {
    children,
    description,
    icon,
    onSubmit,
    title,
    okButtonProps,
    closeButtonProps
  }
) => {
  const [open, setOpen] = useState<boolean>(false);

  const onClose = () => setOpen(false);

  const onOpen = () => setOpen(true);

  return (
    <>
      <Button onClick={onOpen}>
        <Icon icon={`mdi:${icon}`} />
      </Button>

      <Dialog open={open} onClose={() => onClose()} aria-labelledby="form-dialog">
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers={true}>
          <DialogContentText sx={{ mb: 3 }}>
            {description && description}

            <Box sx={{ p: 1 }}>
              {children}
            </Box>
          </DialogContentText>
        </DialogContent>

        <DialogActions className="dialog-actions-dense">
          <Button
            color="error"
            onClick={(e) => {
              onClose();

              if (closeButtonProps?.onClick) {
                closeButtonProps?.onClick(e);
              }
            }}
            startIcon={<Icon icon="mdi:close-circle-outline" />}
            variant="outlined"
          >
            {closeButtonProps?.title || "Close"}
          </Button>
          <Button
            endIcon={<Icon icon="mdi:content-save" />}
            onClick={(e) => {
              onClose();

              if (onSubmit) {
                onSubmit();
              }

              if (!onSubmit && okButtonProps?.onClick) {
                okButtonProps?.onClick(e);
              }
            }}
            variant="contained"
          >
            {okButtonProps?.title || "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
