import { getSalesOrders } from '../../api';
import { QUERIES } from '../../../../constants';
import {
  ListViewProvider,
  RequestProvider,
  ResponseProvider,
} from '../../../../providers';
import { PurchaseOrderList } from '../../purchases';

const queryKey = QUERIES.SALES_ORDERS;

const SalesOrderList = () => <PurchaseOrderList />;

export const SalesOrderListWrapper = () => {
  return (
    <RequestProvider>
      <ResponseProvider callback={getSalesOrders} queryKey={queryKey}>
        <ListViewProvider>
          <SalesOrderList />
        </ListViewProvider>
      </ResponseProvider>
    </RequestProvider>
  );
};
