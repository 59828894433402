import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Direction as BaseDirection } from "@mui/material";
import { FC, ReactNode, useEffect } from "react";
import stylisRTLPlugin from "stylis-plugin-rtl";

const styleCache = () =>
  createCache({
    key: "rtl",
    prepend: true,
    stylisPlugins: [stylisRTLPlugin]
  });

interface DirectionProps {
  children: ReactNode;
  direction: BaseDirection;
}

export const Direction: FC<DirectionProps> = (
  { children, direction }
) => {
  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  if (direction === "rtl") {
    return <CacheProvider value={styleCache()}>{children}</CacheProvider>;
  }

  return <>{children}</>;
};

export default Direction;
