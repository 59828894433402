import { FieldValues } from 'react-hook-form';
import { privateAxios } from '../../../api/axios';

import { ORDERS_URL } from '../../../api';
import { ID } from '../../../models';
import { PurchaseOrder } from '../models';

const ROUTING_ORDERS_URL = `${ORDERS_URL}/routing`;

export const updatePurchaseOrder = (
  id: ID,
  body: FieldValues
): Promise<PurchaseOrder> => {
  return privateAxios
    .patch(`${ROUTING_ORDERS_URL}/${id}`, body)
    .then((response) => response.data);
};

export const getRoutingOrders = (query: string) => {
  return privateAxios
    .get(`${ROUTING_ORDERS_URL}?${query}`)
    .then((response) => response.data);
};

export const getRoutingOrderByID = ({ queryKey }: any) => {
  const { id } = queryKey[1];

  return privateAxios
    .get(`${ROUTING_ORDERS_URL}/${id}`, { withCredentials: true })
    .then((response: any) => response.data);
};
