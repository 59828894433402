import { FC } from 'react';
import Icon from '../../../../core/components/Icon';
import { FileProp } from './FileProp';

export interface ListItemPreviewProps {
  file: FileProp;
}

export const FilePreviewIcon: FC<ListItemPreviewProps> = ({ file }) => {
  if ((file?.type ?? file?.name).startsWith('image')) {
    return <img width={38} height={38} alt={file.name} src={file?.url ?? undefined} />;
  }
  if ((file?.type ?? file?.name).endsWith('pdf')) {
    return <Icon color='red' icon='mdi:file-pdf-box-outline' />;
  }
  if ((file?.type ?? file?.name).endsWith('document')) {
    return <Icon color='blue' icon='mdi:file-word-box-outline' />;
  }
  if ((file?.type ?? file?.name).endsWith('sheet')) {
    return <Icon color='green' icon='mdi:file-excel-box-outline' />;
  }

  return <Icon icon='mdi:file-document-outline' />;
};

export default FilePreviewIcon;
